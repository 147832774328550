import React from "react";
import { FormButtonProps } from "./types";

const FormButton: React.FC<FormButtonProps> = ({
  label,
  url = "/",
  onClick,
}) => {
  return (
    <div className="flex">
      <div className="rounded-2xl" onClick={onClick}>
        <button className="w-full cursor-pointer rounded-2xl flex items-center justify-center py-3 border-transparent font-medium text-xl text-white bg-gradient-to-r from-lime-500 to-lime-600 hover:from-lime-600 hover:to-lime-700 md:text-lg px-24">
          {label}
        </button>
      </div>
    </div>
  );
};

export default FormButton;

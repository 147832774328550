import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import LogoRF from "../../assets/logo_rf.png";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
//import SignInVideo from "../../assets/videos/signin.mp4";
import { Link, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import useShowToastErrorMessage from "../../hooks/toast/useShowToastErrorMessages";
import services from "../../services/api";
import { toast } from "react-toastify";
import { useAuthContext } from "../../store/context/AuthContext";
import {
  ResetPasswordSchema,
  ResetPasswordType,
} from "../../settings/yup/schemas/ResetPasswordSchema";
import { useTranslation } from "react-i18next";
import { HOME_VIDEO } from "../../constants/urls";

const ResetPassword: React.FC = () => {
  const { handleLoading } = useAuthContext();

  const [searchParams] = useSearchParams();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const { t } = useTranslation();

  const onSubmit = async ({ email, confirmEmail }: ResetPasswordType) => {
    try {
      if (email !== confirmEmail) {
        toast.error(t("resetPass.filled"));
        return;
      }

      await services.auth.reset(email);

      handleLoading(true);
      setEmail(email);
      setIsEmailSent(true);
    } catch (error) {
      toast.error(t("resetPass.ops"));
      console.error(error);
    } finally {
      handleLoading(false);
    }
  };

  const { formState, handleSubmit, control } = useForm<ResetPasswordType>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const { errors } = formState;

  useShowToastErrorMessage(errors);

  useEffect(() => {
    const findToken = searchParams.get("token");
    if (findToken) {
      setToken(findToken);
      setIsEmailSent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-blue-800 w-full h-full flex justify-center">
      <div className="w-full flex">
        <div className="w-full md:w-7/12 flex">
          <div className="flex w-96 items-center justify-center flex-col m-auto">
            {isEmailSent ? (
              <div>
                <h1 className="text-lime-700 text-3xl mt-10 mb-5 text-center">
                  {token ? "Email successfully verified" : "Check your Email"}
                </h1>
                {!token && (
                  <p className="text-blue-400 text-base text-center">
                    {t("resetPass.if")} <strong>{email}</strong>{" "}
                    {t("resetPass.exists")}
                  </p>
                )}
                <div className="flex justify-center mt-5">
                  <Link to="/login">
                    <Button label={t("resetPass.backto")} />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex justify-center flex-col items-center">
                <img src={LogoRF} alt="Moneyball" width={100} />
                <h1 className="text-white text-3xl mt-10 mb-5 text-center">
                  {t("resetPass.reset")}
                </h1>
                <p className="text-blue-400 text-base text-center">
                  {t("resetPass.please")}
                  <br /> {t("resetPass.which")}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="w-full mt-10">
                    <div className="mb-5">
                      <Controller
                        defaultValue=""
                        name="email"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            {...field}
                            {...fieldState}
                            type="email"
                            label="Email"
                            placeholder={String(t("resetPass.email"))}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        defaultValue=""
                        name="confirmEmail"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            {...field}
                            {...fieldState}
                            type="email"
                            label={String(t("resetPass.confirmEmail"))}
                            placeholder={String(t("resetPass.email"))}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-5">
                    <Button label={t("resetPass.sendme")} />
                  </div>
                  <div className="mt-5 mb-5 flex justify-center">
                    <span className="text-white">
                      {t("resetPass.have")}
                      <Link
                        to="/login"
                        className="text-lime-700 ml-2 underline"
                      >
                        {t("resetPass.login")}
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        <div className="bg-gray-400 w-7/12 hidden sm:block">
          <div className="w-full h-full overflow-hidden">
            <div className="fixed h-full bg-light-cover z-10 w-7/12"></div>
            <video
              autoPlay
              muted
              loop
              className="overflow-hidden w-full"
              style={{
                position: "relative",
                top: "-45%",
              }}
            >
              <source src={HOME_VIDEO} type="video/mp4" />
            </video>
            <h2 className="text-white text-4xl font-medium absolute ml-10 top-10 z-20">
              {t("login.youAre")}
              <br />
              {t("login.way")}
              <br />
              {t("login.new")}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState } from "react";
import { InputProps } from "./types";

const Input = React.forwardRef<HTMLElement, InputProps>(
  (
    {
      id,
      label,
      variation = "primary",
      placeholder,
      errorMessage,
      type,
      disabled,
      defaultValue,
      onChange,
      onBlur,
      onKeyUp,
      hidden,
      value,
      ...rest
    },
    ref
  ) => {
    const variationStyles =
      variation === "primary"
        ? "font-normal text-white text-base"
        : "font-normal text-blue-400 text-sm";

    const [showPass, setShowPass] = useState(false);

    return (
      <div className="block relative w-full">
        {label && <span className={variationStyles}>{label}</span>}
        <div></div>
        <input
          id={id}
          className="rounded-2xl relative mt-1 text-sm w-full px-4 py-4 bg-light-blue text-light-blue-secondary border border-transparent focus:outline-none focus:border-lime-500 focus:ring-1 focus:ring-lime-500"
          style={{ colorScheme: "dark" }}
          placeholder={placeholder}
          disabled={disabled}
          type={showPass ? "text" : type}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          hidden={hidden}
          {...rest}
        />
        <span className="block font-normal text-brand-danger">
          {errorMessage}
        </span>

        {type === "password" && (
          <div
            onClick={() => setShowPass(!showPass)}
            className="absolute cursor-pointer bottom-4 right-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#616d7e"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        )}
      </div>
    );
  }
);

export default Input;

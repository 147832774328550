import { createContext, useContext } from "react";
import useAuth from "../../hooks/auth/useAuth";

export type AuthProviderType = {
  children: JSX.Element;
};

export type UserType = {
  id: number;
  username: string;
  picture: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  organization: any;
  business: string;
  password: string;
};

export type AuthContextType = {
  loading: boolean;
  handleLoading: (status: boolean) => void;
  authenticated: boolean;
  user?: UserType;
  handleUser: (data: UserType) => void;
  handleLogin: () => void;
  handleLogout: () => void;
};

const Context = createContext<AuthContextType>({
  loading: false,
  authenticated: true,
} as AuthContextType);

export function AuthProvider({ children }: AuthProviderType) {
  const {
    authenticated,
    user,
    loading,
    handleLoading,
    handleUser,
    handleLogin,
    handleLogout,
  } = useAuth();

  return (
    <Context.Provider
      value={{
        loading,
        handleLoading,
        user,
        authenticated,
        handleUser,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useAuthContext = () => useContext(Context);

import React, { useState } from "react";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import { AccordionProps } from "./types";

const Accordion: React.FC<AccordionProps> = ({ label, headline, content }) => {
  const [open, setOpen] = useState<boolean>();

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex justify-between pt-5 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <p className="text-accordion-label">{label}</p>
        {open ? <Icon name={IconEnum.right} /> : <Icon name={IconEnum.down} />}
      </div>
      {headline && (
        <div className="flex flex-col text-blue-400 mt-5 border-b-2 border-blue-1000 pb-5">
          {headline}
        </div>
      )}
      <div
        className={
          `${open ? "flex" : "hidden"} ` +
          "flex flex-col text-blue-400 mt-5 border-b-2 border-blue-1000 pb-5"
        }
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;

interface ProgressProps {
  progress: number;
}

export default function Progress({ progress }: ProgressProps) {
  return (
    <div>
      {Boolean(progress) && (
        <div className="mb-5 ease-out transition-all duration-500 w-full bg-gray-200 rounded-full dark:bg-gray-700">
          <div
            className="h-7 flex ease-out justify-center transiton-all duration-500 items-center bg-lime-700 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
    </div>
  );
}

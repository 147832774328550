import React from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
import { ChartBarProps } from "./types";

const ChartBar: React.FC<ChartBarProps> = ({ label, data, width, height }) => {
  return (
    <div className="bg-blue-700 p-3 rounded-lg px-px-10 block float-left pr-5 pl-5">
      <div
        style={{
          width,
        }}
      >
        <p className="text-gray-400 font-bold text-lg mb-5">{label}</p>
        <AreaChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          style={{
            fontSize: "13px",
          }}
        >
          <XAxis dataKey="name" stroke="#849AA9" fill="#09B55F" />
          <YAxis stroke="#849AA9" />
          <Tooltip />
          <Area type="monotone" dataKey="key" stroke="#849AA9" fill="#09B55F" />
        </AreaChart>
      </div>
    </div>
  );
};

export default ChartBar;

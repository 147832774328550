import React from "react";
import { AdPreviewProps } from "./types";
import Lion from "../../../../../assets/lion.png";
import Width from "../../../../../assets/width.svg";
import WidthLateral from "../../../../../assets/widthlateral.png";
import HeightLateral from "../../../../../assets/heightlateral.svg";
import { useTranslation } from "react-i18next";

const AdPreview: React.FC<AdPreviewProps> = ({
  isVideo,
  logo,
  bottomBanner,
  lateralBanner,
  videoUrl,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <span className="text-white font-bold">{t("newCamp.ad")}</span>
      {lateralBanner && (
        <div className="flex items-end w-full justify-end">
          <div className="ml-[-20px] mb-2">
            <img src={WidthLateral} alt="Height Banner" width={80} />
          </div>
        </div>
      )}
      <div className="flex justify-end">
        <div style={{ width: `${lateralBanner ? "390" : "100%"}` }}>
          <img
            src={Lion}
            alt="Rafael Leão"
            className="h-[239px] md:w-[440px]"
          />
          {logo && (
            <div className="flex justify-end w-full">
              <img
                src={logo}
                alt="Logo"
                className="relative -mt-14 max-w-[60px] right-5 -top-12"
              />
            </div>
          )}
        </div>
        {lateralBanner && (
          <>
            {videoUrl && (
              <video width="135" autoPlay height="239" controls>
                <source src={videoUrl} type="video/mp4" />
                <source src={videoUrl} type="video/ogg" />
                {t("newCamp.suport")}
              </video>
            )}
            {!videoUrl && (
              <img
                src={lateralBanner}
                className="h-[239px] w-[80px]"
                alt="Lateral Banner"
              />
            )}
          </>
        )}
        <div>
          <img
            src={HeightLateral}
            className="max-h-[225px] mt-2 absolute ml-[-40px]"
            alt="Height Banner"
          />
        </div>
      </div>
      {bottomBanner && (
        <>
          {isVideo && videoUrl && (
            <video width="434" autoPlay controls>
              <source src={videoUrl} type="video/mp4" />
              <source src={videoUrl} type="video/ogg" />
              {t("newCamp.suport")}
            </video>
          )}
          {!isVideo && !videoUrl && (
            <img
              src={bottomBanner}
              className="w-full max-h-[180px]"
              height={50}
              alt="Bottom Banner"
            />
          )}
        </>
      )}
      <img
        src={Width}
        className="w-full max-h-[180px] mt-2"
        alt="Bottom Banner"
      />
    </div>
  );
};

export default AdPreview;

import { ComponentStory, ComponentMeta } from "@storybook/react";
import { IconEnum } from "../../Icon/types";

import ReportBox from "./index";

export default {
  title: "Report/Box",
  component: ReportBox,
} as ComponentMeta<typeof ReportBox>;

const Template: ComponentStory<typeof ReportBox> = (args) => (
  <ReportBox {...args} />
);

export const TotalUniqueViews = Template.bind({});

TotalUniqueViews.args = {
  label: "Total Unique Views",
  icon: IconEnum.peopleOutline,
  amount: "13,675",
  percentage: "2,51%",
  status: "positive",
};

export const TotalCampaigns = Template.bind({});

TotalCampaigns.args = {
  label: "Total Campaigns",
  icon: IconEnum.document,
  amount: "1,986",
  percentage: "-1,43%",
  status: "negative",
};

export const BudgetSpend = Template.bind({});

BudgetSpend.args = {
  label: "Budget Spend",
  icon: IconEnum.like,
  amount: "€8905",
  percentage: "-3,56%",
  status: "negative",
};

export const TotalBudget = Template.bind({});

TotalBudget.args = {
  label: "Budget Spend",
  icon: IconEnum.chat,
  amount: "€1,234,780",
  percentage: "+0,94%",
  status: "negative",
};

import React from "react";
import Table from "../../components/Table";
import { analyticsTableColumns } from "./analyticsTableColumns";
import Roma from "../../assets/roma.png";
import Page from "../../components/Page";

const Campaign: React.FC = () => {
  const data = [
    {
      id: 1,
      details: `<img src=${Roma} class="mr-5" />  Josh created a New Campaign for Roma TV`,
      name: "Nuno Teles",
      date: "May 26, 2022",
      budget: "$2500",
    },
    {
      id: 2,
      details: `<img src=${Roma} class="mr-5" />  Josh created a New Campaign for Roma TV`,
      name: "New Teles",
      date: "New 26, 2022",
      budget: "$4500",
    },
    {
      id: 3,
      details: `<img src=${Roma} class="mr-5" />  Josh created a New Campaign for Roma TV`,
      name: "Thirdiary Teles",
      date: "Thirdiary 26, 2022",
      budget: "$5500",
    },
  ];

  const onEdit = (id: number) => {
    alert(`Update: ${id}`);
  };

  const onDelete = (id: number) => {
    alert(`Delete: ${id}`);
  };

  const onUpdate = (id: number) => {
    alert(`Update: ${id}`);
  };

  const columns = analyticsTableColumns(onEdit, onDelete, onUpdate);

  return (
    <Page title="Analytics">
      <Table data={data} columns={columns} />
    </Page>
  );
};

export default Campaign;

import { ComponentStory, ComponentMeta } from "@storybook/react";
import { IconEnum } from "../Icon/types";

import Sidebar from "./index";

export default {
  title: "Main/Sidebar",
  component: Sidebar,
} as ComponentMeta<typeof Sidebar>;

const Template: ComponentStory<typeof Sidebar> = (args) => (
  <Sidebar {...args} />
);

export const SidebarComponent = Template.bind({});

const navigationItems = [
  {
    name: "Dahboard",
    link: "/",
    icon: IconEnum.dashboard,
  },
  {
    name: "Analytics",
    link: "/",
    icon: IconEnum.analytics,
  },
  {
    name: "Clientes",
    link: "/",
    icon: IconEnum.people,
  },
];

SidebarComponent.args = {
  navigationItems,
};

import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import services from "../../services/api";
import { CampaignType } from "./campaignsTableColumns";
import { formatCurrency } from "../../helpers";
import { useAuthContext } from "../../store/context/AuthContext";
import ButtonStatus from "../../components/ButtonStatus";
import Dropdown from "../../components/Dropdown";
import { useTranslation } from "react-i18next";

const currencyConfig = {
  country: "pt-PT",
  currency: "EUR",
};

const Campaign: React.FC = () => {
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const dropdownItems = [
    { name: "A-Z", value: "sort=alphabetical_asc" },
    { name: "Z-A", value: "sort=alphabetical_desc" },
    { name: t("campaigns.most"), value: "sort=created_desc" },
    { name: t("campaigns.least"), value: "sort=created_asc" },
  ];

  const [selectedFilter, setSelectedFilter] = useState(dropdownItems[2]);

  const { user } = useAuthContext();

  const { handleLoading } = useAuthContext();

  const navigate = useNavigate();

  const [next, setNext] = useState<string>("");
  const [prev, setPrev] = useState<string>("");

  const hasPages = Boolean(next || prev);

  const loadCampaigns = async (page?: string) => {
    handleLoading(true);
    const response = services.campaigns.list(page, selectedFilter.value);
    response
      .then((response) => {
        let campaigns = response.data;

        campaigns = campaigns?.results?.map((campaign: any) => {
          return {
            id: campaign.id,
            name: `${
              user?.picture
                ? `<img src=${user?.picture} class="mr-5" width="60" />`
                : ""
            } ${campaign.name} `,
            start_date: campaign.start_date,
            end_date: campaign.end_date,
            budget: formatCurrency(
              campaign.daily_budget || campaign.event_budget,
              currencyConfig.country,
              currencyConfig.currency
            ),
            gender: campaign?.genders
              ?.map((gender: string) =>
                gender === "M" ? t("campaigns.male") : t("campaigns.female")
              )
              .join(", "),
            age: campaign?.ages?.join(", "),
            payment_id: campaign.payment_id,
            payment_status: campaign.payment_status,
            createdBy: campaign.created_by,
            description: campaign.budget_description,
            created_at: campaign.created_at,
            currency: campaign.currency,
          };
        });

        setPrev(response.data.previous);
        setNext(response.data.next);
        setCampaigns(campaigns);

        handleLoading(false);
      })
      .finally(() => handleLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  useEffect(() => {
    loadCampaigns();
  }, [selectedFilter]);

  const onPayment = async (id: number) => {
    const response = services.campaigns.put(id);
  };

  const handlePrev = async () => {
    await loadCampaigns(prev);
    return;
  };

  const handleNext = async () => {
    await loadCampaigns(next);
    return;
  };

  const onView = async (id?: string) => {
    navigate("/campaigns/" + id);
  };

  return (
    <Page
      title={String(t("campaigns.campaigns"))}
      button={
        <div className="w-64 shadow-green-shadow ">
          <Button
            isFilled
            label={t("campaigns.create")}
            classNames=" !p-2 !h-10"
            onClick={() => navigate("/campaign/new")}
          />
        </div>
      }
    >
      {Boolean(campaigns.length) && (
        <Dropdown
          options={dropdownItems}
          selected={selectedFilter}
          setSelected={setSelectedFilter}
        />
      )}

      {Boolean(campaigns.length) ? (
        <div className="max-w-7xl m-auto">
          <div className="grid-cols-12 mb-8 border-b border-blue-1000 font-inter py-4 font-medium text-sm text-[#9fa2b4] grid">
            <p className="col-span-3">{t("campTable.campaign")}</p>
            <p className="col-span-2">{t("campTable.customer")}</p>
            <p className="col-span-2">{t("campTable.start")}</p>
            <p className="col-span-2 text-center">{t("campTable.budget")}</p>
            <p className="col-span-3">{t("campTable.details")}</p>
          </div>
          <div className="overflow-auto">
            {campaigns.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (campaign: CampaignType) => {
                const startDate = campaign.start_date;
                const data_hora = new Date(String(startDate));
                const options = {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                };
                const formatedDate =
                  data_hora
                    .toLocaleDateString(currentLanguage, options as any)
                    .replace(",", "") + " ";

                const date1 = new Date(String(campaign.start_date));
                const date2 = new Date(String(campaign.end_date));

                const diffTime = Math.abs(Number(date2) - Number(date1));
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                const today = new Date();
                const created = new Date(String(campaign.created_at));

                const year = created.getFullYear();
                const month = created.getMonth() + 1;
                const day = created.getDate();

                const createdDate = `${day.toString().padStart(2, "0")}.${month
                  .toString()
                  .padStart(2, "0")}.${year}`;

                const diff = Math.abs(Number(today) - Number(created));
                const updated = Math.ceil(diff / (1000 * 60 * 60 * 24)) - 1;

                return (
                  <div
                    className={`font-mulish font-semibold w-full overflow-auto text-sm text-white bg-blue-800 mb-2.5 px-6 h-28 rounded-xl grid-cols-12 items-center py-4 grid`}
                    key={campaign.id}
                  >
                    <div
                      className={`flex flex-col w-full col-span-3 items-start gap-2`}
                    >
                      <p className="inline-block w-full">
                        {campaign.description}
                      </p>
                      <p className="text-xs text-gray-400">
                        {updated
                          ? `${t("campTable.updated")} ${updated} ${
                              updated > 1
                                ? t("campTable.ago")
                                : t("campTable.oneAgo")
                            }`
                          : t("campTable.today")}
                      </p>
                    </div>

                    <div className="col-span-2">
                      {campaign.createdBy?.first_name}
                      <div className="text-xs mt-2 text-gray-400">
                        {t("campTable.on")} {createdDate}
                      </div>
                    </div>
                    <div className="col-span-2">{formatedDate}</div>
                    <div className="col-span-2 ">
                      <ButtonStatus
                        onClick={() => onPayment(Number(campaign.payment_id))}
                        payment_status={String(campaign.payment_status)}
                        budget={Number(campaign.budget)}
                        days={Number(diffDays)}
                        key={campaign.id}
                        currency={Number(campaign.currency)}
                      />
                    </div>
                    <div className="body-sm pl-3 col-span-3">
                      <span className="flex space-x-2 w-36">
                        <button
                          onClick={() => onView(campaign.id?.toString())}
                          className="border-[1px] w-full text-white cursor-pointer rounded-[10px] h-10 border-[#09b55f]"
                        >
                          {t("campTable.view")}
                        </button>
                      </span>
                    </div>
                  </div>
                );
              }
            )}
          </div>

          {hasPages && (
            <div className="mb-12 w-full flex space-x-5 justify-center">
              <div className="w-32">
                <Button
                  isFilled
                  disabled={!Boolean(prev)}
                  label={String(t("campaigns.previous"))}
                  onClick={() => handlePrev()}
                />
              </div>
              <div className="w-32">
                <Button
                  isFilled
                  disabled={!Boolean(next)}
                  label={String(t("campaigns.next"))}
                  onClick={() => handleNext()}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="text-2xl mt-[15%] text-white font-medium text-center">
          {String(t("campaigns.noCamp"))}
        </p>
      )}
    </Page>
  );
};

export default Campaign;

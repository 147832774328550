import React, { useEffect, useState } from "react";
import { IconEnum } from "../../components/Icon/types";
import { TotalUniqueViews } from "../../components/Report/Box/box.stories";
import ChartBar from "../../components/Report/ChartBar";
import PieChart from "../../components/Report/PieChart";
import TinyBarChart from "../../components/Report/TinyBarChart";
import Page from "../../components/Page";
import InputSelect from "../../components/Form/InputSelect";
import services from "../../services/api";
import { useAuthContext } from "../../store/context/AuthContext";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  const [organizationType, setOrganizationType] = useState();
  const { user } = useAuthContext();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    const response = services.users.organization(user?.organization);

    response.then((organization) =>
      setOrganizationType(organization.data.business)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationType]);

  useEffect(() => {
    navigate("/campaigns-candidate");
  }, [navigate]);

  const data = [
    {
      name: "January",
      key: 60,
    },
    {
      name: "February",
      key: 30,
    },
    {
      name: "March",
      key: 50,
    },
    {
      name: "April",
      key: 70,
    },
    {
      name: "May",
      key: 40,
    },
    {
      name: "June",
      key: 80,
    },
    {
      name: "July",
      key: 130,
    },
    {
      name: "Ag",
      key: 130,
    },
    {
      name: "Sep",
      key: 130,
    },
  ];

  const dataByDevice = [
    { name: "Tablet", key: 400 },
    { name: "Desktop", key: 300 },
    { name: "Mobile", key: 300 },
  ];

  const COLORS = ["#6AD2FF", "#39B8FF", "#7551FF"];

  return (
    <Page
      title="Dashboard"
      button={
        <div className="w-2/12 mt-5 hidden">
          <InputSelect
            label=""
            options={[]}
            onChangeSelect={(value) => console.log(value)}
            defaultValue={1}
          />
        </div>
      }
    >
      <div className="flex flex-col flex-wrap items-center w-full md:space-y-0 space-y-4 md:flex-row justify-between">
        <div className="md:w-2/12 w-full">
          <TotalUniqueViews
            label={String(t("dashboard.unique"))}
            icon={IconEnum.peopleOutline}
            amount="13,675"
            percentage="2,51%"
            status="positive"
          />
        </div>
        <div className="md:w-2/12 w-full">
          <TotalUniqueViews
            label={String(t("dashboard.campaigns"))}
            icon={IconEnum.document}
            amount="1,986"
            percentage="-1,43%"
            status="negative"
          />
        </div>
        <div className="md:w-2/12 w-full">
          <TotalUniqueViews
            label={String(t("dashboard.spend"))}
            icon={IconEnum.like}
            amount="€8905"
            percentage="-3,56%"
            status="negative"
          />
        </div>
        <div className="md:w-2/12 w-full">
          <TotalUniqueViews
            label={String(t("dashboard.budget"))}
            icon={IconEnum.chat}
            amount="€1,234,780"
            percentage="+0,94%"
            status="positive"
          />
        </div>
        <div className="md:w-2/12 w-full">
          <TotalUniqueViews
            label={String(t("dashboard.unique"))}
            icon={IconEnum.alert}
            amount="432,097"
            percentage="-0,20%"
            status="positive"
          />
        </div>
      </div>
      <div className="flex items-cente justify-centerr lg:flex-row flex-col xl:flex-row gap-1 md:w-12/12 mt-10">
        <ChartBar
          label={String(t("dashboard.viewers"))}
          width={width > 700 ? 765 : 320}
          height={236}
          data={data}
        />

        {width < 768 && (
          <TinyBarChart
            label={String(t("dashboard.users"))}
            width={320}
            height={150}
            data={data}
            status="positive"
            amount="2.579"
            percentage="2.45%"
          />
        )}
      </div>
      <div className="flex md:gap-6 md:flex-row flex-col my-10">
        {width > 768 && (
          <TinyBarChart
            label={String(t("dashboard.users"))}
            width={320}
            height={150}
            data={data}
            status="positive"
            amount="2.579"
            percentage="2.45%"
          />
        )}

        <div className="flex md:w-5/12 mt-4 md:mt-0">
          <PieChart
            label={String(t("dashboard.users"))}
            data={dataByDevice}
            width={320}
            height={250}
            colors={COLORS}
          />
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;

import React from "react";
import { useMap } from "react-leaflet";
import { SmartMapProps } from "./types";

const SmartMap: React.FC<SmartMapProps> = ({ coords }) => {
  const map = useMap();
  map.setView(coords, map.getZoom());

  return null;
};

export default SmartMap;

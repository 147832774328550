import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { UserType } from "../../store/context/AuthContext";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(true);

  const userStorage = localStorage.getItem("user");

  const [user, setUser] = useState<UserType>(
    userStorage ? JSON.parse(userStorage) : {}
  );

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }

    setLoading(false);
  }, []);

  async function handleLogin() {
    setAuthenticated(true);
  }

  function handleUser(data: UserType) {
    setUser(data);
    localStorage.setItem("user", JSON.stringify(data));
    setAuthenticated(true);
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("organization");
    localStorage.removeItem("organizationType");
    setUser({} as UserType);
    navigate("/login");
  }

  function handleLoading(status: boolean) {
    setLoading(status);
  }

  return {
    authenticated,
    user,
    loading,
    handleLoading,
    handleUser,
    handleLogin,
    handleLogout,
  };
}

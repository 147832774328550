import React from "react";
import Icon from "../../Icon";
import { IconEnum } from "../../Icon/types";

import { TagListProps } from "../types";

const TagList: React.FC<TagListProps> = ({ data, handleDelete }) => (
  <div className="flex space-x-3">
    {data.map(({ id, name }) => (
      <div
        className="flex items-center rounded-lg bg-lime-700 py-2 px-4"
        key={id}
      >
        <label className="text-white text-xs">{name}</label>
        {handleDelete && (
          <div
            onClick={() => handleDelete?.(id)}
            className="ml-2 cursor-pointer"
          >
            <Icon name={IconEnum.close} />
          </div>
        )}
      </div>
    ))}
  </div>
);

export default TagList;

import React, { MutableRefObject, useState, useEffect } from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import { CustomFileType } from "./types";
import DeleteIcon from "../../assets/delete.png";
import { useTranslation } from "react-i18next";

export type DragDropFileProps = {
  onChange?: (files: CustomFileType[]) => void;
};

const allowedVideoExtensions = /(\.mp4|\.mov|\.wmv|\.flv|\.avi)$/i;

const DragDropFile: React.FC<DragDropFileProps> = ({ onChange }) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef() as MutableRefObject<HTMLInputElement>;
  const [files, setFiles] = useState<CustomFileType[]>();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleDrag = function (
    e: React.DragEvent<HTMLFormElement | HTMLDivElement>
  ) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
      return;
    }
    if (e.type === "dragleave") {
      setDragActive(false);
      return;
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setLoading(true);
    e.preventDefault();
    handleProgress();

    const pattern = /^[a-zA-Z0-9. _#-]+$/;

    setTimeout(() => {
      if (e.target.files && e.target.files[0]) {
        handleFiles(e.target.files);

        if (!allowedVideoExtensions.exec(String(e.target.files[0].name))) {
          toast.error(t("newCamp.weOnly"));
          setFiles(undefined);
        } else if (!pattern.test(e.target.files[0].name)) {
          toast.error(t("newCamp.filename"));
          setFiles(undefined);
        } else {
          toast.success(t("newCamp.fileSucess"));
          setProgress(100);
        }
      }
      setLoading(false);
    }, 3000);
  };

  const handleDelete = (data: CustomFileType) => {
    const fileItems = files?.filter(
      (item) => item.file.name !== data.file.name
    );

    setFiles(fileItems);
  };

  const handleFiles = (file: FileList) => {
    if (!file?.length) {
      return;
    }

    const files: CustomFileType[] = Array.from(
      new Array(file.length).keys()
    ).map((_, index) => ({
      file: file[index],
    }));

    setFiles(files);
  };

  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  useEffect(() => {
    if (files) {
      onChange?.(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const [progress, setProgress] = useState(0);

  const handleProgress = () => {
    let currentProgess = 0;
    let steps = 1;

    const timer = setInterval(function () {
      currentProgess += steps;
      setProgress(currentProgess);

      if (currentProgess >= 70) {
        clearInterval(timer);
      }
    }, steps);
  };

  return (
    <>
      {Boolean(progress) && (
        <div className="mb-5 w-full bg-gray-200 rounded-full dark:bg-gray-700">
          <div
            className="h-7 flex justify-center items-center bg-lime-700 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
            style={{ width: progress + "%" }}
          >
            {progress} %
          </div>
        </div>
      )}
      <form
        className="bg-blue-800 w-full h-72 text-center"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          multiple={true}
          onChange={handleChange}
        />
        <label
          htmlFor="input-file-upload"
          className={`${
            dragActive ? "bg-white" : "bg-blue-800"
          } w-full h-full flex items-center justify-center border-2 border-gray-200 border-dashed rounded`}
        >
          <div>
            {loading && <Icon name={IconEnum.loading} />}
            {!loading && (
              <>
                <div className="mb-5">
                  <p className="text-gray-200 text-xl">{t("newCamp.drag")}</p>
                  <span className="text-gray-200 mt-2">{t("newCamp.or")}</span>
                </div>
                <div className="w-6/12 md:w-4/5 lg:w-full m-auto">
                  <Button label={t("newCamp.choose")} onClick={onButtonClick} />
                </div>

                <p className="pt-4 text-lime-500 text-sm">
                  {t("newCamp.weOnly")}
                </p>
                <p className="pt-4 text-lime-500 text-sm">
                  {t("newCamp.videonames")}
                </p>
                <div>
                  {files && (
                    <ul className="mt-5">
                      {files.map((item, index) => (
                        <div className="flex w-full items-center space-x-2 justify-center">
                          <li key={index} className="text-white">
                            {item.file.name}
                          </li>
                          <div
                            className="cursor-pointer"
                            onClick={() => handleDelete(item)}
                          >
                            <img
                              src={DeleteIcon}
                              alt={String(t("newCamp.deleteVideo"))}
                              width={20}
                            />
                          </div>
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            className="w-full h-72 rounded bottom-0 right-0 left-0 -mt-72 z-1 relative"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </>
  );
};

export default DragDropFile;

import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { useParams } from "react-router-dom";
import services from "../../services/api";
import { useAuthContext } from "../../store/context/AuthContext";
import Loader from "../../components/Loader";
import { EventType } from "../Events/eventsTableColumns";
import { useTranslation } from "react-i18next";

const Video: React.FC = () => {
  const [event, setEvent] = useState<EventType>({} as EventType);

  const { t } = useTranslation();

  const { handleLoading, loading } = useAuthContext();

  const { id } = useParams();

  const loadEvent = async () => {
    handleLoading(true);
    const response = services.events.detail(Number(id));
    response
      .then((response) => {
        setEvent(response.data);

        handleLoading(false);
      })
      .finally(() => handleLoading(false));
  };

  useEffect(() => {
    loadEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page width="medium">
      <Loader visible={loading} />
      {event && (
        <>
          <div className="bg-blue-700 rounded block float-left w-full">
            <div className="flex flex-wrap justify-between pl-15 pr-15">
              {
                <div
                  className="w-full flex justify-between"
                  key={String(String(event.id) + event.title)}
                >
                  <div className="w-6/12 flex items-center">
                    <div className="h-full w-full cursor-pointer">
                      <video className="w-full h-80" controls>
                        <source src={event.media} type="video/mp4" />
                        {t("newCamp.suport")}
                      </video>
                    </div>
                  </div>
                  <div className="w-6/12 flex flex-col items-center justify-center">
                    <h1 className="text-white mb-3 pl-5 text-medium uppercase w-full">
                      {event.title}
                    </h1>
                    <div className="flex w-full justify-center">
                      <div className="text-white w-full h-full">
                        <textarea
                          className="w-full rounded-2xl mt-1 text-base px-4 py-4 bg-transparent text-light-blue-secondary border border-transparent focus:outline-none pb-28"
                          value={`<iframe width="560" height="315" src=${event.media} title="Moneyball video player" frameborder="0" allowfullscreen></iframe>`}
                        />
                      </div>
                    </div>
                    <div className="w-32 mt-5">
                      <Button label="Copy Link" variation="dark" />
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default Video;

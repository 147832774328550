import React, { useEffect, useState } from "react";
import Checkbox from "../../../../components/Form/Checkbox";
import { CustomerDataProps } from "./types";
import { useTranslation } from "react-i18next";

const CustomersData: React.FC<CustomerDataProps> = ({ setValue }) => {
  const [genders, setGenders] = useState<string[]>([]);
  const [ages, setAges] = useState<string[]>([]);
  const [parental, setParental] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleSetAge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const filterAges = ages.filter((age: string) => age !== value);
    if (event.target.checked) {
      setAges([...filterAges, value]);
      return;
    }
    setAges([...filterAges]);
  };

  const handleSetGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const filterGenders = genders.filter((gender: string) => gender !== value);

    if (event.target.checked) {
      setGenders([...filterGenders, value]);
      return;
    }

    setGenders([...filterGenders]);
  };

  const handleSetParental = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const filterParental = parental.filter(
      (parental: string) => parental !== value
    );

    if (event.target.checked) {
      setParental([...filterParental, value]);
      return;
    }

    setParental([...filterParental]);
  };

  useEffect(() => {
    setValue("campaign_genders", genders);
  }, [genders, setValue]);

  useEffect(() => {
    setValue("campaign_ages", ages);
  }, [ages, setValue]);

  useEffect(() => {
    setValue("campaign_parent_status", parental);
  }, [parental, setValue]);

  return (
    <>
      <div className="flex justify-center flex-col w-full">
        <p className="text-white mb-5 text-1xl">{t("newCamp.selectGender")}</p>

        <div className="md:pl-5 flex md:gap-0 gap-6 flex-wrap md:space-x-12 mt-5">
          <div>
            <span className="text-white text-lg"> {t("newCamp.gender")}</span>
            <div className="pt-4">
              <Checkbox
                label={t("newCamp.female")}
                name="female"
                value="F"
                onChange={handleSetGender}
              />
              <Checkbox
                label={t("newCamp.male")}
                name="male"
                value="M"
                onChange={handleSetGender}
              />
            </div>
          </div>
          <div>
            <span className="text-white text-lg"> {t("newCamp.age")}</span>
            <div className="pt-4">
              <Checkbox
                label="18 - 24"
                name="1824"
                value="18-24"
                onChange={handleSetAge}
              />
              <Checkbox
                label="25 -34"
                name="2534"
                value="25-34"
                onChange={handleSetAge}
              />
              <Checkbox
                label="45- 54"
                name="4554"
                value="45-54"
                onChange={handleSetAge}
              />
              <Checkbox
                label="55- 64"
                name="5564"
                value="55-64"
                onChange={handleSetAge}
              />
            </div>
          </div>
          <div>
            <span className="text-white text-lg"> {t("newCamp.parental")}</span>
            <div className="pt-4">
              <Checkbox
                label={t("newCamp.notParent")}
                name="notparent"
                value="notparent"
                onChange={handleSetParental}
              />
              <Checkbox
                label={t("newCamp.parent")}
                name="parent"
                value="parent"
                onChange={handleSetParental}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersData;

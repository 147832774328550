import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import TagList from "../../components/TagInput/TagList";
import { formatCurrency } from "../../helpers";
import services from "../../services/api";
import { useAuthContext } from "../../store/context/AuthContext";
import { useTranslation } from "react-i18next";
import { convertToBRDate } from "../../utils/convertBRDate";

type CreatedBy = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
};

type Interests = {
  id: number;
  category: string;
  name: string;
};

type Languages = {
  id: number;
  name: string;
};

type Location = {
  city: string;
  province: string;
  country: string;
};

export interface CampaignInfoType {
  ages: string[];
  created_at: string;
  title: string;
  duration: number;
  start_date: string;
  end_date: string;
  genders: string[];
  interests: Interests[];
  languages: Languages[];
  locations: Location[];
  created_by: CreatedBy;
  mux_playback_id: string;
  parent_status: string[];
  thumbnail: string;
  total_budget: number;
  updated_at: string;
  updated_by: CreatedBy;
}

const currencyConfig = {
  country: "pt-PT",
  currency: "EUR",
};

const brCurrencyConfig = {
  country: "br-BR",
  currency: "BRL",
};

const CampaignDetail: React.FC = () => {
  const [campaignInfo, setCampaignInfo] = useState<CampaignInfoType>();
  const [organizationType, setOrganizationType] = useState("");
  const { user } = useAuthContext();
  const { id } = useParams();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getCampaignInfo = async () => {
    try {
      const response = await services.campaigns.get(String(id));
      setCampaignInfo(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    const response = services.users.organization(user?.organization);

    response.then((organization) =>
      setOrganizationType(organization.data.business)
    );
    getCampaignInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCampaignInfo();
  }, [campaignInfo?.mux_playback_id]);

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Page title="Campaign Details" hasTitle={false}>
      {campaignInfo?.title ? (
        <div className="w-full h-full relative left-0 right-0 top-0 bottom-0 flex items-center justify-center z-10">
          <Link
            to={
              organizationType === "brd" ? "/campaigns" : "/campaigns-candidate"
            }
            className="absolute cursor-pointer items-center flex gap-2 left-0 -top-12"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            <p className="text-white text-xl">{t("campDetails.back")}</p>
          </Link>
          <div className="w-full text-white">
            <div className="py-1 rounded block float-left w-full">
              <div className="flex flex-wrap font-inter flex-col justify-center items-center md:px-15">
                {
                  <div className="w-full md:w-[628px] md:h-[409px] rounded-lg flex items-center flex-col">
                    <div className="w-full h-full flex items-center">
                      <div className="h-full w-full rounded-lg cursor-pointer">
                        {campaignInfo.mux_playback_id ? (
                          ReactHtmlParser(`<mux-player
              stream-type="on-demand"
              playback-id=${campaignInfo?.mux_playback_id}    
              style="border-radius: 30%; height: 409px; max-width:628px;"                  
              metadata-video-title="Placeholder (optional)"
              metadata-viewer-user-id="Placeholder (optional)"
              primary-color="#FFFFFF"
              secondary-color="#000000">
            </mux-player>`)
                        ) : (
                          <div>
                            <p className="flex mt-[20%] text-2xl items-center justify-center">
                              {t("campDetails.loading")}
                            </p>
                            <Loading />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }

                <div className="mt-6">
                  <p className="font-bold font-mulish text-2xl pb-4">
                    {capitalizeFirstLetter(campaignInfo?.title)}
                  </p>

                  <p className="font-medium text-xl text-gray-200">
                    {t("campDetails.created")}
                  </p>
                  <p className="font-semibold text-xl font-mulish">
                    {campaignInfo?.created_by?.first_name}{" "}
                    {campaignInfo?.created_by?.last_name}
                  </p>

                  <p className="font-medium text-xl pt-4 text-gray-200">
                    {t("campDetails.date")}
                  </p>
                  <p className="font-semibold text-xl font-mulish pb-9">
                    {currentLanguage === "pt-BR"
                      ? convertToBRDate(campaignInfo?.created_at)
                      : campaignInfo?.created_at}
                  </p>

                  <ul className="text-gray-300 text-xl">
                    <li className="mb-10">
                      <p className="pb-4">1. {t("campDetails.where")}</p>
                      <TagList
                        data={campaignInfo?.locations.map((item, index) => {
                          return {
                            id: index,
                            name: `${item.city}, ${item.country}`,
                          };
                        })}
                      />
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">2. {t("campDetails.language")}</p>
                      <TagList data={campaignInfo?.languages!} />
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">3. {t("campDetails.select")}</p>
                      <div className="flex items-center gap-3">
                        <TagList
                          data={[
                            {
                              id: 2,
                              name: String(
                                campaignInfo?.genders[0]?.toUpperCase() === "M"
                                  ? t("campDetails.male")
                                  : t("campDetails.female")
                              ),
                            },
                          ]}
                        />
                        <TagList
                          data={[
                            {
                              id: 2,
                              name: String(
                                campaignInfo?.genders[1]?.toUpperCase() === "M"
                                  ? t("campDetails.male")
                                  : t("campDetails.female")
                              ),
                            },
                          ]}
                        />
                        <TagList
                          data={[{ id: 3, name: String(campaignInfo?.ages) }]}
                        />
                      </div>
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">4. {t("campDetails.tell")}</p>
                      <TagList data={campaignInfo?.interests!} />
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">5. {t("campDetails.budget")}</p>
                      <div className="space-x-2 flex text-sm">
                        <p>{t("campDetails.total")}:</p>
                        <p className="font-bold">
                          {currentLanguage === "pt-br"
                            ? formatCurrency(
                                campaignInfo?.total_budget,
                                brCurrencyConfig.country,
                                brCurrencyConfig.currency
                              )
                            : formatCurrency(
                                campaignInfo?.total_budget,
                                currencyConfig.country,
                                currencyConfig.currency
                              )}
                        </p>
                      </div>
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">6. {t("campDetails.duration")}</p>
                      <TagList
                        data={[
                          {
                            id: 3,
                            name:
                              String(campaignInfo?.duration) +
                              ` ${
                                campaignInfo.duration > 1
                                  ? t("campDetails.days")
                                  : t("campDetails.day")
                              }`,
                          },
                        ]}
                      />
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">7. {t("campDetails.start")}</p>
                      <TagList
                        data={[
                          {
                            id: 3,
                            name: convertToBRDate(campaignInfo?.start_date),
                          },
                        ]}
                      />
                    </li>

                    <li className="mb-10">
                      <p className="pb-4">8. {t("campDetails.end")}</p>
                      <TagList
                        data={[
                          {
                            id: 3,
                            name: convertToBRDate(campaignInfo?.end_date),
                          },
                        ]}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default CampaignDetail;

import React, { useEffect, useState } from "react";
import Input from "../../../../components/Form/Input";
import { formatCurrency } from "../../../../helpers";
import { BudgetProps } from "./types";
import services from "../../../../services/api";
import InputSelectCurrency from "../../../../components/InputSelectCurrency";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const currencyConfig = {
  country: "pt-PT",
  currency: "EUR",
};

const brCurrencyConfig = {
  country: "br-BR",
  currency: "BRL",
};

export interface CurrencyType {
  id: number;
  name: string;
  abbreviation: string;
}

const Budget: React.FC<BudgetProps> = ({ type = "smart_distr", setValue }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [inputValue, setInputValue] = useState<string>("100");

  const [currency, setCurrency] = useState<string>(currencyConfig.currency);

  const [startDate, setStartDate] = useState<Date>();

  const [endDate, setEndDate] = useState<Date>();

  const [coins, setCoins] = useState<CurrencyType[]>();

  useEffect(() => {
    if (currentLanguage === "pt-br") {
      setValue("campaign_currency", 2);
      return;
    }

    const formattedValue = formatCurrency(
      Number(100),
      currencyConfig.country,
      currency
    );

    const listCurrencies = async () => {
      const response = await services.campaigns.getCurrencies();

      const data = response.data.map((item: CurrencyType) => {
        return {
          id: item.id,
          name: item.name,
          abbreviation: item.abbreviation,
        };
      });
      setCoins(data);
    };

    listCurrencies();
    setInputValue(formattedValue);
  }, [currency]);

  const handleStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDay = Number(event.target.value.slice(-2));
    const inputDate = new Date(new Date(event.target.value).setDate(inputDay));

    setStartDate(inputDate);
    setValue("campaign_start_date", event.target.value);
  };

  const handleEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputDay = Number(event.target.value.slice(-2));
    const inputDate = new Date(new Date(event.target.value).setDate(inputDay));
    const hasTenNumbers = inputDate.toString();
    console.log(hasTenNumbers);

    if (!startDate) {
      return toast.error(t("newCamp.first"));
    }

    setEndDate(inputDate);
    setValue("campaign_end_date", event.target.value);
  };

  const getCurrentDateInput = (date?: Date) => {
    if (!date) {
      return "yyy-MM-dd";
    }

    const dateObj = date;

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    return shortDate;
  };

  const defaultEnCurrency = coins?.find((item) => item.abbreviation === "USD");

  useEffect(() => {
    if (currentLanguage === "pt-br") {
      setValue("campaign_currency", 2);
      return;
    }

    setValue("campaign_currency", Number(defaultEnCurrency));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const verifyStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const inputDay = Number(event.target.value.slice(-2));
    const inputDate = new Date(new Date(event.target.value).setDate(inputDay));

    if (inputDate.getTime() < today.getTime()) {
      setStartDate(undefined);
      return toast.error(t("newCamp.earliertoday"));
    }
  };

  const verifyEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const inputDay = Number(event.target.value.slice(-2));
    const inputDate = new Date(new Date(event.target.value).setDate(inputDay));
    const hasTenNumbers = inputDate.toString();
    console.log(hasTenNumbers);

    if (inputDate.getTime() < today.getTime()) {
      setEndDate(undefined);
      return toast.error(t("newCamp.earliertoday"));
    }

    if (!startDate) {
      return toast.error(t("newCamp.first"));
    }
  };

  return (
    <div className="flex justify-center flex-col">
      {localStorage.getItem("distribution_type") === "smart_distr" && (
        <form>
          <div className="flex w-full mt-5 flex-col">
            <p className="text-white text-base mb-2">{t("newCamp.event")}</p>
            <Input
              label={String(t("newCamp.estimated"))}
              placeholder={inputValue}
              type="text"
              variation="secondary"
              value={inputValue.includes("NaN") ? "0" : inputValue}
              onChange={(event) => {
                setInputValue(event.target.value);
                setValue("campaign_event_budget", event.target.value);
              }}
              onBlur={() => {
                setInputValue(
                  formatCurrency(
                    Number(inputValue),
                    currencyConfig.country,
                    currency
                  )
                );
              }}
            />
            <div className="flex space-x-2 w-full mt-5 flex-col">
              <InputSelectCurrency
                label={t("newCamp.currency")}
                options={coins || []}
                onChangeSelect={(option) => {
                  setInputValue(
                    formatCurrency(
                      Number(option),
                      currencyConfig.country,
                      currency
                    )
                  );
                  setCurrency(option.target.value);
                }}
              />
            </div>
          </div>
        </form>
      )}
      <p className="text-white mb-8 text-1xl">{t("newCamp.set")}</p>
      <form>
        <p className="text-white text-base mb-2">{t("newCamp.duration")}</p>
        <div className="mt-5">
          <label>
            <span className="font-normal text-blue-400 text-sm">
              {String(t("newCamp.startdate"))}
            </span>
          </label>
          <input
            className="rounded-2xl mt-1 text-sm w-full px-4 py-4 bg-light-blue text-light-blue-secondary border border-transparent focus:outline-none focus:border-lime-500 focus:ring-1 focus:ring-lime-500"
            type="date"
            onChange={handleStartDate}
            onBlur={verifyStartDate}
            style={{ colorScheme: "dark" }}
          />
        </div>

        <div className="mt-5">
          <label>
            <span className="font-normal text-blue-400 text-sm">
              {String(t("newCamp.endate"))}
            </span>
          </label>
          <input
            className="rounded-2xl mt-1 text-sm w-full px-4 py-4 bg-light-blue text-light-blue-secondary border border-transparent focus:outline-none focus:border-lime-500 focus:ring-1 focus:ring-lime-500"
            type="date"
            onChange={handleEndDate}
            onBlur={verifyEndDate}
            style={{ colorScheme: "dark" }}
          />
        </div>

        <div className="flex space-x-2 w-full mt-5 flex-col">
          <p className="text-white text-base mb-2">{t("newCamp.daily")}</p>
          {currentLanguage === "pt-br" ? (
            <Input
              placeholder={inputValue}
              type="text"
              variation="secondary"
              value={inputValue.includes("NaN") ? "0" : inputValue}
              onChange={(event) => {
                console.log(event.target.value);
                setInputValue(event.target.value);
                setValue("campaign_daily_budget", event.target.value);
              }}
              onBlur={() => {
                console.log("input value", inputValue);
                if (Number(inputValue) < 50) {
                  setInputValue("0");
                  setValue("campaign_daily_budget", "0");
                  return toast.error(t("newCamp.error"));
                }
                setInputValue(
                  formatCurrency(
                    Number(inputValue),
                    brCurrencyConfig.country,
                    brCurrencyConfig.currency
                  )
                );
              }}
            />
          ) : (
            <Input
              placeholder={inputValue}
              type="text"
              variation="secondary"
              value={inputValue.includes("NaN") ? "0" : inputValue}
              onChange={(event) => {
                console.log(event.target.value);
                setInputValue(event.target.value);
                setValue("campaign_daily_budget", event.target.value);
              }}
              onBlur={() => {
                console.log(inputValue);
                if (Number(inputValue) < 50) {
                  return toast.error(t("newCamp.error"));
                }
                setInputValue(
                  formatCurrency(
                    Number(inputValue),
                    currencyConfig.country,
                    currency
                  )
                );
              }}
            />
          )}
        </div>
        {currentLanguage !== "pt-br" && (
          <div className="flex space-x-2 w-full mt-5 flex-col">
            <InputSelectCurrency
              label="Currency"
              options={coins || []}
              onChangeSelect={(option) =>
                setValue("campaign_currency", Number(option.target.value))
              }
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default Budget;

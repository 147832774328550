import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import BrazilFlag from "../../assets/flags/brazil.svg";
import USFlag from "../../assets/flags/unitedstates.svg";

export type LanguageSelectorProps = {
  classNames?: string;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ classNames }) => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (selectedOption: any) => {
    const selectedLanguage = selectedOption.value;
    i18n.changeLanguage(selectedLanguage);
  };

  const currentLanguage = i18n.language;

  const options = [
    {
      value: "en",
      label: (
        <div>
          <img src={USFlag} alt="United States" width={30} />
        </div>
      ),
    },
    {
      value: "pt-br",
      label: (
        <div>
          <img src={BrazilFlag} alt="Brazil" width={30} />
        </div>
      ),
    },
  ];

  return (
    <div className={`inline-block top-2 left-3 ${classNames}`}>
      <Select
        className="bg-transparent"
        options={options}
        value={options.find((option) => option.value === currentLanguage)}
        onChange={handleLanguageChange}
      />
    </div>
  );
};

export default LanguageSelector;

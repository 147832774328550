import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const status = queryParams.get("status");
  const club = queryParams.get("club");

  const { t } = useTranslation();

  return (
    <div className="flex md:mt-[10%] mt-[50%] justify-center items-center">
      <div className="bg-blue-300 py-10 mx-3 px-2 md:p-20 md:w-8/12 rounded-2xl">
        <div className="flex justify-center flex-col items-center">
          <h2 className="text-3xl text-white">
            {status === "paid" ? "Success 🎉" : "Ooops"}{" "}
          </h2>
          {status === "paid" ? (
            <p className="text-white mt-5 mb-5 text-center">
              {t("feedback.congrats")} <strong> {club} </strong>{" "}
              {t("feedback.will")}
            </p>
          ) : (
            <p className="text-white mt-5 mb-5 text-center">
              {t("feedback.wecant")}
            </p>
          )}
          <div className="w-52">
            {status === "paid" ? (
              <Button
                label={String(t("feedback.continue"))}
                isFilled
                onClick={() => navigate("/campaigns")}
              />
            ) : (
              <Button
                isFilled
                variation="danger"
                label={String(t("feedback.try"))}
                onClick={() => navigate("/campaigns")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;

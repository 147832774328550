import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import { Logo } from "../../assets";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../store/context/AuthContext";
import Sidebar from "../Sidebar";
import services from "../../services/api";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

const Header: React.FC = () => {
  const { user } = useAuthContext();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [organizationType, setOrganizationType] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const response = services.users.organization(user?.organization);

    response.then((organization) =>
      setOrganizationType(organization.data.business)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationType]);

  const navigationItems = [
    {
      name: t("sidebar.stats"),
      link: "/dashboard",
      icon: IconEnum.dashboard,
    },
  ];

  if (organizationType === "brd") {
    navigationItems.push({
      name: t("sidebar.campaigns"),
      link: "/campaigns",
      icon: IconEnum.analytics,
    });
  }

  if (organizationType === "spt") {
    navigationItems.unshift({
      name: "Videos",
      link: "/videos",
      icon: IconEnum.integration,
    });

    navigationItems.unshift({
      name: t("sidebar.campaigns"),
      link: "/campaigns-candidate",
      icon: IconEnum.analytics,
    });
  }

  return (
    <header className="w-full">
      <Sidebar
        isOpen={openSidebar}
        setIsOpen={setOpenSidebar}
        navigationItems={navigationItems}
      />
      <div className="flex justify-between bg-blue-800 w-full py-5 pr-10">
        <div className="pl-10 flex gap-6">
          <div className="lg:hidden" onClick={() => setOpenSidebar(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#fff"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
          <Link to="/">
            <Logo width="76" height="36" />
          </Link>
        </div>
        <div className="flex">
          {false && (
            <div className="flex items-center pr-10">
              <Icon name={IconEnum.notify} />
              <div className="absolute text-white bg-gray-400 rounded-full text-xs p-1 text-light-50 mb-8 ml-5 pr-2 pl-2">
                10
              </div>
            </div>
          )}
          <div className="flex items-center">
            {user?.picture && (
              <img
                className="h-8 rounded-full p-0 mr-2"
                src={user?.picture}
                alt="User logo"
              ></img>
            )}
            {user?.first_name && (
              <p className="text-light text-gray-400 pr-5 mt-4">
                <Link to="/account/profile">
                  {t("sidebar.hello")},
                  <strong className="capitalize ml-1">{user.first_name}</strong>
                </Link>
              </p>
            )}
            <LanguageSelector classNames="relative" />
            {false && <Icon name={IconEnum.down} />}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import services from "../../services/api";
import { useAuthContext } from "../../store/context/AuthContext";
import SearchInput from "../../components/SearchInput";
import Loader from "../../components/Loader";
import { EventType } from "../Events/eventsTableColumns";
import ReactHtmlParser from "react-html-parser";
import PlayIcon from "../../assets/play.png";
import Icon from "../../components/Icon";
import DeleteIcon from "../../assets/delete.png";
import { IconEnum } from "../../components/Icon/types";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

const Videos: React.FC = () => {
  const [events, setEvents] = useState<EventType[]>([]);
  const [event, setEvent] = useState<EventType>({} as EventType);
  const [notFound, setNotFound] = useState(false);
  const [next, setNext] = useState<string>("");
  const [prev, setPrev] = useState<string>("");
  const { handleLoading, loading } = useAuthContext();

  const [deleteId, setDeleteId] = useState<number>();

  const hasPages = Boolean(next || prev);

  const { t } = useTranslation();

  const dropdownItems = [
    { name: "A-Z", value: "sort=alphabetical_asc" },
    { name: "Z-A", value: "sort=alphabetical_desc" },
    { name: t("campaigns.most"), value: "sort=created_desc" },
    { name: t("campaigns.least"), value: "sort=created_asc" },
  ];

  const [selected, setSelected] = useState(dropdownItems[2]);

  const loadEvents = async (page?: string) => {
    handleLoading(true);
    const response = services.events.list(page, selected.value);
    response
      .then((response) => {
        let events = response.data;

        if (events?.results?.length) {
          events = events?.results?.map((event: EventType) => {
            return {
              id: event.id,
              uuid: event.uuid,
              mux_playback_id: event.mux_playback_id,
              title: event.title,
              thumbnail: event.thumbnail,
              //thumbnail: `<img src=${event.thumbnail} alt=${event.title} width="100" />`,
              media: event.media,
            };
          });

          setPrev(response.data.previous);

          setNext(response.data.next);
          setEvents(events);
        } else {
          setEvents([]);
        }

        handleLoading(false);
      })
      .finally(() => handleLoading(false));
  };

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const navigate = useNavigate();

  const onView = async (event: EventType) => {
    setEvent(event);
  };

  const onDelete = async (id?: number) => {
    try {
      if (id) {
        setDeleteId(id);
        if (deleteId) {
          await services.events.delete(id);
          await loadEvents();
          setDeleteId(0);
        }
      }
    } catch {
      toast.error(t("video.youcant"));
    }
  };

  const handlePrev = async () => {
    await loadEvents(prev);
    return;
  };

  const handleNext = async () => {
    await loadEvents(next);
    return;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `<iframe width="560" height="315" src="https://www.app.getmoneyball.tv/embed/video?id=${event.uuid}&width=560&height=315&loading=false&autoplay=false" title="${event.title}" frameborder="0"></iframe>`
    );
    toast.success(t("video.copied"));
  };

  const handleFilterEvents = (event: React.ChangeEvent<HTMLInputElement>) => {
    const getEvents = [...events];

    const value = event.target.value;

    const filterEvents = getEvents.filter((eventItem) =>
      eventItem.title?.toLowerCase().includes(value.toLowerCase())
    );

    if (!value.length) {
      setNotFound(false);
      setEvents(events);
    }

    if (filterEvents.length) {
      setEvents(filterEvents);
      return;
    }

    if (!filterEvents.length) {
      setNotFound(true);
      return;
    }
  };

  const notHasEvents = !Boolean(events.length) || notFound;

  return (
    <Page width="large">
      <div className="w-full flex flex-col items-start mb-10">
        <div className="w-full flex items-end mb-8 justify-between">
          <div className="md:w-6/12 sm:m-0">
            <p className="text-white font-base text-2xl mb-5">
              {t("video.your")}
            </p>
            <SearchInput
              placeholder={String(t("video.search"))}
              onChange={handleFilterEvents}
            />
          </div>
          <div className="md:w-64 w-32 mb-2 ml-2 md:ml-0 md:mb-0">
            <Button
              isFilled
              label={t("video.upload")}
              onClick={() => navigate("/event/new")}
            />
          </div>
        </div>
      </div>
      {event.media && (
        <div className="fixed w-full h-full left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-black/60 z-50">
          <div className="container">
            <div className="bg-blue-700 rounded block float-left w-full">
              <div className="flex flex-wrap justify-between pl-15 pr-15">
                {
                  <div
                    className="w-full flex justify-between"
                    key={String(String(event.id) + event.title)}
                  >
                    <div className="w-5/12 flex items-center">
                      <div className="h-full cursor-pointer">
                        {ReactHtmlParser(
                          `<iframe width="560" height="315" src="https://www.app.getmoneyball.tv/embed/video?id=${event.uuid}&width=560&height=315&loading=false&autoplay=false" title="${event.title}" frameborder="0"></iframe>`
                        )}
                      </div>
                    </div>
                    <div className="w-6/12 flex items-center flex-col">
                      <h1 className="flex items-center justify-between text-white mb-3 pl-5 text-medium uppercase w-full border-b pt-5 pb-5 border-blue-1000">
                        {event.title}
                        <div
                          className="pr-5 cursor-pointer"
                          onClick={() => setEvent({})}
                        >
                          <Icon name={IconEnum.close} />
                        </div>
                      </h1>
                      <div className="flex w-full justify-center h-full">
                        <div className="text-white flex-col px-4 gap-4 justify-center text-center w-full h-full flex items-center">
                          <p className="text-white text-xl">
                            {t("video.please")}
                          </p>
                          <textarea
                            className="w-full rounded-2xl  mt-1 text-base py-4 bg-transparent text-light-blue-secondary border border-transparent focus:outline-none"
                            value={`<iframe width="560" height="315" src="https://www.app.getmoneyball.tv/embed/video?id=${event.uuid}&width=560&height=315&loading=false&autoplay=false" title="${event.title}" frameborder="0"></iframe>`}
                          />
                        </div>
                      </div>
                      <div className="w-full border border-blue-1000 flex justify-center items-center pt-3 pb-3">
                        <div className="w-32">
                          <Button
                            label={t("video.copy")}
                            variation="dark"
                            onClick={() => handleCopy()}
                          />
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => onDelete(Number(event.id))}
                        >
                          <img src={DeleteIcon} alt="Delete Video" width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      )}
      {notHasEvents && (
        <div className="w-full flex items-center justify-center space-y-3 flex-col">
          <div>
            <p className="text-white">{t("video.noEvents")}</p>
          </div>
        </div>
      )}
      <Loader visible={loading} />

      {!notFound && Boolean(events.length) && (
        <>
          <div className="flex justify-between mb-5">
            <div className="mb-3">
              <p className="text-white text-2xl">{t("video.videos")}</p>
              <Dropdown
                selected={selected}
                setSelected={setSelected}
                options={dropdownItems}
              />
            </div>
          </div>
          <div className="bg-blue-700 p-3 rounded md:px-10 block float-left px-5 w-full mb-8">
            <div className="flex flex-col sm:flex-row flex-wrap justify-between md:px-15 pb-12">
              {events.map((event) => {
                return (
                  <div
                    className="sm:w-6/12 w-full mt-12 px-5"
                    key={String(String(event.id) + event.title)}
                  >
                    <div className="flex justify-between items-center">
                      <h1 className="text-white mb-3 font-bold">
                        {event.title}
                      </h1>
                    </div>
                    <div className="w-full flex items-center justify-center h-64 overflow-hidden">
                      <LazyLoadImage
                        src={PlayIcon}
                        alt="Play"
                        className="absolute cursor-pointer"
                        onClick={() => onView(event)}
                        effect="blur"
                      />
                      <div
                        className="thumbnail h-full w-full cursor-pointer"
                        onClick={() => onView(event)}
                      >
                        <LazyLoadImage
                          src={event.thumbnail}
                          alt={event.title}
                          width="100"
                        />
                        {/* {ReactHtmlParser(String(event.thumbnail))} */}
                      </div>
                    </div>
                    <div className="flex space-x-3 w-full mt-5">
                      <Button
                        label={t("video.view")}
                        onClick={() => onView(event)}
                        variation="normal"
                      />
                      <Button
                        label={t("video.delete")}
                        onClick={() => onDelete(Number(event.id))}
                        variation="danger"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {Boolean(deleteId) && (
        <Modal
          title={t("video.sure")}
          labelPrimary={t("video.yes")}
          labelSecondary={t("video.cancel")}
          actionPrimary={() => onDelete(deleteId)}
          actionSecundary={() => setDeleteId(0)}
          toggle={false}
        />
      )}
      {hasPages && (
        <div className="mb-12 w-full flex space-x-5 justify-center">
          <div className="w-32">
            <Button
              disabled={!Boolean(prev)}
              label={t("video.previous")}
              isFilled
              onClick={() => handlePrev()}
            />
          </div>
          <div className="w-32">
            <Button
              disabled={!Boolean(next)}
              label={t("video.next")}
              isFilled
              onClick={() => handleNext()}
            />
          </div>
        </div>
      )}
    </Page>
  );
};

export default Videos;

import React, { useEffect, useState } from "react";
import InputSelect from "../../../../components/Form/InputSelect";
import Icon from "../../../../components/Icon";
import { IconEnum } from "../../../../components/Icon/types";
import Location from "../../../../components/Location";
import { languageOptions } from "./options";
import { CustomerLocationProps } from "./types";
import { useTranslation } from "react-i18next";

const CustomersLocation: React.FC<CustomerLocationProps> = ({ setValue }) => {
  const [languages, setLanguages] = useState<number[]>([]);
  const { t } = useTranslation();

  const defaultLanguage = languageOptions.find(
    (item) => item.label === "Português"
  );

  const handleSetLanguages = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event.target.value);
    const filterLanguages = languages.filter(
      (language: number) => language !== value
    );

    setLanguages([...filterLanguages, value]);
  };

  const handleDeleteLanguage = (value: number) => {
    const filterLanguages = languages.filter(
      (language: number) => language !== value
    );

    setLanguages(filterLanguages);
  };

  useEffect(() => {
    setValue("campaign_languages", languages);
  }, [languages, setValue]);

  useEffect(() => {
    setLanguages([Number(defaultLanguage?.value)]);
  }, []);

  return (
    <>
      <div className="flex justify-center flex-col">
        <Location
          label={t("newCamp.where")}
          placeholder={String(t("newCamp.addLoc"))}
          setValue={setValue}
        />
      </div>

      <div className="flex justify-center flex-col w-full">
        <p className="text-white mb-5 text-1xl">2. {t("newCamp.whatLang")}</p>

        <div className="md:pl-5">
          <div className="w-80">
            <InputSelect
              label={t("newCamp.addLang")}
              options={languageOptions}
              onChangeSelect={handleSetLanguages}
              defaultValue={defaultLanguage?.value}
            />
          </div>
          <div className="flex mt-3 flex-wrap">
            {languages.map((language) => {
              return (
                <div
                  className="flex mb-2 mr-2 justify-between items-center rounded bg-lime-700 p-2"
                  key={language}
                >
                  <label className="text-white">
                    {
                      languageOptions.find(
                        (languageOption) => languageOption.value === language
                      )?.label
                    }
                  </label>
                  <div
                    className="ml-2 cursor-pointer"
                    onClick={() => handleDeleteLanguage(language)}
                  >
                    <Icon name={IconEnum.close} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersLocation;

import React from "react";
import DragDropFile from "../../../../components/DragDropFile";
import SearchInput from "../../../../components/SearchInput";
import { useTranslation } from "react-i18next";

export type VideoAdProps = {
  onChange?: (video: File) => void;
};

const VideoAd: React.FC<VideoAdProps> = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-center flex-col">
        <p className="text-white mb-5 text-1xl">{t("newCamp.lets")}</p>
        <DragDropFile onChange={(files) => onChange?.(files[0].file)} />
      </div>

      <div className="mt-10" hidden>
        <div className="w-96">
          <p className="text-white mb-2">Your Moneyball video</p>
          <SearchInput placeholder="Search for your video or paster url here" />
        </div>
      </div>
    </>
  );
};

export default VideoAd;

import React from "react";
import { PieChartProps, RenderCustomizedLabelProps } from "./types";
import { PieChart as PieChartComponent, Pie, Cell, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;

const RenderCustomizedLabel: React.FC<RenderCustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart: React.FC<PieChartProps> = ({
  label,
  width,
  height,
  data,
  colors,
}) => {
  return (
    <div className="bg-blue-700 p-3 rounded-lg px-px-10 block float-left pr-5 pl-5">
      <p className="text-gray-400 font-bold text-lg">{label}</p>
      <PieChartComponent width={width} height={height}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={RenderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="key"
          stroke="none"
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChartComponent>
    </div>
  );
};

export default PieChart;

import Icon from "../../components/Icon";
import { IconEnum } from "../../components/Icon/types";
import { Column } from "../../components/Table/types";
import TableDropdown from "../../components/TableDropdown";

export type Campaign = {
  id?: number;
  performanceDetails?: string;
  totalSpending?: string;
  totalSales?: string;
  conversionRate?: string;
  clicks?: string;
  roas?: string;
};

export const performanceTableColumns = (
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
): Column<Campaign>[] => {
  const options = [
    {
      label: "Update",
      icon: <Icon name={IconEnum.analytics} />,
      isDisabled: !onEdit,
      action: (id: number) => onEdit?.(id),
    },
    {
      label: "Delete",
      icon: <Icon name={IconEnum.document} />,
      isDisabled: !onDelete,
      action: (id: number) => onDelete?.(id),
    },
  ];

  return [
    {
      title: "Performance Details",
      fieldResolver: (campaign) => campaign.performanceDetails,
    },
    {
      title: "Total Spending",
      fieldResolver: (campaign) => campaign.totalSpending,
    },
    {
      title: "Total Sales",
      fieldResolver: (campaign) => campaign.totalSales,
    },
    {
      title: "Conversion rate %",
      fieldResolver: (campaign) => campaign.conversionRate,
    },
    {
      title: "Numbers of clicks",
      fieldResolver: (campaign) => campaign.clicks,
    },
    {
      title: "ROAS",
      fieldResolver: (campaign) => campaign.roas,
    },
    {
      render: ({ row: trailer }) => (
        <TableDropdown options={options} id={Number(trailer.id)} />
      ),
    },
  ];
};

import * as yup from "yup";
import { requiredMessage } from "../../../helpers";

export const ResetPasswordSchema = yup.object().shape({
  email: yup.string().required(requiredMessage("Email")),
  confirmEmail: yup.string().required(requiredMessage("Confirm Email")),
});

export type ResetPasswordType = {
  email: string;
  confirmEmail: string;
};

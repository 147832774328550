import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import CreateEvent from "../components/CreateEvent";
import Loader from "../components/Loader";
import { useAuthContext } from "../store/context/AuthContext";
import Profile from "../views/Account/Profile";
import Analytics from "../views/Analytics";
import CampaignDetail from "../views/CampaignDetail";
import Campaign from "../views/Campaigns";
import CampaignsCandidate from "../views/CampaignsCandidate";
import CreateCampaign from "../views/CreateCampaign";
import Dashboard from "../views/Dashboard";
import Events from "../views/Events";
import Integration from "../views/Integration";
import Otp from "../views/Otp";
import Performance from "../views/Performance";
import ResetPassword from "../views/ResetPassword";
import StartCampaign from "../views/StartCampaign";
import Success from "../views/Sucess";
import Video from "../views/Video";
import Videos from "../views/Videos";
import services from "../services/api";
import Embed from "../views/Embed";

export type LoadableProps = {
  loading: boolean;
};

const Loadable = (Component: any) => (props: LoadableProps) =>
  (
    <Suspense>
      <Loader visible={props.loading} />
      <Component {...props} />{" "}
    </Suspense>
  );

const Login = Loadable(lazy(() => import("../views/Login")));
const Register = Loadable(lazy(() => import("../views/Register")));
const VerifyEmail = Loadable(lazy(() => import("../views/VerifyEmail")));

const LOGIN_REDIRECT = <Navigate to="/login" />;
const DASHBOARD_REDIRECT = <Navigate to="/dashboard" />;

export default function Router(): JSX.Element | null {
  const { authenticated: isLogged } = useAuthContext();
  const { loading, user } = useAuthContext();

  const [organizationType, setOrganizationType] = useState();

  useEffect(() => {
    const response = services.users.organization(user?.organization);

    response.then((organization) =>
      setOrganizationType(organization.data.business)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ROUTE_REDIRECT =
    organizationType === "brd" ? <Campaign /> : <CampaignsCandidate />;

  return useRoutes([
    {
      path: "*",
      element: (
        <h1 className="text-white h-full flex items-center justify-center text-3xl">
          Not found
        </h1>
      ),
    },

    {
      path: "/",
      element: isLogged ? ROUTE_REDIRECT : LOGIN_REDIRECT,
    },

    {
      path: "/dashboard",
      element: isLogged ? <Dashboard /> : LOGIN_REDIRECT,
    },
    {
      path: "/login",
      element: !isLogged ? <Login loading={loading} /> : DASHBOARD_REDIRECT,
    },
    {
      path: "/reset-password",
      element: !isLogged ? <ResetPassword /> : DASHBOARD_REDIRECT,
    },
    {
      path: "/otp",
      element: !isLogged ? <Otp /> : DASHBOARD_REDIRECT,
    },
    {
      path: "/verify-email",
      element: <VerifyEmail loading={loading} />,
    },
    {
      path: "/reset-password-confirm",
      element: !isLogged ? <ResetPassword /> : DASHBOARD_REDIRECT,
    },
    {
      path: "/register",
      element: <Register loading={loading} />,
    },
    {
      path: "/campaigns",
      element: isLogged ? <Campaign /> : LOGIN_REDIRECT,
    },
    {
      path: "/campaigns-candidate",
      element: isLogged ? <CampaignsCandidate /> : LOGIN_REDIRECT,
    },
    {
      path: "/campaigns/:id",
      element: isLogged ? <CampaignDetail /> : LOGIN_REDIRECT,
    },
    {
      path: "/embed/:id",
      element: <Embed />,
    },
    {
      path: "/events",
      element: isLogged ? <Events /> : LOGIN_REDIRECT,
    },
    {
      path: "/videos",
      element: isLogged ? <Videos /> : LOGIN_REDIRECT,
    },
    {
      path: "/videos/:id",
      element: isLogged ? <Video /> : LOGIN_REDIRECT,
    },
    {
      path: "/performance",
      element: isLogged ? <Performance /> : LOGIN_REDIRECT,
    },
    {
      path: "/integration",
      element: isLogged ? <Integration /> : LOGIN_REDIRECT,
    },
    {
      path: "/analytics",
      element: isLogged ? <Analytics /> : LOGIN_REDIRECT,
    },
    {
      path: "/account/profile",
      element: isLogged ? <Profile /> : LOGIN_REDIRECT,
    },
    {
      path: "/campaign/start",
      element: isLogged ? <StartCampaign /> : LOGIN_REDIRECT,
    },
    {
      path: "/campaign/new",
      element: isLogged ? <CreateCampaign /> : LOGIN_REDIRECT,
    },
    {
      path: "/event/new",
      element: isLogged ? <CreateEvent /> : LOGIN_REDIRECT,
    },
    {
      path: `/campaign-checkout`,
      element: <Success />,
    },
  ]);
}

import styled from "styled-components";

export default styled.div`
  input:checked ~ .dot {
    transform: translateX(100%);
  }

  input:checked ~ div.block {
    background: #6ccaa1;
  }
`;

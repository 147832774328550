import { AxiosInstance } from "axios";

const client = (httpClient: AxiosInstance) => ({
  get: async (id: number) => {
    const response = await httpClient.get(`/users/${id}`);

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  organization: async (id: number) => {
    const response = await httpClient.get(`/organizations/${id}`);

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
});

export default client;

import React, { useEffect, useState } from "react";
import Accordion from "../../../../components/Accordion";
import Checkbox from "../../../../components/Form/Checkbox";
import { InterestsProps } from "./types";
import services from "../../../../services/api";
import { useTranslation } from "react-i18next";

interface InterestsType {
  id: number;
  category: string;
  name: string;
}

const Interests: React.FC<InterestsProps> = ({ setValue }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [interests, setInterests] = useState<number[]>();
  const [listInterests, setListInterests] = useState<InterestsType[]>([]);

  const { t } = useTranslation();

  const handleInterests = (
    event: React.ChangeEvent<HTMLInputElement>,
    interest: number
  ) => {
    const value = interest;
    const filter = interests?.filter(
      (item: number) => item !== Number(value)
    ) as number[];

    if (!filter?.length) {
      setInterests([Number(interest)]);
      return;
    }

    if (event.target.checked) {
      setInterests([...filter, Number(interest)]);
      return;
    }

    setInterests([...filter]);
  };

  useEffect(() => {
    setValue("campaign_interests", interests as number[]);
  }, [interests, setValue]);

  const fetchInterests = async () => {
    const response = services.campaigns.listInterests();
    response.then((response) => {
      setListInterests(response.data);
    });
  };

  useEffect(() => {
    fetchInterests();
  }, []);

  const arts = [
    t("arts.arts"),
    t("arts.books"),
    t("arts.celebrity"),
    t("arts.fine"),
    t("arts.humor"),
    t("arts.movies"),
    t("arts.music"),
    t("arts.television"),
  ];

  const automotive = [
    t("automotive.automotive"),
    t("automotive.parts"),
    t("automotive.repair"),
    t("automotive.buying"),
    t("automotive.car"),
    t("automotive.certified"),
    t("automotive.convertible"),
    t("automotive.coupe"),
    t("automotive.diesel"),
    t("automotive.eletric"),
    t("automotive.hatchback"),
    t("automotive.hybrid"),
    t("automotive.luxury"),
    t("automotive.mini"),
    t("automotive.motor"),
    t("automotive.off"),
    t("automotive.performance"),
    t("automotive.pickup"),
    t("automotive.roadside"),
    t("automotive.sedan"),
    t("automotive.trucks"),
    t("automotive.vintage"),
    t("automotive.wagon"),
  ];

  const business = [
    t("business.business"),
    t("business.advertising"),
    t("business.agriculture"),
    t("business.biotech"),
    t("business.business_software"),
    t("business.construction"),
    t("business.forestry"),
    t("business.government"),
    t("business.green_solutions"),
    t("business.human_resources"),
    t("business.logistics"),
    t("business.marketing"),
    t("business.metals"),
    t("business.careers"),
    t("business.career_planning"),
    t("business.college"),
    t("business.financial_aid"),
    t("business.job_fairs"),
    t("business.job_search"),
    t("business.resume_writing"),
    t("business.nursing"),
    t("business.scholarships"),
    t("business.telecommuting"),
    t("business.us_military"),
    t("business.career_advice"),
  ];

  const education = [
    t("education.education"),
    t("education.7-12_education"),
    t("education.adult_education"),
    t("education.art_history"),
    t("education.college_administration"),
    t("education.college_life"),
    t("education.college_life"),
    t("education.distance_learning"),
    t("education.english_2nd_language"),
    t("education.language_school"),
    t("education.homeschooling"),
    t("education.homework"),
    t("education.k-6_educators"),
    t("education.private_school"),
    t("education.special_education"),
    t("education.business_studying"),
  ];

  const family = [
    t("family.family"),
    t("family.adoption"),
    t("family.babies"),
    t("family.daycare"),
    t("family.family_internet"),
    t("family.parenting_k6"),
    t("family.parenting_teens"),
    t("family.special_needs_kids"),
    t("family.eldercare"),
  ];

  const health = [
    t("health.health"),
    t("health.exercise"),
    t("health.add"),
    t("health.aids_hiv"),
    t("health.allergies"),
    t("health.alternative_medicine"),
    t("health.arthritis"),
    t("health.asthma"),
    t("health.autism_pdd"),
    t("health.bipolar_disorder"),
    t("health.brain_tumor"),
    t("health.cancer"),
    t("health.cholesterol"),
    t("health.chronic_fatigue"),
    t("health.chronic_pain"),
    t("health.cold_flu"),
    t("health.deafness"),
    t("health.dental_care"),
    t("health.depression"),
    t("health.dermatology"),
    t("health.diabetes"),
    t("health.epilepsy"),
    t("health.gerd_acid_reflux"),
    t("health.headaches_migraines"),
    t("health.heart_disease"),
    t("health.herbs"),
    t("health.holistic_healing"),
    t("health.ibs_crohns"),
    t("health.incest_abuse"),
    t("health.incontinence"),
    t("health.infertility"),
    t("health.mens_health"),
    t("health.nutrition"),
    t("health.orthopedics"),
    t("health.panic_anxiety"),
    t("health.pediatrics"),
    t("health.physical_therapy"),
    t("health.psychology_psychiatry"),
    t("health.senior_health"),
    t("health.sexuality"),
    t("health.sleep_disorders"),
    t("health.smoking_cessation"),
    t("health.substance_abuse"),
    t("health.thyroid_disease"),
    t("health.weight_loss"),
    t("health.womens_health"),
  ];

  const food = [
    t("food.food"),
    t("food.american_cuisine"),
    t("food.barbecues_grilling"),
    t("food.cajun_creole"),
    t("food.chinese_cuisine"),
    t("food.cocktails_beer"),
    t("food.coffee_tea"),
    t("food.cuisine_specific"),
    t("food.desserts_baking"),
    t("food.dining_out"),
    t("food.food_allergies"),
    t("food.french_cuisine"),
    t("food.health_lowfat_cooking"),
    t("food.italian_cuisine"),
    t("food.japanese_cuisine"),
    t("food.mexican_cuisine"),
    t("food.vegan"),
    t("food.vegetarian"),
    t("food.wine"),
  ];

  const hobbies = [
    t("hobbies.hobbies"),
    t("hobbies.art_technology"),
    t("hobbies.arts_crafts"),
    t("hobbies.beadwork"),
    t("hobbies.birdwatching"),
    t("hobbies.board_games_puzzles"),
    t("hobbies.candle_soap_making"),
    t("hobbies.card_games"),
    t("hobbies.chess"),
    t("hobbies.cigars"),
    t("hobbies.collecting"),
    t("hobbies.comic_books"),
    t("hobbies.drawing_sketching"),
    t("hobbies.freelance_writing"),
    t("hobbies.genealogy"),
    t("hobbies.getting_published"),
    t("hobbies.guitar"),
    t("hobbies.home_recording"),
    t("hobbies.investors_patents"),
    t("hobbies.jewelry_making"),
    t("hobbies.magic_illusion"),
    t("hobbies.needlework"),
    t("hobbies.painting"),
    t("hobbies.photography"),
    t("hobbies.radio"),
    t("hobbies.roleplaying_games"),
    t("hobbies.sci_fi_fantasy"),
    t("hobbies.scrapbooking"),
    t("hobbies.screenwriting"),
    t("hobbies.stamps_coins"),
    t("hobbies.video_computer_games"),
    t("hobbies.woodworking"),
  ];

  const homegarden = [
    t("homegarden.home_garden"),
    t("homegarden.appliances"),
    t("homegarden.entertaining"),
    t("homegarden.environmental_safety"),
    t("homegarden.gardening"),
    t("homegarden.home_repair"),
    t("homegarden.home_theater"),
    t("homegarden.interior_decorating"),
    t("homegarden.landscaping"),
    t("homegarden.remodeling_construction"),
  ];

  const lawgov = [
    t("lawgov.law_gov_politics"),
    t("lawgov.immigration"),
    t("lawgov.legal_issues"),
    t("lawgov.us_government_resources"),
    t("lawgov.politics"),
    t("lawgov.commentary"),
  ];

  const news = [
    t("news.news"),
    t("news.international_news"),
    t("news.national_news"),
    t("news.local_news"),
  ];

  const personalfinance = [
    t("personal.personal_finance"),
    t("personal.beginning_investing"),
    t("personal.credit_debt_loans"),
    t("personal.financial_news"),
    t("personal.financial_planning"),
    t("personal.hedge_fund"),
    t("personal.insurance"),
    t("personal.investing"),
    t("personal.mutual_funds"),
    t("personal.options"),
    t("personal.retirement_planning"),
    t("personal.stocks"),
  ];

  const sports = [
    t("sports.sports"),
    t("sports.baseball"),
    t("sports.basketball"),
    t("sports.biking"),
    t("sports.bodybuilding"),
    t("sports.boxing"),
    t("sports.canoeing_kayaking"),
    t("sports.cheerleading"),
    t("sports.climbing"),
    t("sports.cricket"),
    t("sports.figure_skating"),
    t("sports.fishing"),
    t("sports.fly_fishing"),
    t("sports.football"),
    t("sports.freshwater_fishing"),
    t("sports.golf"),
    t("sports.hiking"),
    t("sports.horse_racing"),
    t("sports.horses"),
    t("sports.hunting_shooting"),
    t("sports.inline_skating"),
    t("sports.martial_arts"),
    t("sports.mountain_biking"),
    t("sports.nascar_racing"),
    t("sports.olympics"),
    t("sports.paintball"),
    t("sports.power_motorcycles"),
    t("sports.pro_basketball"),
    t("sports.pro_ice_hockey"),
    t("sports.roller_skating"),
    t("sports.rugby"),
    t("sports.running_jogging"),
    t("sports.sailing"),
    t("sports.saltwater_fishing"),
    t("sports.scuba_diving"),
    t("sports.skateboarding"),
    t("sports.skiing"),
    t("sports.snowboarding"),
    t("sports.surfing_bodyboarding"),
    t("sports.swimming"),
    t("sports.table_tennis_ping_pong"),
    t("sports.tennis"),
    t("sports.triathlon"),
    t("sports.volleyball"),
    t("sports.walking"),
    t("sports.waterski_wakeboard"),
    t("sports.whitewater_rafting"),
    t("sports.world_soccer"),
  ];

  const technology = [
    t("tech.technology"),
    t("tech.3d_graphics"),
    t("tech.animation"),
    t("tech.antivirus"),
    t("tech.c_c++"),
    t("tech.cameras_camcorders"),
    t("tech.cell_phones"),
    t("tech.computer_certification"),
    t("tech.computer_networking"),
    t("tech.computer_peripherals"),
    t("tech.computer_reviews"),
    t("tech.data_centers"),
    t("tech.databases"),
    t("tech.desktop_publishing"),
    t("tech.desktop_video"),
    t("tech.email"),
    t("tech.graphics_software"),
    t("tech.home_video_dvd"),
    t("tech.internet_technology"),
    t("tech.java"),
    t("tech.javascript"),
    t("tech.mp3_midi"),
    t("tech.net_conferencing"),
    t("tech.net_for_beginners"),
    t("tech.network_security"),
    t("tech.open_source"),
    t("tech.operating_systems"),
    t("tech.pc_support"),
    t("tech.portable"),
    t("tech.powerpoint"),
    t("tech.presentation_software"),
    t("tech.project_management"),
    t("tech.python"),
    t("tech.registered_nursing"),
    t("tech.satellite"),
    t("tech.shareware_freeware"),
    t("tech.simulation"),
    t("tech.smartphones"),
    t("tech.spreadsheet_software"),
    t("tech.telecommuting"),
    t("tech.unix"),
    t("tech.visual_basic"),
    t("tech.web_clip_art"),
    t("tech.web_design_html"),
    t("tech.web_search"),
    t("tech.windows"),
  ];

  const travel = [
    t("travel.travel"),
    t("travel.africa"),
    t("travel.air_travel"),
    t("travel.australia_newzealand"),
    t("travel.bed_breakfasts"),
    t("travel.budget_travel"),
    t("travel.business_travel"),
    t("travel.by_us_locale"),
    t("travel.camping"),
    t("travel.canada"),
    t("travel.caribbean"),
    t("travel.cruises"),
    t("travel.eastern_europe"),
    t("travel.europe"),
    t("travel.france"),
    t("travel.greece"),
    t("travel.honeymoon_getaways"),
    t("travel.hotels"),
    t("travel.italy"),
    t("travel.japan"),
    t("travel.mexico_centralamerica"),
    t("travel.national_parks"),
    t("travel.south_america"),
    t("travel.spas"),
    t("travel.theme_parks"),
    t("travel.traveling_with_kids"),
    t("travel.united_kingdom"),
  ];

  const society = [
    t("society.society"),
    t("society.dating"),
    t("society.divorce_support"),
    t("society.gay_life"),
    t("society.marriage"),
    t("society.senior_living"),
    t("society.teens"),
    t("society.weddings"),
    t("society.ethnic_specific"),
  ];

  const science = [
    t("science.science"),
    t("science.astrology"),
    t("science.biology"),
    t("science.chemistry"),
    t("science.geology"),
    t("science.paranormal_phenomena"),
    t("science.physics"),
    t("science.space_astronomy"),
    t("science.geography"),
    t("science.botany"),
    t("science.weather"),
  ];

  const pets = [
    t("pets.pets"),
    t("pets.aquariums"),
    t("pets.birds"),
    t("pets.cats"),
    t("pets.dogs"),
    t("pets.large_animals"),
    t("pets.reptiles"),
    t("pets.veterinary_medicine"),
  ];

  const style = [
    t("style.style_fashion"),
    t("style.beauty"),
    t("style.body_art"),
    t("style.fashion"),
    t("style.jewelry"),
    t("style.clothing"),
    t("style.accessories"),
  ];

  const realstate = [
    t("realstate.real_estate"),
    t("realstate.apartments"),
    t("realstate.architects"),
    t("realstate.buying_selling_homes"),
  ];

  const shopping = [
    t("shopping.shopping"),
    t("shopping.contests_freebies"),
    t("shopping.couponing"),
    t("shopping.comparison_engines"),
  ];

  const religion = [
    t("religion.religion_spirituality"),
    t("religion.alternative_religions"),
    t("religion.atheism_agnosticism"),
    t("religion.buddhism"),
    t("religion.catholicism"),
    t("religion.christianity"),
    t("religion.hinduism"),
    t("religion.islam"),
    t("religion.judaism"),
    t("religion.latter_day_saints"),
    t("religion.pagan_wiccan"),
  ];

  return (
    <>
      <div className="flex justify-center flex-col w-full">
        <p className="text-white mb-5 text-1xl">{t("newCamp.tellus")}</p>

        <div className="pl-5 flex mt-5 flex-col space-y-4">
          <Accordion
            label={arts[0]}
            content={
              <>
                {listInterests
                  ?.filter((item) => item.category === "Arts & Entertainment")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={automotive[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Automotive")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={business[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Business")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Careers"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Careers")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={education[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Education")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={family[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Family & Parenting")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={health[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Health & Fitness")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Food & Drink"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Food & Drink")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Hobbies & Interests"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Hobbies & Interests")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Home & Garden"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Home & Garden")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={`Law, Gov't & Politics`}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Law, Gov't & Politics")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={news[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "News")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={personalfinance[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Personal Finance")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={society[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Society")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={science[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Science")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={pets[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Pets")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={sports[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Sports")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Style & Fashion"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Style & Fashion")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={"Technology & Computing"}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Technology & Computing")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={travel[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Travel")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={realstate[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Real Estate")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={shopping[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Shopping")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
          <Accordion
            label={religion[0]}
            content={
              <>
                {listInterests
                  .filter((item) => item.category === "Religion & Spirituality")
                  .map((item) => (
                    <Checkbox
                      label={item.name}
                      name={item.name}
                      variation="secondary"
                      onChange={(event) => handleInterests(event, item.id)}
                    />
                  ))}
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Interests;

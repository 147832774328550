import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/Form/Button";
import Input from "../../../components/Form/Input";
import Icon from "../../../components/Icon";
import { IconEnum } from "../../../components/Icon/types";
import Page from "../../../components/Page";
import useShowToastErrorMessage from "../../../hooks/toast/useShowToastErrorMessages";
import { useAuthContext } from "../../../store/context/AuthContext";
import services from "../../../services/api";
import { toast } from "react-toastify";
import {
  ProfileSchema,
  ProfileType,
} from "../../../settings/yup/schemas/ProfileSchema";
import User from "../../../assets/user.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Profile: React.FC = () => {
  const { user, handleUser, handleLoading } = useAuthContext();

  const [avatar, setAvatar] = useState<string>(user?.picture || User);

  const { t } = useTranslation();

  const onSubmit = async (data: ProfileType): Promise<void> => {
    handleLoading(true);
    const update = await services.auth.update(data);
    if (update?.data) {
      handleUser(update.data);
    }
    handleLoading(false);
    toast.success(t("settings.success"));
  };

  const { formState, handleSubmit, control, setValue } = useForm<ProfileType>({
    resolver: yupResolver(ProfileSchema),
  });

  const { errors } = formState;

  useShowToastErrorMessage(errors);

  const handleThumbnail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const image = URL.createObjectURL(file);

      setAvatar(image);
      setValue("picture", file);
    }
  };

  return (
    <Page>
      <div className="flex justify-center w-full">
        <div className="bg-blue-800 md:w-8/12 w-full md:p-10 p-6 rounded-2xl">
          <div className="flex items-center space-x-3">
            <Link to={"/"}>
              <Icon name={IconEnum.arrowLeft} />
            </Link>
            <h1 className="text-2xl text-white">{t("settings.profile")}</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex items-center flex-col">
              <Input
                type="file"
                id="upload-thumbnail"
                hidden
                onChange={handleThumbnail}
              />

              <label htmlFor="upload-thumbnail">
                <div className="justify-center mb-4 flex flex-col items-center cursor-pointer w-[170px]">
                  <img
                    src={avatar}
                    alt="user"
                    width={50}
                    height={50}
                    className="rounded-3xl w-14 h-14"
                  />
                  <span className="mt-4 w-full cursor-pointer rounded-2xl flex items-center justify-center py-3 border-transparent text-base text-white bg-gradient-to-r from-lime-500 to-lime-600 hover:from-lime-600 hover:to-lime-700 pr-0 pl-0">
                    {t("settings.upload")}
                  </span>
                </div>
              </label>
            </div>
            <div className="flex md:flex-row md:space-y-0 flex-col md:space-x-5 space-y-4 justify-between mt-4">
              <Controller
                defaultValue={user?.first_name}
                name="first_name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    {...fieldState}
                    type="text"
                    label={String(t("settings.first"))}
                    placeholder="First name"
                  />
                )}
              />
              <Controller
                defaultValue={user?.last_name}
                name="last_name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    {...fieldState}
                    type="text"
                    label={String(t("settings.last"))}
                    placeholder="Last name"
                  />
                )}
              />
              <Controller
                defaultValue={user?.email}
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    {...fieldState}
                    type="text"
                    label="Email"
                    placeholder="Email"
                  />
                )}
              />
            </div>
            <div className="flex mt-10 justify-end">
              <div className="w-5/12 flex justify-end">
                <Button label={String(t("settings.save"))} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default Profile;

import React from "react";
import { CheckboxProps } from "./types";

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  isChecked,
  variation = "primary",
  onChange,
  onClick,
  value,
}) => {
  return (
    <div className="flex items-center space-x-2 p-1 mb-2">
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isChecked}
        className="min-w-[16px] form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-lime-700 checked:border-lime-700 focus:outline-none transition duration-200 md:mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
        onChange={onChange}
        onClick={onClick}
        value={value}
      />
      <label
        htmlFor={name}
        className={
          variation === "secondary"
            ? "text-blue-400 text-base"
            : "text-white text-sm ml-5"
        }
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

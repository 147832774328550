export const formatCurrency = (
  value: number,
  locale: Intl.LocalesArgument,
  currency: string
) =>
  value?.toLocaleString(locale, {
    style: "currency",
    currency,
  });

export const requiredMessage = (fieldName: string): string => {
  return `${fieldName} o campo é obrigatório`;
};

export const setFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) =>
    formData.append(
      key,
      Array.isArray(object[key]) ? JSON.stringify(object[key]) : object[key]
    )
  );
  return formData;
};

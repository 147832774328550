import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Feedback from "./Steps/Feedback";
import VideoAd from "./Steps/VideoAd";
import Interests from "./Steps/Interests";
import CustomersLocation from "./Steps/CustomersLocation";
import CustomersData from "./Steps/CustomersData";
import { Step } from "./types";
import Distribution from "./Steps/Distribution";
import Budget from "./Steps/Budget";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CampaignType,
  CampaignSchema,
} from "../../settings/yup/schemas/CampaignSchema";
import { default as Campaign } from "./Steps/CampaignType";
import useShowToastErrorMessage from "../../hooks/toast/useShowToastErrorMessages";
import { AdType } from "./Steps/CampaignType/types";
import services from "../../services/api";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Progress from "../../components/Progress";
import { useTranslation } from "react-i18next";

const CreateCampaign: React.FC = () => {
  const { formState, setValue, watch } = useForm<CampaignType>({
    resolver: yupResolver(CampaignSchema),
  });

  const { errors } = formState;

  const formValues = watch();

  const { t } = useTranslation();

  useShowToastErrorMessage(errors);

  const [error, setError] = useState<boolean>(false);

  const steps: Step[] = [
    {
      index: 1,
      component: <Campaign setValue={setValue} />,
    },
    {
      index: 2,
      component: (
        <VideoAd
          onChange={(video) => {
            setValue("advertisement_media", video);
          }}
        />
      ),
    },
    {
      index: 3,
      component: <CustomersLocation setValue={setValue} />,
    },
    {
      index: 4,
      component: <CustomersData setValue={setValue} />,
    },
    {
      index: 5,
      component: <Interests setValue={setValue} />,
    },
    {
      index: 6,
      component: (
        <Distribution
          onChange={(event) => setValue("candidate_event", event)}
        />
      ),
    },
    {
      index: 7,
      component: <Budget setValue={setValue} />,
    },
    {
      index: 8,
      component: <Feedback hasError={error} />,
    },
  ];

  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);

  const progress = (currentStep.index / steps.length) * 100;

  const [progressbar, setProgressbar] = useState(0);

  const lastStep = steps.length;

  const isLastStep = currentStep.index === lastStep;

  const [isLoading, setIsLoading] = useState(false);

  const handleSetCurrentStep = async () => {
    window.scrollTo(0, 0);

    if (isLastStep) return;

    if (currentStep.index === 1) {
      if (!formValues.campaign_name) {
        toast.error(t("newCamp.requiredCamp"));
        return;
      }

      if (
        !formValues.advertisement_media &&
        formValues.advertisement_display !== "pre_roll"
      ) {
        toast.error(t("newCamp.requiredVideo"));
        return;
      }
    }

    if (currentStep.index === 2) {
      if (!formValues.campaign_locations) {
        toast.error(t("newCamp.requiredLocation"));
        return;
      }
    }

    if (currentStep.index === 3) {
      if (!formValues.campaign_languages.length) {
        toast.error(t("newCamp.requiredLanguage"));
        return;
      }
    }

    if (currentStep.index === 4) {
      if (!formValues.campaign_genders.length) {
        toast.error(t("newCamp.requiredGender"));
        return;
      }

      if (!formValues.campaign_ages.length) {
        toast.error(t("newCamp.requiredAge"));
        return;
      }

      if (!formValues.campaign_parent_status.length) {
        toast.error(t("newCamp.requiredParental"));
        return;
      }
    }

    if (currentStep.index === 5) {
      if (
        !formValues.campaign_interests ||
        !formValues.campaign_interests.length
      ) {
        toast.error(t("newCamp.tellus"));
        return;
      }
    }

    if (currentStep.index === 6) {
      if (!formValues.candidate_event) {
        toast.error(t("newCamp.selectVideo"));
        return;
      }
    }

    if (currentStep.index === 7) {
      if (
        !formValues.campaign_daily_budget ||
        formValues.campaign_daily_budget === "0"
      ) {
        toast.error(t("newCamp.validBudget"));
        return;
      }
      if (!formValues.campaign_start_date) {
        toast.error(t("newCamp.selectStart"));
        return;
      }
      if (!formValues.campaign_end_date) {
        toast.error(t("newCamp.selectEnd"));
        return;
      }
      if (!formValues.campaign_currency) {
        toast.error(t("newCamp.selectCurrency"));
        return;
      }
    }

    if (
      currentStep.index === 1 &&
      !(formValues.advertisement_display === AdType.video)
    ) {
      setCurrentStep(steps[currentStep.index + 1]);
      return;
    }

    setCurrentStep(steps[currentStep.index]);
  };

  const handleLastStep = () => {
    window.scrollTo(0, 0);

    if (
      currentStep.index === 3 &&
      !(formValues.advertisement_display === AdType.video)
    ) {
      setCurrentStep(steps[0]);
      return;
    }

    setCurrentStep(steps[currentStep.index - 2]);
  };

  useEffect(() => {
    if (isLastStep) {
      try {
        setError(false);
        setIsLoading(true);
        services.campaigns.create(formValues).then((res) => {
          window.location.href = res.data.checkout;
        });
      } catch {
        setError(true);
        setIsLoading(false);
      }
    }
  }, [isLastStep]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (progressbar < 100) {
          setProgressbar((prevWidth) => prevWidth + 1);
        }
      }, 30);
      return () => clearInterval(interval);
    }
  }, [progressbar, isLoading]);


  return (
    <div className="bg-blue-900 w-full h-full flex flex-col">
      <div className="w-full">
        <Header />
      </div>
      <div className="w-full flex mt-10 justify-center">
        <div className="md:ml-[200px] lg:w-[1072px] w-full ml-0">
          <div className="flex w-full flex-col md:px-0 px-6">
            <h1 className="md:text-4xl text-2xl text-white">
              {t("newCamp.title")}
            </h1>
            <p className="text-gray-400 mt-2">
              {t("newCamp.step")} {currentStep.index} {t("newCamp.of")}{" "}
              {steps.length}
            </p>
          </div>

          {isLoading && (
            <div className="mt-5 px-6 md:px-0 mb-10">
              <Progress progress={progressbar} />
            </div>
          )}

          {isLoading ? (
            <div className="flex pb-28 flex-col items-center justify-center m-auto">
              <Loading />
              <p className="text-white text-2xl px-6 mt-4">
                {t("newCamp.creating")}
              </p>
            </div>
          ) : (
            <div className="bg-blue-800 mx-2 md:p-20 p-6 rounded-2xl flex justify-center">
              <div className="w-full">
                {steps.map((step) => {
                  return (
                    <div
                      className={
                        step.index === currentStep.index
                          ? "visible"
                          : "invisible h-0 max-h-0"
                      }
                    >
                      {step.component}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="flex justify-end w-full mt-10 mb-10">
            <div className="w-52 flex space-x-5 mr-10">
              {currentStep.index > 1 && !isLastStep && (
                <Button
                  label={t("newCamp.back")}
                  isFilled
                  onClick={handleLastStep}
                />
              )}
              {!isLastStep && (
                <Button
                  isFilled
                  label={
                    currentStep.index === 7
                      ? t("newCamp.submit")
                      : t("newCamp.next")
                  }
                  variation="normal"
                  onClick={handleSetCurrentStep}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaign;

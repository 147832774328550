import React from "react";
import Logo from "../../assets/logo.png";
import LogoRF from "../../assets/logo_rf.png";
import Button from "../../components/Form/Button";
import Input from "../../components/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import useShowToastErrorMessage from "../../hooks/toast/useShowToastErrorMessages";
import services from "../../services/api";
import { toast } from "react-toastify";
import { useAuthContext } from "../../store/context/AuthContext";
import { OtpSchema, OtpType } from "../../settings/yup/schemas/OtpSchema";
import { useNavigate } from "react-router-dom";
import { HOME_VIDEO } from "../../constants/urls";

const Otp: React.FC = () => {
  const navigate = useNavigate();

  const { handleLoading } = useAuthContext();

  const onSubmit = async (data: OtpType) => {
    try {
      handleLoading(true);
      await services.auth.newPassword(data);
      toast.success("Password successfully updated!");
      navigate("/login");
    } catch (error) {
      toast.error("Oops, incorrect email or one time password");
      console.error(error);
    } finally {
      handleLoading(false);
    }
  };

  const { formState, handleSubmit, control } = useForm<OtpType>({
    resolver: yupResolver(OtpSchema),
  });

  const { errors } = formState;

  useShowToastErrorMessage(errors);

  return (
    <div className="bg-blue-800 w-full h-full flex justify-center">
      <div className="w-full flex">
        <div className="w-full md:w-7/12 flex">
          <div className="flex w-96 items-center justify-center flex-col m-auto">
            <img src={LogoRF} alt="Moneyball" width={100} />
            <h1 className="text-white text-3xl mt-10 mb-5">
              Reset Your Password
            </h1>
            <p className="text-blue-400 text-base">
              Enter your one time password below
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full mt-10 space-y-5">
                <div>
                  <Controller
                    defaultValue=""
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        {...fieldState}
                        type="email"
                        label="Email"
                        placeholder="Your email"
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    defaultValue=""
                    name="one_time_password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        {...fieldState}
                        type="password"
                        label="One time password"
                        placeholder="Your one time password"
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    defaultValue=""
                    name="new_password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        {...fieldState}
                        type="password"
                        label="New password"
                        placeholder="Your new password"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex justify-center mt-5">
                <Button label="Reset" />
              </div>
            </form>
          </div>
        </div>
        <div className="bg-gray-400 w-7/12 hidden sm:block">
          <div className="w-full h-full overflow-hidden">
            <div className="fixed h-full bg-light-cover z-10 w-7/12"></div>
            <video
              autoPlay
              muted
              loop
              className="overflow-hidden w-full"
              style={{
                position: "relative",
                top: "-45%",
              }}
            >
              <source src={HOME_VIDEO} type="video/mp4" />
            </video>
            <h2 className="text-white text-4xl font-medium absolute ml-10 top-10">
              You are one click
              <br />
              way to start your
              <br />
              new campaign
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;

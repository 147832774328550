import axios, { AxiosInstance } from "axios";
import { AdServerType, MediaInfo, RequestEventParts } from "./types";

const client = (httpClient: AxiosInstance) => ({
  list: async (page?: string, params?: string) => {
    const response = await httpClient.get(page || `/events?${params}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  embedDetail: async (id: string) => {
    const response = await httpClient.get(`/mb-embed/${id}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  detail: async (id: number) => {
    const response = await httpClient.get(`/events/${id}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  delete: async (id: number) => {
    const response = await httpClient.delete(`/events/${id}`);

    return response;
  },

  putVideoUrl: async (url: string, data: Blob) => {
    const response = await axios.put(url, data, {
      transformRequest: (data: Blob, headers: any) => {
        delete headers.put["Content-Type"];
        return data;
      },
    });

    return response;
  },
  postVideo: async (data: RequestEventParts) => {
    const response = await httpClient.post("/events", data);

    return response;
  },
  getAdServer: async (data: AdServerType) => {
    const response = await httpClient.get(
      `/ad-server?eventId=${data.eventId}&latitude=${data.latitude}&longitude=${data.longitude}`
    );

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  adSever: async (data: AdServerType) => {
    const response = await httpClient.post("/ad-server", data);

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  create: async (data: MediaInfo) => {
    // data.completion_rate = "0.0";
    // data.total_views = "0";

    const user = localStorage.getItem("user");

    if (user) {
      const { id } = JSON.parse(user);
      // data.user = id;
    }

    // data.is_active = true;

    const response = await httpClient.post("/events/pre-upload", data);

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
});

export default client;

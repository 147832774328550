import React from "react";
import Table from "../../components/Table";
import { performanceTableColumns } from "./performanceTableColumns";
import Nike from "../../assets/nike.png";
import Page from "../../components/Page";

const Performance: React.FC = () => {
  const data = [
    {
      id: 1,
      performanceDetails: `<img src=${Nike} class="mr-5" /> Nike created a New Campaign`,
      totalSpending: "$24,500",
      totalSales: "$35,890",
      conversionRate: "8%",
      clicks: "2450",
      roas: "146%",
    },
  ];

  const onEdit = (id?: number) => {
    alert(`Update: ${id}`);
  };

  const onDelete = (id?: number) => {
    alert(`Delete: ${id}`);
  };

  const columns = performanceTableColumns(onEdit, onDelete);

  return (
    <Page title="Performance">
      <Table data={data} columns={columns} />
    </Page>
  );
};

export default Performance;

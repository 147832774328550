import Button from "../../components/Button";
import { Column } from "../../components/Table/types";
import { TFunction } from "i18next";

export type CampaignType = {
  id?: number | string;
  playback_id?: number | string;
  details?: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  budget?: string;
  status?: string;
  t: TFunction<"translation", undefined, "translation">;
};

export const campaignsTableColumns = (
  onApprove: (id: number) => void,
  onDelete: (id: number) => void,
  onView: (id: string) => void,
  t: (name: string) => string
): Column<CampaignType>[] => {
  return [
    {
      title: String(t("candidate.details")),
      fieldResolver: (campaign) => campaign.details,
    },
    {
      title: String(t("candidate.Budget")),
      fieldResolver: (campaign) => campaign.budget,
    },
    {
      title: String(t("candidate.Status")),
      render: ({ row: campaign }) => (
        <p
          className={`${
            campaign.status === "For Approval"
              ? "text-yellow-100"
              : campaign.status === "approved"
              ? "text-lime-700"
              : "text-red-700"
          }`}
        >
          {campaign.status === "For Approval" && t("candidate.for")}
          {campaign.status === "approved" && t("candidate.approved")}
          {campaign.status === "rejected" && t("candidate.rejected")}
        </p>
      ),
    },
    {
      title: String(t("candidate.approvalStats")),
      render: ({ row: campaign }) =>
        campaign.id ? (
          <div className="flex space-x-2 w-18">
            {campaign.playback_id ? (
              <>
                <Button
                  label={t("candidate.view")}
                  variation="alert"
                  onClick={() => onView(String(campaign.id))}
                />

                <Button
                  label={t("candidate.reject")}
                  disabled={campaign.status === "rejected"}
                  variation="danger"
                  onClick={() => onDelete(Number(campaign.id))}
                />
                <Button
                  disabled={campaign.status === "approved"}
                  label={t("candidate.approve")}
                  onClick={() => onApprove(Number(campaign.id))}
                />
              </>
            ) : (
              <div className="flex justify-end items-end">
                <Button
                  label={t("candidate.process")}
                  disabled
                  variation="alert"
                  onClick={() => onView(String(campaign.playback_id))}
                />
              </div>
            )}
          </div>
        ) : (
          <div role="status" className="max-w-sm animate-pulse">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 mb-4"></div>
          </div>
        ),
    },
  ];
};

import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import AuthService from "./auth";
import CampaignService from "./campaigns";
import UsersService from "./users";
import EventsService from "./events";
interface CommonHeaderProperties extends AxiosRequestHeaders {
  Authorization: string;
}

const API_ENVS = {
  production: "",
  development: "",
  local: "https://api.app.getmoneyball.tv",
};

const httpClient = axios.create({
  baseURL: API_ENVS.local,
});

httpClient.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers = {
        Authorization: `Bearer ${JSON.parse(token)?.access}`,
      } as CommonHeaderProperties;
    }

    return config;
  }
);

httpClient.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  async (error) => {
    const STATUS_CODE = [0, 500, 400, 401];

    if (error?.request?.status) {
      const canThrowAnError = STATUS_CODE.includes(error.request.status);

      if (
        error.request.status === 401 &&
        error.response.data.code === "token_not_valid"
      ) {
        await api.auth.refresh();
      }

      if (canThrowAnError) {
        throw new Error(error.message);
      }
    }

    return error;
  }
);

const api = {
  auth: AuthService(httpClient),
  campaigns: CampaignService(httpClient),
  users: UsersService(httpClient),
  events: EventsService(httpClient),
};

export default api;

import React, { useRef, useState } from "react";
import Container from "./styles";
import { InputSelectProps } from "./types";

const InputSelectCurrency = React.forwardRef<HTMLElement, InputSelectProps>(
  (
    {
      label,
      options,
      defaultValue = 0,
      defaultChecked,
      onChangeSelect,
      onChangeText,
      onClickText,
      editable = false,
      ...rest
    },
    ref
  ) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(true);
    const [dropdownValue, setDropdownValue] = useState<string>();
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleOnChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDropdownValue(event.currentTarget.value);
      onChangeText?.(event);
      setIsDropdownVisible(true);
    };

    const handleClick = (value: string) => {
      setIsDropdownVisible(false);
      setDropdownValue(value);
      onClickText?.(value);
    };

    return (
      <div className="block w-full">
        <span className="font-normal text-white text-base">{label}</span>
        <Container className="flex justify-center flex-col mt-4">
          {editable && (
            <>
              <input
                name="format"
                placeholder="Selecione.."
                type="text"
                value={dropdownValue}
                onChange={handleOnChangeText}
                className="appearance-none
              rounded-2xl
              w-full
              text-base 
              block 
              px-4 
              py-4 
              bg-light-blue
              text-light-blue-secondary 
              border
              border-transparent 
              focus:outline-none 
              focus:border-lime-500 
              focus:ring-1 
              focus:ring-lime-500e
              "
                ref={inputRef}
              />
              {isDropdownVisible && dropdownValue && (
                <ul>
                  {options.map(({ name, id }) => {
                    return (
                      <li
                        onClick={() => handleClick(String(id))}
                        key={id}
                        value={id}
                        className="
                      appearance-none
                      w-full
                      text-base 
                      block 
                      px-4 
                      py-4 
                      bg-light-blue
                      text-light-blue-secondary 
                      border
                      border-transparent 
                      hover:outline-none 
                      hover:border-lime-500 
                      hover:ring-1 
                      hover:ring-lime-500e"
                      >
                        {label}
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          )}
          {!editable && (
            <select
              name="search"
              className="appearance-none
            rounded-2xl
            w-full
            -mt-3
            text-sm
            block 
            px-4 
            py-4 
            bg-light-blue
            text-light-blue-secondary 
            border
            border-transparent 
            focus:outline-none 
            focus:border-lime-500 
            focus:ring-1 
            focus:ring-lime-500e
            "
              defaultValue={defaultValue}
              defaultChecked={defaultChecked}
              onChange={onChangeSelect}
              {...rest}
            >
              {!options.length && <option value={0}>Selecione</option>}
              {options.map(({ name, id }) => {
                return (
                  <option
                    key={name}
                    value={id}
                    className="bg-light-blue focus:bg-light-blue"
                  >
                    {name}
                  </option>
                );
              })}
            </select>
          )}
        </Container>
      </div>
    );
  }
);

export default InputSelectCurrency;

export const Logo = ({ width = "174", height = "86" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 174 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25232 0L16.5995 17.5943L28.1464 0H33.1006V33.017H27.7498V19.4795C27.7498 17.498 27.7908 15.6206 27.8729 13.8422C27.9549 12.0663 28.0945 10.2983 28.2942 8.53548L18.085 23.5337H14.8132L4.85293 8.58235C5.04989 10.3426 5.18393 12.1054 5.24959 13.8656C5.31524 15.6258 5.34807 17.498 5.34807 19.4795V33.017H0V0H5.25232Z"
      fill="white"
    />
    <path
      d="M66.45 20.6134C66.45 23.1288 65.8619 25.3551 64.691 27.2871C63.5175 29.2218 61.9336 30.7373 59.9338 31.8387C57.9341 32.9401 55.6964 33.4896 53.2207 33.4896C50.745 33.4896 48.4964 32.9401 46.4802 31.8387C44.4641 30.7373 42.872 29.2218 41.6984 27.2871C40.5249 25.3525 39.9395 23.1288 39.9395 20.6134C39.9395 18.0981 40.5249 15.8744 41.6984 13.9397C42.872 12.005 44.4641 10.4896 46.4802 9.38816C48.4936 8.28672 50.7423 7.7373 53.2207 7.7373C55.6992 7.7373 57.9369 8.28672 59.9338 9.38816C61.9336 10.4896 63.5175 12.005 64.691 13.9397C65.8619 15.8718 66.45 18.0981 66.45 20.6134ZM61.2469 20.6134C61.2797 18.9469 60.9405 17.4758 60.232 16.2025C59.5208 14.9292 58.5551 13.9371 57.3323 13.2315C56.1095 12.5232 54.739 12.1691 53.2207 12.1691C51.6997 12.1691 50.321 12.5232 49.0818 13.2315C47.8426 13.9397 46.8687 14.9292 46.1574 16.2025C45.4462 17.4758 45.107 18.9469 45.1425 20.6134C45.1097 22.2487 45.4489 23.7042 46.1574 24.9775C46.8687 26.2508 47.8426 27.2507 49.0818 27.972C50.321 28.6958 51.6997 29.0578 53.2207 29.0578C54.739 29.0578 56.1122 28.6958 57.3323 27.972C58.5551 27.2481 59.5208 26.2508 60.232 24.9775C60.9405 23.7016 61.2797 22.2487 61.2469 20.6134Z"
      fill="white"
    />
    <path
      d="M85.6267 7.73413C87.9711 7.73413 89.7903 8.18981 91.0787 9.10116C92.3672 10.0125 93.2672 11.2389 93.7788 12.7804C94.2903 14.3219 94.5475 16.0353 94.5475 17.9205V33.0151H89.4429V18.2512C89.4429 17.056 89.276 15.9884 88.9477 15.0432C88.6167 14.1006 88.0559 13.3611 87.2626 12.8273C86.4693 12.2935 85.3614 12.0722 83.9416 12.1659C82.6203 12.1659 81.4468 12.4341 80.4236 12.9679C79.4005 13.5043 78.5908 14.2099 77.9944 15.0901C77.4008 15.9702 77.1026 16.9466 77.1026 18.0142V33.0125H71.998V8.2497H77.1026V12.1659C77.896 11.002 79.0504 9.97345 80.5714 9.07512C82.0923 8.1846 83.7775 7.73413 85.6267 7.73413Z"
      fill="white"
    />
    <path
      d="M123.832 29.763H123.782C122.724 30.7368 121.271 31.6013 119.422 32.3564C117.573 33.1116 115.639 33.4891 113.625 33.4891C110.917 33.4891 108.529 32.9475 106.463 31.8617C104.398 30.7759 102.789 29.2839 101.632 27.3804C100.475 25.4796 99.8979 23.3158 99.8979 20.8942C99.8979 18.1263 100.508 15.762 101.731 13.7961C102.951 11.8301 104.573 10.3225 106.586 9.26793C108.603 8.21336 110.799 7.68738 113.176 7.68738C115.357 7.68738 117.34 8.19773 119.124 9.22105C120.907 10.2444 122.327 11.6505 123.386 13.4419C124.442 15.2334 124.972 17.3269 124.972 19.7147L124.923 21.7899H105.003C105.298 24.0241 106.266 25.7921 107.902 27.0966C109.538 28.4012 111.612 29.0547 114.123 29.0547C115.939 29.0547 117.408 28.7318 118.533 28.0887C119.654 27.4455 120.582 26.8232 121.307 26.2269L123.832 29.763ZM113.179 12.1686C111.229 12.1686 109.527 12.6009 108.075 13.4654C106.622 14.3298 105.662 15.8167 105.2 17.9232H119.769V17.5925C119.671 16.5249 119.299 15.5797 118.653 14.7621C118.01 13.9445 117.209 13.3091 116.249 12.8508C115.291 12.3978 114.268 12.1686 113.179 12.1686Z"
      fill="white"
    />
    <path
      d="M134.389 43.8643L138.651 34.5711L138.798 34.2404L126.707 8.25122H132.654L140.782 26.4575C140.88 26.6449 140.979 26.8819 141.08 27.1657C141.178 27.4495 141.293 27.762 141.427 28.1083C141.559 27.762 141.682 27.4235 141.799 27.0928C141.914 26.7621 142.04 26.4418 142.171 26.1268L148.961 8.25122H155.007L144.302 33.014L139.893 43.8617H134.389V43.8643Z"
      fill="white"
    />
    <path
      d="M84.6363 52.5115C88.0065 52.5115 90.5506 53.2197 92.2686 54.6336C93.9865 56.0475 94.8455 58.1098 94.8455 60.8126C94.8455 62.2916 94.4734 63.6118 93.7294 64.7757C92.9853 65.9396 91.8883 66.851 90.433 67.5124C91.5245 67.8587 92.5394 68.3846 93.4804 69.0929C94.4215 69.8011 95.1902 70.7047 95.7838 71.8061C96.3774 72.9076 96.6756 74.2434 96.6756 75.8161C96.6756 77.7351 96.3282 79.3235 95.6361 80.5786C94.9412 81.8362 94.033 82.8283 92.9114 83.5496C91.7871 84.2734 90.5479 84.7838 89.1938 85.0832C87.8396 85.3827 86.4855 85.5311 85.1314 85.5311H70.165V52.5141H84.6363V52.5115ZM83.7445 57.4172H75.5186V66.1896H84.3381C85.6922 66.1583 86.8904 65.7652 87.9299 65.01C88.9694 64.2549 89.4919 63.1873 89.4919 61.8021C89.4919 60.2293 88.9393 59.1071 87.8314 58.4301C86.7235 57.7557 85.3612 57.4172 83.7445 57.4172ZM84.587 71.0953H75.5186V80.6228H84.836C86.7181 80.6228 88.2555 80.2218 89.4454 79.4198C90.6354 78.6178 91.229 77.3836 91.229 75.7171C91.229 74.522 90.8816 73.595 90.1895 72.9336C89.4947 72.2722 88.6439 71.8009 87.6372 71.5197C86.625 71.2385 85.6101 71.0953 84.587 71.0953Z"
      fill="white"
    />
    <path
      d="M126.806 60.7654V85.5281H121.652V81.7083C120.858 82.7446 119.685 83.7211 118.134 84.6324C116.58 85.5438 114.78 85.9994 112.734 85.9994C110.553 85.9994 108.562 85.45 106.762 84.3486C104.962 83.2472 103.531 81.7239 102.475 79.7736C101.417 77.8233 100.889 75.5918 100.889 73.0765C100.889 70.5611 101.433 68.3348 102.525 66.4027C103.616 64.4681 105.118 62.9604 107.033 61.8746C108.948 60.7888 111.128 60.2472 113.574 60.2472C115.39 60.2472 116.985 60.6248 118.355 61.3799C119.726 62.135 120.826 63.0151 121.652 64.0202V60.7654H126.806ZM113.924 81.5182C115.51 81.5182 116.897 81.1484 118.087 80.4089C119.277 79.6694 120.202 78.6644 120.861 77.3911C121.523 76.1178 121.851 74.6778 121.851 73.0765C121.851 71.5037 121.52 70.0898 120.861 68.8321C120.199 67.5745 119.275 66.5772 118.087 65.8377C116.897 65.0982 115.51 64.7285 113.924 64.7285C112.403 64.7285 111.049 65.0904 109.861 65.8143C108.671 66.5381 107.747 67.5276 107.088 68.7853C106.425 70.0429 106.097 71.4751 106.097 73.0765C106.097 74.6804 106.428 76.1178 107.088 77.3911C107.747 78.6644 108.674 79.672 109.861 80.4089C111.049 81.151 112.403 81.5182 113.924 81.5182Z"
      fill="white"
    />
    <path
      d="M134.041 85.5285V50.6262H139.146V85.5311H134.041V85.5285Z"
      fill="white"
    />
    <path
      d="M146.479 85.5285V50.6262H151.584V85.5311H146.479V85.5285Z"
      fill="white"
    />
    <path
      d="M167.06 85.8441C170.708 85.8441 173.666 83.0287 173.666 79.5558C173.666 76.0828 170.708 73.2675 167.06 73.2675C163.411 73.2675 160.453 76.0828 160.453 79.5558C160.453 83.0287 163.411 85.8441 167.06 85.8441Z"
      fill="white"
    />
  </svg>
);

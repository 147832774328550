export function convertToBRDate(date: string) {
  const data = new Date(date);
  
  const meses = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];
  
  const mes = meses[data.getMonth()];

  const dia = data.getDate();
  const ano = data.getFullYear();

  return dia + " de " + mes + " de " + ano;
}

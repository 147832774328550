import React from "react";
import { SearchInputProps } from "./types";
import SearchIcon from "../../assets/search.png";

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  errorMessage,
  disabled,
  defaultValue,
  onChange,
}) => {
  return (
    <label className="block">
      <div className="flex items-center bg-blue-300 rounded-2xl border border-blue-1000 pr-5">
        <input
          className="mt-1 text-base block w-full px-4 py-4 rounded-2xl bg-blue-300 text-light-blue-secondary focus:outline-none"
          placeholder={placeholder}
          disabled={disabled}
          type="text"
          defaultValue={defaultValue}
          onChange={onChange}
        />
        <img src={SearchIcon} alt="Search Icon" />
      </div>
      <span className="block font-normal text-brand-danger">
        {errorMessage}
      </span>
    </label>
  );
};

export default SearchInput;

import * as yup from "yup";
import { requiredMessage } from "../../../helpers";

export const OtpSchema = yup.object().shape({
  email: yup.string().required(requiredMessage("Email")),
  new_password: yup.string().required(requiredMessage("Password")),
  one_time_password: yup.string().required(requiredMessage("Password")),
});

export type OtpType = {
  email: string;
  new_password: string;
  one_time_password: string;
};

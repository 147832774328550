export const languageOptions = [
  {
    value: 1,
    label: "Mandarin Chinese",
  },
  {
    value: 2,
    label: "Spanish",
  },
  {
    value: 3,
    label: "English",
  },
  {
    value: 4,
    label: "Hindi",
  },
  {
    value: 5,
    label: "Bengali",
  },
  {
    value: 6,
    label: "Português",
  },
  {
    value: 7,
    label: "Russian",
  },
  {
    value: 8,
    label: "Japanese",
  },
  {
    value: 9,
    label: "Western Punjabi",
  },
  {
    value: 10,
    label: "Marathi",
  },
  {
    value: 11,
    label: "Telugu",
  },
  {
    value: 12,
    label: "Wu Chinese",
  },
  {
    value: 13,
    label: "Turkish",
  },
  {
    value: 14,
    label: "Korean",
  },
  {
    value: 15,
    label: "French",
  },
  {
    value: 16,
    label: "German",
  },
  {
    value: 17,
    label: "Vietnamese",
  },
  {
    value: 18,
    label: "Tamil",
  },
  {
    value: 19,
    label: "Yue Chinese",
  },
  {
    value: 20,
    label: "Urdu",
  },
  {
    value: 21,
    label: "Javanese",
  },
  {
    value: 22,
    label: "Italian",
  },
  {
    value: 23,
    label: "Egyptian Arabic",
  },
  {
    value: 24,
    label: "Gujarati",
  },
  {
    value: 25,
    label: "Iranian Persian",
  },
  {
    value: 26,
    label: "Bhojpuri",
  },
  {
    value: 27,
    label: "Southern Min",
  },
  {
    value: 28,
    label: "Hakka",
  },
  {
    value: 29,
    label: "Jin Chinese",
  },
  {
    value: 30,
    label: "Hausa",
  },
  {
    value: 31,
    label: "Kannada",
  },
  {
    value: 32,
    label: "Indonesian",
  },
  {
    value: 33,
    label: "Polish",
  },
  {
    value: 34,
    label: "Yoruba",
  },
  {
    value: 35,
    label: "Xiang Chinese",
  },
  {
    value: 36,
    label: "Malayalam",
  },
  {
    value: 37,
    label: "Odia",
  },
  {
    value: 38,
    label: "Maithili",
  },
  {
    value: 39,
    label: "Burmese",
  },
  {
    value: 40,
    label: "Eastern Punjabi",
  },
  {
    value: 41,
    label: "Sunda",
  },
  {
    value: 42,
    label: "Sudanese Arabic",
  },
  {
    value: 43,
    label: "Algerian Arabic",
  },
  {
    value: 44,
    label: "Moroccan Arabic",
  },
  {
    value: 45,
    label: "Ukrainian",
  },
  {
    value: 46,
    label: "Igbo",
  },
  {
    value: 47,
    label: "Northern Uzbek",
  },
  {
    value: 48,
    label: "Sindhi",
  },
  {
    value: 49,
    label: "North Levantine Arabic",
  },
  {
    value: 50,
    label: "Romanian",
  },
  {
    value: 51,
    label: "Tagalog",
  },
  {
    value: 52,
    label: "Dutch",
  },
  {
    value: 53,
    label: "Saidi Arabic",
  },
  {
    value: 54,
    label: "Gan Chinese",
  },
  {
    value: 55,
    label: "Amharic",
  },
  {
    value: 56,
    label: "Northern Pashto",
  },
  {
    value: 57,
    label: "Magahi",
  },
  {
    value: 58,
    label: "Thai",
  },
  {
    value: 59,
    label: "Saraiki",
  },
  {
    value: 60,
    label: "Khmer",
  },
  {
    value: 61,
    label: "Chhattisgarhi",
  },
  {
    value: 62,
    label: "Somali",
  },
  {
    value: 63,
    label: "Malaysian",
  },
  {
    value: 64,
    label: "Cebuano",
  },
  {
    value: 65,
    label: "Nepali",
  },
  {
    value: 66,
    label: "Mesopotamian Arabic",
  },
  {
    value: 67,
    label: "Assamese",
  },
  {
    value: 68,
    label: "Sinhalese",
  },
  {
    value: 69,
    label: "Northern Kurdish",
  },
  {
    value: 70,
    label: "Hejazi Arabic",
  },
  {
    value: 71,
    label: "Nigerian Fulfulde",
  },
  {
    value: 72,
    label: "Bavarian",
  },
  {
    value: 73,
    label: "South Azerbaijani",
  },
  {
    value: 74,
    label: "Greek",
  },
  {
    value: 75,
    label: "Chittagonian",
  },
  {
    value: 76,
    label: "Kazakh",
  },
  {
    value: 77,
    label: "Deccan",
  },
  {
    value: 78,
    label: "Hungarian",
  },
  {
    value: 79,
    label: "Kinyarwanda",
  },
  {
    value: 80,
    label: "Zulu",
  },
  {
    value: 81,
    label: "South Levantine Arabic",
  },
  {
    value: 82,
    label: "Tunisian Arabic",
  },
  {
    value: 83,
    label: "Sanaani Spoken Arabic",
  },
  {
    value: 84,
    label: "Northern Min",
  },
  {
    value: 85,
    label: "Southern Pashto",
  },
  {
    value: 86,
    label: "Rundi",
  },
  {
    value: 87,
    label: "Czech",
  },
  {
    value: 88,
    label: "Taizzi-Adeni Arabic",
  },
  {
    value: 89,
    label: "Uyghur",
  },
  {
    value: 90,
    label: "Eastern Min",
  },
  {
    value: 91,
    label: "Sylheti",
  },
];

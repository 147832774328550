import { AxiosInstance } from "axios";
import { setFormData } from "../../../helpers";
import { CampaignType } from "../../../settings/yup/schemas/CampaignSchema";

const client = (httpClient: AxiosInstance) => ({
  list: async (page?: string, params?: string) => {
    const response = await httpClient.get(page || `/campaigns?${params}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },

  getCurrencies: async () => {
    const response = await httpClient.get(`/currencies`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },

  getCurrencyById: async (id: number) => {
    const response = await httpClient.get(`/currencies/${id}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },

  get: async (id: string) => {
    const response = await httpClient.get(`/campaigns/${id}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  put: async (payment_id: number) => {
    const response = await httpClient.put(`/campaign-checkout/${payment_id}`);

    const { checkout } = response.data;

    window.location.href = checkout;
  },
  approveCandidates: async (id: number) => {
    const response = await httpClient.patch(
      "/campaign-event-candidates/" + id,
      {
        evaluation: "approved",
      }
    );

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  rejectCandidates: async (id: number) => {
    const response = await httpClient.patch(
      "/campaign-event-candidates/" + id,
      {
        evaluation: "rejected",
      }
    );

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  listCandidates: async (page?: string, params?: string) => {
    const response = await httpClient.get(
      page || `/campaign-event-candidates?${params}`
    );

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  uploadVideo: async (media: File) => {
    const response = await httpClient.post(
      "/campaign-ad-candidate/temp/banner",
      setFormData({
        media: media,
      })
    );

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  listInterests: async () => {
    const response = await httpClient.get("/interests");

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  create: async (data: CampaignType) => {
    data.campaign_budget_description = `${data.campaign_name}`;
    data.campaign_distribution =
      localStorage.getItem("distribution_type") || "event_selec";
    data.campaign_parent_status = data.campaign_parent_status || ["unknown"];    
    data.campaign_start_date = data.campaign_start_date || "2023-01-01";
    data.campaign_end_date = data.campaign_end_date || "2025-01-01";
    data.advertisement_total_spending = Number(data.campaign_daily_budget);
    data.advertisement_total_sales = 0;
    data.campaign_location_city = data.campaign_location_city || "London";
    data.campaign_location_country = data.campaign_location_country || "UK";
    data.campaign_location_province =
      data.campaign_location_province || "London";

    const response = await httpClient.post(
      "/campaign-ad-candidate",
      setFormData(data),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
});

export default client;

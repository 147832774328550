import React from "react";
import Logo from "../../assets/logo.png";
import LogoRF from "../../assets/logo_rf.png";
//import SignInVideo from "../../assets/videos/signin.mp4";
import Button from "../../components/Button";
import { HOME_VIDEO } from "../../constants/urls";

const StartCampaign: React.FC = () => {
  return (
    <div className="bg-blue-800 w-full h-full flex justify-center">
      <div className="w-full flex">
        <div className="w-8/12 flex">
          <div className="flex items-center justify-center flex-col m-auto">
            <div className="flex items-center absolute top-0 h-4/6">
              <h1 className="text-white text-3xl mt-10 mb-5 h-full flex items-center">
                The Connected TV
                <br /> Advertising Platform
              </h1>
              <div className="bg-white w-1 h-full ml-10 mr-10"></div>
              <h2 className="text-white text-3xl mt-10 mb-5 h-full flex items-end">
                You’ll create your Video
                <br /> Ad with Moneyball.
              </h2>
            </div>

            <div className="absolute h-4/6 flex items-end w-72">
              <Button label="Start Campaign" />
            </div>
          </div>
        </div>
        <div className="bg-gray-400 w-5/12">
          <div className="w-full h-full overflow-hidden">
            <div className="fixed h-full bg-light-cover z-10 w-7/12"></div>
            <video
              autoPlay
              muted
              loop
              className="overflow-hidden w-full"
              style={{
                position: "relative",
                top: "-45%",
              }}
            >
              <source src={HOME_VIDEO} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartCampaign;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({
  label,
  url = "#",
  onClick,
  variation = "normal",
  children,
  disabled,
  isFilled = false,
}) => {
  const [buttonColor, setButtonColor] = useState("border-white");

  useEffect(() => {
    if (variation === "light") {
      setButtonColor("!border-white");
    }

    if (variation === "alert") {
      setButtonColor("!border-yellow-500");
    }

    if (variation === "danger") {
      setButtonColor("!border-red-500");
    }

    if (variation === "dark") {
      setButtonColor("!blue-800");
    }
  }, [buttonColor, setButtonColor, variation]);

  const colorVariant = {
    light: isFilled ? "bg-white !text-black" : "!border-white",
    normal: isFilled ? "bg-lime-500 !border-none" : "!border-teal-500",
    alert: "!border-yellow-500",
    danger: "!border-red-500",
    dark: isFilled ? "bg-blue-800 !border-none" : "!border-blue-800",
  };

  return disabled ? (
    <div className="rounded-2xl w-full">
      <Link
        to={url}
        className={
          "opacity-60 w-full cursor-not-allowed rounded-2xl flex items-center justify-center py-3 border-transparent font-normal text-sm px-1 text-white bg-gradient-to-r from-blue-800 to-blue-800"
        }
      >
        {label}
      </Link>
      {children && children}
    </div>
  ) : (
    <div className="rounded-2xl w-full" onClick={onClick}>
      <Link
        to={url}
        className={`w-full cursor-pointer rounded-2xl flex items-center justify-center py-3 border-2 h-[44px] font-normal text-sm md:px-4 text-white ${colorVariant[variation]}`}
      >
        {label}
      </Link>
      {children && children}
    </div>
  );
};

export default Button;

import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./store/context/AuthContext";
import { useEffect } from "react";
import i18n from "./i18";

function App() {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  const language = params.get("language");

  useEffect(() => {
    i18n.changeLanguage(language || "pt-br");
  }, [language]);

  return (
    <HelmetProvider>
      <AuthProvider>
        <>
          <Router />
        </>
      </AuthProvider>
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;

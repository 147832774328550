import React, { useState } from "react";
import Button from "../Button";
import Header from "../Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DragDropFile from "../DragDropFile";
import Input from "../Form/Input";
import {
  Chunk,
  EventType,
  RequestEventParts,
} from "../../services/api/events/types";
import { EventSchema } from "../../settings/yup/schemas/EventSchema";
import { toast } from "react-toastify";
import services from "../../services/api";
import { useNavigate } from "react-router-dom";
import Progress from "../Progress";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const videos = [
  {
    video: "https://getmoneyball.s3.amazonaws.com/NBA.mp4",
    text: "Amazing... Looks like a Touchdown 🎉",
  },
  {
    video: "https://getmoneyball.s3.amazonaws.com/golloo2.mp4",
    text: "Amazing... Looks like a Goal 🎉",
  },
  {
    video: "https://getmoneyball.s3.amazonaws.com/golloo3.mp4",
    text: "Amazing... Looks like a Goal 🎉",
  },
];

const CreateEvent: React.FC = () => {
  const { setValue, watch, reset } = useForm<EventType>({
    resolver: yupResolver(EventSchema),
  });

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const [thumbnail, setThumbnail] = useState<File | undefined>();

  const [isVideoCreated, setIsVideoCreated] = useState<boolean>(false);

  const formValues = watch();

  const navigate = useNavigate();

  const randomVideo = videos[Math.floor(Math.random() * videos.length)];

  const [progress, setProgress] = useState(0);

  const runPromises = (promises: Promise<any>[]) => {
    let completedPromises = 0;
    const totalPromises = promises.length;

    return Promise.all(
      promises.map((p: Promise<any>) =>
        p.then(() => {
          completedPromises++;
          const totalProgress = Math.floor(
            (completedPromises / totalPromises) * 60
          );

          setProgress(40 + totalProgress);
          return p;
        })
      )
    );
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!formValues.title) {
      setLoading(false);
      return toast.error(t("events.give"));
    }

    if (!formValues.media) {
      setLoading(false);
      return toast.error(t("events.upload"));
    }

    const mediaInfo = {
      file_name: formValues.media.name,
      file_size: formValues.media.size,
      content_type: formValues.media.type,
    };

    const PART_SIZE = 1024 * 1024 * 25;

    try {
      const parts: Blob[] = [];
      let start = 0;
      let end = PART_SIZE - 1;
      while (start < mediaInfo.file_size) {
        const filePart = formValues.media.slice(start, end + 1);
        parts.push(filePart);
        start = end + 1;
        end += PART_SIZE;
      }

      await services.events
        .create(mediaInfo)
        .then(async (res) => {
          let parts_response: Chunk[] = [];

          const upload_id = res.data.upload_id;
          let promises: Promise<any>[] = [];

          parts.map(async (part, index) => {
            const signedUrl = res.data.signed_urls[index];
            const promise = services.events.putVideoUrl(signedUrl, part);
            promises.push(promise);
          });

          runPromises(promises).then(async (res) => {
            setLoading(true);

            res.map((res, index) => {
              const etag = res.headers.etag;
              const etagString = JSON.parse(etag);
              parts_response.push({
                PartNumber: index + 1,
                ETag: etagString,
              });
            });
            const payload: RequestEventParts = {
              parts: parts_response,
              file_name: mediaInfo.file_name,
              title: formValues.title,
              upload_id: upload_id,
            };

            await services.events.postVideo(payload).then((res) => {
              setProgress(0);
              setLoading(false);
              setIsVideoCreated(true);
            });
          });
        })
        .catch((error) => {
          toast.error("upload aws error" + error);
          setLoading(false);
        });
    } catch (err) {
      toast.error("oops" + err);
      setLoading(false);
    }
  };

  return (
    <div className="bg-blue-900 w-full h-full flex flex-col">
      <div className="w-full">
        <Header />
      </div>
      <div className="w-full flex mt-10 justify-center">
        <div className="md:w-8/12 w-full mx-4 md:mx-0">
          <div className="flex w-full flex-col mb-5">
            <h1 className="text-2xl md:text-4xl text-white">
              {t("events.new")}
            </h1>
          </div>

          {loading && <Progress progress={progress} />}

          {loading && (
            <div className="flex pb-28 flex-col items-center justify-center m-auto">
              <Loading />
              <p className="text-white text-2xl mt-4">{t("events.we")}</p>
            </div>
          )}

          {!isVideoCreated && !loading && (
            <div className="bg-blue-800 p-6 md:p-20 rounded-2xl flex justify-center">
              <div className="w-full">
                <Input
                  label={String(t("events.name"))}
                  type="text"
                  placeholder={String(t("events.give"))}
                  onChange={(event) => {
                    setValue("title", event.target.value);
                  }}
                />

                <div className="flex mt-5 flex-col w-64">
                  <div className="w-full flex-col">
                    {thumbnail && (
                      <p className="text-white mt-2">{thumbnail?.name}</p>
                    )}
                  </div>
                </div>

                <div className="flex justify-center flex-col mt-5">
                  <DragDropFile
                    onChange={(files) => setValue("media", files[0]?.file)}
                  />
                </div>
              </div>
            </div>
          )}

          <div className={isVideoCreated ? "block" : "hidden"}>
            <h1 className="text-white text-center text-3xl mb-2">
              {t("events.amazing")} 🎉
            </h1>
            <video autoPlay muted loop className="overflow-hidden w-full">
              <source src={randomVideo.video} type="video/mp4" />
            </video>
          </div>

          <div className="flex justify-center md:justify-end w-full mt-10 mb-10">
            {!isVideoCreated && (
              <div className="w-52 flex space-x-5 md:mr-10">
                <Button
                  disabled={loading}
                  label={t("events.create")}
                  onClick={handleSubmit}
                  isFilled
                />
              </div>
            )}
            {isVideoCreated && (
              <div className="w-52 flex space-x-5 mr-10">
                <Button
                  label={t("events.view")}
                  variation="dark"
                  isFilled
                  onClick={() => {
                    navigate("/videos");
                  }}
                />
              </div>
            )}
            {isVideoCreated && (
              <div className="w-52 flex space-x-5 mr-10">
                <Button
                  label={t("events.new")}
                  isFilled
                  onClick={() => {
                    setIsVideoCreated(false);
                    reset();
                    setThumbnail(undefined);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;

import Button from "../../components/Button";
import { Column } from "../../components/Table/types";

export type Campaign = {
  id?: string;
  name?: string;
  description?: string;
  date?: string;
  budget?: string;
};

export const analyticsTableColumns = (
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  onUpdate: (id: number) => void
): Column<Campaign>[] => {
  return [
    {
      title: "Campaign details",
      fieldResolver: ({ description }) => description,
    },
    {
      title: "Customer Name",
      fieldResolver: ({ name }) => name,
    },
    {
      title: "Date",
      fieldResolver: ({ date }) => date,
    },
    {
      title: "Budget",
      fieldResolver: ({ budget }) => budget,
    },
    {
      render: ({ row: { id } }) => (
        <div className="flex space-x-3">
          <Button
            label="Edit"
            onClick={() => onEdit(Number(id))}
            variation="normal"
          />
          <Button
            label="Delete"
            onClick={() => onDelete(Number(id))}
            variation="danger"
          />
          <Button
            label="Update"
            onClick={() => onUpdate(Number(id))}
            variation="alert"
          />
        </div>
      ),
    },
  ];
};

import React, { useState } from "react";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import { TableDropdownProps } from "./types";

const TableDropdown: React.FC<TableDropdownProps> = ({ options, id }) => {
  const [status, setStatus] = useState<boolean>();

  return (
    <>
      <button
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        type="button"
        onClick={() => setStatus(!status)}
      >
        <Icon name={IconEnum.ellipsis} />
      </button>
      {status && (
        <div
          id="dropdown"
          className="z-10 bg-blue-900 divide-gray-100 rounded shadow w-44  absolute"
        >
          <ul
            className="py-1 text-sm text-white"
            aria-labelledby="dropdownDefault"
          >
            {options.map(
              (item, optionIndex) =>
                !item.isDisabled && (
                  <li
                    key={optionIndex}
                    className="block px-4 py-2"
                    onClick={(e) => {
                      e.stopPropagation();

                      item?.action(id);
                    }}
                  >
                    {item.label}
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default TableDropdown;

import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";

type FeedbackProps = {
  hasError?: boolean;
};

const Feedback: React.FC<FeedbackProps> = ({ hasError }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center">
      <div className="bg-blue-300 p-20 w-8/12 rounded-2xl">
        <div className="flex justify-center flex-col items-center">
          <h2 className="text-3xl text-white">
            {hasError ? "Ooops!!" : "Success 🎉"}
          </h2>
          <p className="text-white mt-5 mb-5">
            {hasError
              ? "We can't create your campaign right now, please contact a system administrator."
              : "Campaign created with success"}
          </p>
          <div className="w-52">
            {hasError ? (
              <Button
                label="Try Again"
                variation="dark"
                onClick={() => navigate("/campaign/new")}
              />
            ) : (
              <Button
                label="Continue"
                variation="dark"
                onClick={() => navigate("/campaigns")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

import * as yup from "yup";
import { requiredMessage } from "../../../helpers";

export const ProfileSchema = yup.object().shape({
  first_name: yup.string().required(requiredMessage("First name")),
  last_name: yup.string().required(requiredMessage("Last name")),
});

export type ProfileType = {
  first_name: string;
  last_name: string;
  picture: File;
  email: string;
};

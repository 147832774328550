import React from "react";
import Table from "../../components/Table";
import { integrationTableColumns } from "./integrationTableColumns";
import Salesforce from "../../assets/salesforce.png";
import Microsoft from "../../assets/microsoft.png";
import Sap from "../../assets/sap.png";
import Page from "../../components/Page";

const Integration: React.FC = () => {
  const data = [
    {
      id: 1,
      apps: `<img src=${Salesforce} />`,
      applicationName:
        "<div class='flex flex-col'><strong>Salesforce</strong><p>Salesforce is a customer relationship management solution that brings companies and customers together.</p></div>",
    },
    {
      id: 2,
      apps: `<img src=${Microsoft} />`,
      applicationName:
        "<div class='flex flex-col'><strong>Microsoft Dynamics</strong><p>Microsoft Dynamics is a line of enterprise resource planning and customer relationship management software applications.</p></div>",
    },
    {
      id: 2,
      apps: `<img src=${Sap} />`,
      applicationName:
        "<div class='flex flex-col'><strong>SAP</strong><p>The SAP CRM applications have been initially an integrated on-premises customer relationship management software manufactured by SAP SE.</p></div>",
    },
  ];

  const onEdit = (id?: number) => {
    alert(`Update: ${id}`);
  };

  const onDelete = (id?: number) => {
    alert(`Delete: ${id}`);
  };

  const columns = integrationTableColumns(onEdit, onDelete);

  return (
    <Page title="Integrations">
      <Table data={data} columns={columns} />
    </Page>
  );
};

export default Integration;

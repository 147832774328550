import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets";
import { useAuthContext } from "../../store/context/AuthContext";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import Aside from "./styles";
import { SidebarProps } from "./types";
import { t } from "i18next";
import LogoRF from "../../assets/logo_rf.png";

const Sidebar: React.FC<SidebarProps> = ({
  navigationItems,
  isOpen,
  setIsOpen,
}) => {
  const { handleLogout } = useAuthContext();

  const sideNavRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: { target: any }) {
    if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
      setIsOpen?.(false);
    }
  }

  return (
    <Aside
      ref={sideNavRef}
      className={`bg-blue-700 ease-in-out duration-300 h-full lg:block ${
        isOpen
          ? "translate-x-0 lg:hidden"
          : "-translate-x-full lg:translate-x-0"
      } lg:block z-50 w-52 fixed pt-10 border-blue-500 border-r-2 top-0`}
    >
      <div className="w-full flex justify-center pb-20 border-b-2 border-blue-500">
        <Link to="/">
          <img src={LogoRF} width="76" height="36" />
        </Link>
      </div>
      <ul className="pt-5 border-b-2 border-blue-500">
        {navigationItems.map(({ name, link, icon }) => {
          return (
            <li key={name}>
              <Link
                to={link}
                className="flex font-medium text-base text-blue-600 ml-3 mr-4 mb-3 hover:bg-blue-500 hover:text-lime-700 p-2 rounded"
              >
                <Icon name={icon} />
                <div className="pl-3">{name}</div>
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className="pt-5">
        <p className="ml-3 text-blue-600 text-sm mb-3">
          {t("sidebar.settings")}
        </p>
        <li>
          <Link
            to="/account/profile"
            className="flex font-medium text-blue-600 ml-3 mr-4 hover:bg-blue-500 hover:text-lime-700 p-2 rounded"
          >
            <Icon name={IconEnum.settings} />
            <div className="pl-3"> {t("sidebar.settings")}</div>
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={handleLogout}
            className="flex font-medium text-blue-600 ml-3 mr-4 hover:bg-blue-500 hover:text-lime-700 p-2 rounded"
          >
            <Icon name={IconEnum.logout} />
            <div className="pl-3"> {t("sidebar.logout")}</div>
          </Link>
        </li>
        <li hidden>
          <Link
            to="/"
            className="flex font-medium text-blue-600 ml-3 mr-4 mb-3 hover:bg-blue-500 hover:text-lime-700 p-2 rounded"
          >
            <Icon name={IconEnum.integration} />
            <div className="pl-3">Integrations</div>
          </Link>
        </li>
      </ul>
    </Aside>
  );
};

export default Sidebar;

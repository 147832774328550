import React from "react";
import Input from "./styles";
import { SwitchProps } from "./types";

const Switch: React.FC<SwitchProps> = ({ label, onChange, defaultChecked }) => {
  return (
    <Input className="flex items-center justify-center w-full h-full">
      <label htmlFor="toggle" className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id="toggle"
            className="sr-only"
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
          <div className="block bg-gray-300 w-14 h-8 rounded-full"></div>
          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
        </div>

        {label && <div className="ml-1 text-white font-medium">{label}</div>}
      </label>
    </Input>
  );
};

export default Switch;

import React from "react";
import { BarChart, Bar } from "recharts";
import { TinyBarProps } from "./types";

const TinyBarChart: React.FC<TinyBarProps> = ({
  label,
  width,
  height,
  data,
  status,
  amount,
  percentage,
}) => {
  return (
    <div className="bg-blue-700 p-3 rounded-lg px-px-10 block float-left pr-5 pl-5">
      <div
        style={{
          width,
        }}
      >
        <div className="flex justify-between items-end pl-5 pr-5">
          <p className="text-gray-400 font-bold text-lg">{label}</p>
          <p
            className={`text-sm font-bold ${
              status === "positive" ? "text-green-400" : "text-red-500"
            }`}
          >
            {status === "positive" ? "+" : "-"}
            {percentage}
          </p>
        </div>
        <div className="flex items-center pl-5 pr-5">
          <p className="text-white text-2xl">{amount}</p>
          <p className="text-gray-500 text-sm ml-2 mt-5 mb-3">{label}</p>
        </div>
        <BarChart width={width} height={height} data={data}>
          <Bar dataKey="key" barSize={20} fill="#09B55F" />
        </BarChart>
      </div>
    </div>
  );
};

export default TinyBarChart;

import Switch from "../../components/Form/Switch";
import Icon from "../../components/Icon";
import { IconEnum } from "../../components/Icon/types";
import { Column } from "../../components/Table/types";
import TableDropdown from "../../components/TableDropdown";

export type Integration = {
  id?: number;
  apps?: string;
  applicationName?: string;
  status?: string;
};

export const integrationTableColumns = (
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
): Column<Integration>[] => {
  const options = [
    {
      label: "Update",
      icon: <Icon name={IconEnum.analytics} />,
      isDisabled: !onEdit,
      action: (id: number) => onEdit?.(id),
    },
    {
      label: "Delete",
      icon: <Icon name={IconEnum.document} />,
      isDisabled: !onDelete,
      action: (id: number) => onDelete?.(id),
    },
  ];

  return [
    {
      title: "Connected Apps",
      fieldResolver: (integration) => integration.apps,
    },
    {
      title: "Application Name ",
      fieldResolver: (integration) => integration.applicationName,
    },
    {
      title: "Status",
      render: ({ row: integration }) => <Switch />,
    },
    {
      render: ({ row: integration }) => (
        <TableDropdown options={options} id={Number(integration.id)} />
      ),
    },
  ];
};

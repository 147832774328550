import React from "react";
import Checkbox from "../Form/Checkbox";
import { EventCardProps } from "./types";

const EventCard: React.FC<EventCardProps> = ({
  id,
  label,
  name,
  thumbnail,
  onChange,
  isChecked,
}) => {
  return (
    <div className="flex justify-between bg-blue-300 rounded-2xl pl-4 md:pl-10">
      <Checkbox
        label={label}
        name={name}
        value={String(id)}
        isChecked={isChecked}
        onChange={onChange}
      />
      <img src={thumbnail} alt={name} width="200" />
    </div>
  );
};

export default EventCard;

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import services from "../../services/api";
import Loader from "../../components/Loader";
import { AdvertisementType } from "../../services/api/events/types";
//import { mockAdResponse } from "../../mocks/adserver";
import Thumb from "../../assets/thumb.png";
import ReactPlayer from "react-player";
import { PlayIcon } from "@heroicons/react/20/solid";

type EventType = {
  id?: string;
  url?: string;
  uuid?: string;
  title?: string;
  thumbnail?: string;
  media?: string;
  mux_playback_id?: string;
};

const Embed: React.FC = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get("id");

  const autoplay = queryParams.get("autoplay") === "true" ? true : false;

  const nofullscreen =
    queryParams.get("nofullscreen") === "true" ? "nofullscreen" : "";

  const isLoading = queryParams.get("loading") === "true" ? true : false;
  const height = queryParams.get("height");

  const [event, setEvent] = useState<EventType>({} as EventType);
  const [currentTime, setCurrentTime] = useState(0);
  //const [advertisements, setAdvertisements] =
  //  useState<AdvertisementType[]>(mockAdResponse);

  const [advertisements, setAdvertisements] = useState<AdvertisementType[]>([]);
  const [loading, setLoading] = useState(isLoading || false);
  const muxPlayerRef = useRef<any>(null);
  const [muxPlayerStarted, setMuxPlayerStarted] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [showThumbnail, setShowThumbnail] = useState<boolean>(false);

  const loadEvent = async (id: string) => {
    !isLoading ? setLoading(false) : setLoading(true);

    if (!id) {
      return;
    }

    const response = services.events.embedDetail(id);
    response
      .then((response) => {
        setEvent(response.data);

        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const getLocation = async () => {
      try {
        // Request permission to access user's location
        const permissionResult = await navigator.permissions.query({
          name: "geolocation",
        });
        if (permissionResult.state === "granted") {
          // Get user's current position
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude.toString());
              setLongitude(position.coords.longitude.toString());
            },
            (error) => {
              console.error("Error getting location:", error);
            }
          );
        } else {
          console.error("Geolocation permission denied");
        }
      } catch (error) {
        console.error("Error requesting geolocation permission:", error);
      }
    };

    //getLocation();
  }, []);

  const AdServer = async (uuid: string) => {
    try {
      const response = await services.events.adSever({
        eventId: uuid,
        latitude,
        longitude,
      });

      if (!response.errors?.status) {
        setAdvertisements(response.data);
      }

      //setAdvertisements(mockAdResponse);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentTime = muxPlayerRef.current?.currentTime;
      setCurrentTime(currentTime || 0);
    };

    muxPlayerRef.current?.addEventListener("timeupdate", handleTimeUpdate);
    muxPlayerRef.current?.addEventListener("playing", handleMuxPlayerPlaying);

    return () => {
      muxPlayerRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
      muxPlayerRef.current?.removeEventListener(
        "playing",
        handleMuxPlayerPlaying
      );
    };
  }, []);

  const handleMuxPlayerPlaying = () => {
    setMuxPlayerStarted(true);
  };

  const handleOnPlay = () => {
    setShowThumbnail(false);
  };

  const shouldShowAdvertisement = (advertisement: AdvertisementType) => {
    if (!muxPlayerStarted) {
      return false;
    }

    const buffer = 500; // Buffer time in milliseconds
    const startTime = timeStringToSeconds(advertisement.start_time);
    const endTime = startTime + timeStringToSeconds(advertisement.duration);
    const bufferStart = startTime - buffer / 1000;
    const bufferEnd = endTime + buffer / 1000;
    return currentTime >= bufferStart && currentTime <= bufferEnd;
  };

  const timeStringToSeconds = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const getAdvertisementPositionStyles = (
    position: AdvertisementType["position"]
  ) => {
    switch (position) {
      case "left":
        return {
          left: 0,
          top: 0,
          zIndex: 1,
          height: "100vh",
          maxWidth: "20vw",
        };
      case "right":
        return {
          right: 0,
          top: 0,
          zIndex: 1,
          height: "100vh",
          maxWidth: "20vw",
        };
      case "bottom":
        return { bottom: 0, zIndex: 1, maxHeight: "20vh" };
      default:
        return {};
    }
  };

  const playVideo = () => {
    setInterval(() => {
      const video = document.querySelector("video")?.played.length;

      if (video === 0) {
        console.log("video", video);
        document.querySelector("video")?.play();
        setMuxPlayerStarted(true);
      }
    }, 1000);
  };

  useEffect(() => {
    id && loadEvent(String(id));
    event.uuid && AdServer(event.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.uuid, id]);

  useEffect(() => {
    console.log("is auto", autoplay);
    if (autoplay) {
      setInterval(() => {
        const play = document?.querySelector(".react-player__shadow") as any;

        if (play) {
          play?.click();
        }
      }, 1000);
    } else {
      setTimeout(() => {
        const play = document?.querySelector(".react-player__shadow") as any;

        if (play) {
          play?.click();
        }

        play?.click();
      }, 1000);
    }
  }, [autoplay]);

  const [leftAds, setLeftAds] = useState<number>();
  const [rightAds, setRightAds] = useState<number>();
  const [bottomAds, setBottomAds] = useState<number>();
  const [videoWidth, setVideoWidth] = useState(window.innerWidth);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight);

  useEffect(() => {
    setInterval(() => {
      const widthRightAds =
        document.querySelector(".right-ads")?.clientWidth || 0;
      const widthLeftAds =
        document.querySelector(".left-ads")?.clientWidth || 0;
      const heightBottomAds =
        document.querySelector(".bottom-ads")?.clientHeight || 0;

      setLeftAds(widthLeftAds);
      setRightAds(widthRightAds);
      setBottomAds(heightBottomAds);

      const widthTotal = Math.abs(Number(widthLeftAds) + Number(widthRightAds));

      setVideoWidth(window.innerWidth - widthTotal);
      setVideoHeight(window.innerHeight - heightBottomAds);
    }, 100);
  }, [rightAds, leftAds, bottomAds]);

  return (
    <div className="fixed top-0 left-0 w-full">
      <>
        {showThumbnail ? (
          <>
            <div className="h-[100vh] flex w-[100vw] absolute z-20 justify-center items-center">
              <PlayIcon color="white" className="w-14 cursor-pointer" />
            </div>
            <img
              src={Thumb}
              alt="Thumbnail"
              className="absolute z-20 w-full cursor-pointer"
            />
          </>
        ) : (
          <></>
        )}

        <Loader visible={loading} />
        {advertisements.map((advertisement, index) => {
          if (
            shouldShowAdvertisement(advertisement) &&
            advertisement.position === "left"
          ) {
            return (
              <div
                key={index}
                className="left-ads"
                style={{
                  position: "absolute",
                  ...getAdvertisementPositionStyles(advertisement.position),
                }}
              >
                {advertisement.type === "video/mp4" ? (
                  <video
                    style={{ height: "100%" }}
                    muted
                    src={advertisement.url}
                    autoPlay
                  >
                    <source src={advertisement.url} type={advertisement.type} />
                  </video>
                ) : (
                  <img
                    src={advertisement.url}
                    style={{ height: "100%" }}
                    alt="Advertisement"
                  />
                )}
              </div>
            );
          }
          return null;
        })}
        <div
          className={`flex w-full items-start ${
            Boolean(leftAds) ? "justify-end" : "justify-start"
          }`}
        >
          {autoplay ? (
            <ReactPlayer
              onPlay={handleOnPlay}
              muted
              playing={true}
              url={event.url}
              light={event.thumbnail}
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: nofullscreen,
                  },
                },
              }}
              playsinline={true}
              onClickPreview={playVideo}
              width={videoWidth + "px"}
              height={videoHeight + "px"}
            />
          ) : (
            <ReactPlayer
              onPlay={handleOnPlay}
              url={event.url}
              light={event.thumbnail}
              onClickPreview={playVideo}
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: nofullscreen,
                  },
                },
              }}
              playsinline={true}
              width={videoWidth + "px"}
              height={videoHeight + "px"}
              style={{ position: "fixed" }}
            />
          )}
        </div>
        {advertisements.map((advertisement, index) => {
          if (
            shouldShowAdvertisement(advertisement) &&
            advertisement.position === "right"
          ) {
            return (
              <div
                key={index}
                className="right-ads"
                style={{
                  position: "absolute",
                  ...getAdvertisementPositionStyles(advertisement.position),
                }}
              >
                {advertisement.type === "video/mp4" ? (
                  <video
                    muted
                    src={advertisement.url}
                    style={{ height: "100%" }}
                    autoPlay
                  >
                    <source src={advertisement.url} type={advertisement.type} />
                  </video>
                ) : (
                  <img
                    src={advertisement.url}
                    style={{ height: "100%" }}
                    alt="Advertisement"
                  />
                )}
              </div>
            );
          }
          return null;
        })}
      </>
      {advertisements.map((advertisement, index) => {
        if (
          shouldShowAdvertisement(advertisement) &&
          advertisement.position === "bottom"
        ) {
          return (
            <div
              key={index}
              className="bottom-ads"
              style={{
                position: "fixed",
                ...getAdvertisementPositionStyles(advertisement.position),
              }}
            >
              {advertisement.type === "video/mp4" ? (
                <video src={advertisement.url} width="100%" autoPlay>
                  <source src={advertisement.url} type={advertisement.type} />
                </video>
              ) : (
                <img
                  src={advertisement.url}
                  height="100%"
                  alt="Advertisement"
                />
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Embed;

import React, { Fragment, useEffect, useState } from "react";

import { LocationType, Position, SearchInputProps } from "./types";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Container } from "./styles";
import { fetchLocalMapBox } from "../../services/mapBox";
import SmartMap from "../SmartMap";
import InputSelect from "../Form/InputSelect";
import Icon from "../Icon";
import { IconEnum } from "../Icon/types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Place = {
  coords: any;
  label: string;
  value: string;
};

const Location: React.FC<SearchInputProps> = ({ label, setValue }) => {
  const initialPositions: Position[] = [
    {
      name: "São Paulo, Brasil",
      lat: -23.550651,
      lng: -46.633382,
    },
  ];

  const [positions, setPositions] = useState<Position[]>(initialPositions);
  const [places, setPlaces] = useState<Place[]>();
  const [locations, setLocations] = useState<LocationType[]>([
    {
      city: "São Paulo",
      country: "Brazil",
      latitude: -23.550651,
      longitude: -46.633382,
      province: "São Paulo",
    },
  ]);

  const lastPosition = positions[positions.length - 1];

  const { t } = useTranslation();

  useEffect(() => {
    setValue("campaign_locations", locations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPosition, setValue]);

  const handleChange = async (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const value = String(event.currentTarget.value);

    if (value.length < 5) return;

    const response = await fetchLocalMapBox(value);

    const places: Place[] = response?.features?.map((item: any) => {
      return {
        coords: item.center,
        label: item.place_name,
        value: item.place_name,
      };
    });

    setPlaces(places);
  };

  const handleSetPosition = (placeName: string) => {
    const place = places?.find((place) => place.label === placeName);
    const placeNameValues = placeName.split(",");

    setPositions([
      ...positions,
      {
        name: placeName,
        lng: place?.coords[0],
        lat: place?.coords[1],
      },
    ]);

    console.log([
      ...locations,
      {
        city: placeNameValues[0],
        country: placeNameValues[placeNameValues.length - 1],
        province: placeNameValues[0],
        longitude: place?.coords[0],
        latitude: place?.coords[1],
      },
    ]);

    setLocations([
      ...locations,
      {
        city: placeNameValues[0],
        country: placeNameValues[placeNameValues.length - 1],
        province: placeNameValues[0],
        longitude: place?.coords[0],
        latitude: place?.coords[1],
      },
    ]);
  };

  const handleDelete = (placeName: string) => {
    const filterPositions = positions?.filter(
      (place) => place.name !== placeName
    );

    const filterLocations = locations?.filter(
      (location) => location.country !== placeName
    );

    if (filterPositions.length === 0) {
      toast.error(String(t("newCamp.youCant")));
      return;
    }

    setPositions(filterPositions);
    setLocations(filterLocations);
  };

  return (
    <Container>
      <InputSelect
        label={label}
        editable
        options={places || []}
        onChangeText={handleChange}
        onClickText={handleSetPosition}
      />
      <div className="flex mt-3 flex-wrap">
        {positions.map((loc) => (
          <div className="flex mr-2 mb-2 justify-between items-center rounded bg-lime-700 p-2">
            <label className="text-white">{loc.name}</label>

            <div
              className="ml-2 cursor-pointer"
              onClick={() => handleDelete(loc.name)}
            >
              <Icon name={IconEnum.close} />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10 mb-10 flex justify-center h-96">
        {initialPositions.length > 0 && (
          <MapContainer
            center={initialPositions[0]}
            zoom={15}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution={`&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> ${t(
                "newCamp.contribuitors"
              )}contributors`}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Fragment key={lastPosition.name}>
              <Marker position={[lastPosition.lat, lastPosition.lng]}></Marker>
              <SmartMap coords={[lastPosition.lat, lastPosition.lng]} />
            </Fragment>
          </MapContainer>
        )}
      </div>
    </Container>
  );
};

export default Location;

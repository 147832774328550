import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { IconEnum } from "../../components/Icon/types";
import { Column } from "../../components/Table/types";
import TableDropdown from "../../components/TableDropdown";

export type EventType = {
  id?: string;
  uuid?: string;
  title?: string;
  thumbnail?: string;
  media?: string;
  mux_playback_id?: string;
};

export const eventsTableColumns = (
  onEdit: (id: number) => void,
  onDelete: (id: number) => void
): Column<EventType>[] => {
  const options = [
    {
      label: "Update",
      icon: <Icon name={IconEnum.analytics} />,
      isDisabled: !onEdit,
      action: (id: number) => onEdit?.(id),
    },
    {
      label: "Delete",
      icon: <Icon name={IconEnum.document} />,
      isDisabled: !onDelete,
      action: (id: number) => onDelete?.(id),
    },
  ];

  return [
    {
      title: "id",
      fieldResolver: (event) => String(event.id),
    },
    {
      title: "Title",
      fieldResolver: (event) => String(event.title),
    },
    {
      title: "Thumbnail",
      fieldResolver: (event) => String(event.thumbnail),
    },
    {
      render: ({ row: campaign }) =>
        " " || <TableDropdown options={options} id={Number(campaign.id)} />,
    },
    {
      render: ({ row: { id } }) => (
        <div className="flex space-x-3 w-40">
          <Button
            label="Delete Video"
            onClick={() => onDelete(Number(id))}
            variation="danger"
          />
        </div>
      ),
    },
  ];
};

import React, { Fragment } from "react";
import { Container } from "./styles";
import { TableProps } from "./types";
import ReactHtmlParser from "react-html-parser";

const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <Container>
      <table
        className="border-collapse table-auto w-full text-sm"
        cellSpacing={20}
      >
        <thead>
          <tr>
            {columns.map(
              (column, index) =>
                column.title && <th key={String(index)}>{column.title}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row, rowIndex) => (
              <tr key={String(rowIndex)}>
                {columns.map((column) =>
                  column.fieldResolver?.(row) ? (
                    <td key={String(row)}>
                      <div className="flex items-center">
                        {ReactHtmlParser(String(column.fieldResolver?.(row)))}
                      </div>
                    </td>
                  ) : (
                    ""
                  )
                )}

                {columns &&
                  columns.map((column, columnIndex) => (
                    <Fragment key={columnIndex}>
                      {column.render && (
                        <td key={String(columnIndex)}>
                          {column.render({
                            currentIndex: rowIndex,
                            row,
                          })}
                        </td>
                      )}
                    </Fragment>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </Container>
  );
};

export default Table;

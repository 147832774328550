import styled from "styled-components";

export const Container = styled.div`
  background-color: #2b3648;

  table {
    margin: 0 auto;
  }

  table thead tr,
  table thead th {
    background-color: transparent;
    margin-bottom: 10px;
    padding-bottom: 10px;

    &:hover {
      background-color: transparent;
    }
  }

  thead tr {
    background-color: #1d2838;
    border-bottom: 1px #1d2838 solid;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 10px;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: transparent;
    color: #9195a7;
    border-bottom: 1px #1d2838 solid;
    border-radius: 10px;
  }

  td,
  th {
    border-bottom: 12px #2b3648 solid;
    font-family: "Mulish";
  }

  tr {
    background-color: #1d2838;
    border-bottom: 1px #1d2838 solid;
    color: #ffffff;
    border-radius: 10px;
  }

  thead th {
    padding-left: 30px;
    font-family: "Inter";
  }

  thead th:first-child {
    padding-left: 0px;
  }

  tbody td {
    padding: 30px;
  }
`;

import { AxiosInstance } from "axios";
import { setFormData } from "../../../helpers";
import { OtpType } from "../../../settings/yup/schemas/OtpSchema";
import { ProfileType } from "../../../settings/yup/schemas/ProfileSchema";
import { SignupType } from "../../../settings/yup/schemas/SignupSchema";
import { Auth, Organization } from "./types";

const client = (httpClient: AxiosInstance) => ({
  register: async (data: SignupType) => {
    data.is_active = true;
    const response = await httpClient.post("/users", setFormData(data));

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  reset: async (email: string) => {
    const response = await httpClient.post("/reset-password", {
      email,
    });

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  newPassword: async (data: OtpType) => {
    const response = await httpClient.put("/reset-password", setFormData(data));

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  events: async (user: number) => {
    const response = await httpClient.post("/events", {
      title: `Event for a new campaign`,
      thumbnail: "https://i.imgur.com/iTi6e26.png",
      user: user,
    });

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
  updateOrganization: async (organizationId: number) => {
    const user = localStorage.getItem("user");

    if (user) {
      const { id } = JSON.parse(user);

      const response = await httpClient.patch(`/users/${id}`, {
        organization: organizationId,
      });

      let errors = null;

      if (!response.data) {
        errors = {
          status: response?.request?.status,
          statusText: response?.request?.statusText,
        };
      }

      return {
        data: response.data,
        errors,
      };
    }
  },
  update: async (data: ProfileType) => {
    const user = localStorage.getItem("user");

    if (user) {
      const { id } = JSON.parse(user);

      const response = await httpClient.patch(
        `/users/${id}`,
        setFormData(data)
      );

      let errors = null;

      if (!response.data) {
        errors = {
          status: response?.request?.status,
          statusText: response?.request?.statusText,
        };
      }

      return {
        data: response.data,
        errors,
      };
    }
  },
  login: async ({ username, password }: Auth) => {
    const response = await httpClient.post("/jwt-auth", {
      username,
      password,
    });

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };
    }

    if (response.data) {
      localStorage.setItem("token", JSON.stringify(response.data));
    }

    return {
      data: response.data,
      errors,
    };
  },
  refresh: async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("organization");
      localStorage.removeItem("organizationType");
      window.location.href = "/login";
      return;
    }

    const { refresh } = JSON.parse(token);

    if (!refresh) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("organization");
      localStorage.removeItem("organizationType");
      window.location.href = "/login";
    }

    const response = await httpClient.post("/jwt-auth-refresh", {
      refresh,
    });

    if (response.data.code === "token_not_valid") {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("organization");
      localStorage.removeItem("organizationType");
      window.location.href = "/login";
      return;
    }

    if (response.data) {
      localStorage.setItem(
        "token",
        JSON.stringify({
          access: response.data.access,
        })
      );

      return {
        access: response.data.access,
      };
    }
  },
  verify: async (token: string) => {
    const response = await httpClient.get(`/verify-email?token=${token}`);

    let errors = null;

    if (!response?.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      throw new Error(errors.status);
    }

    return {
      data: response.data,
      errors,
    };
  },
  organization: async ({ name, business, sport }: Organization) => {
    const arrayBuffer = Uint8Array.from(
      window.atob(
        "iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
      ),
      (c) => c.charCodeAt(0)
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const file = new File([arrayBuffer], "png.png", {
      type: "image/png",
    });

    const response = await httpClient.post(
      "/organizations",
      setFormData({
        //picture: file,
        name,
        business,
        sport,
      })
    );

    let errors = null;

    if (!response.data) {
      errors = {
        status: response?.request?.status,
        statusText: response?.request?.statusText,
      };

      return {
        data: response.data,
        errors,
      };
    }

    return {
      data: response.data,
      errors,
    };
  },
});

export default client;

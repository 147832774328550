import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { eventsTableColumns, EventType } from "./eventsTableColumns";
import Page from "../../components/Page";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import services from "../../services/api";
import { useAuthContext } from "../../store/context/AuthContext";

const Events: React.FC = () => {
  const [events, setEvents] = useState<EventType[]>([]);

  const { handleLoading } = useAuthContext();

  const onEdit = (id?: number) => {
    console.log(`Update: ${id}`);
  };

  const onDelete = async (id?: number) => {
    if (id) {
      await services.events.delete(id);
      await loadEvents();
    }
  };

  const loadEvents = async () => {
    handleLoading(true);
    const response = services.events.list();
    response
      .then((response) => {
        let events = response.data;

        if (events?.results?.length) {
          events = events?.results?.map((event: EventType) => {
            return {
              id: event.id,
              title: event.title,
              thumbnail: `<img src=${event.thumbnail} alt=${event.title} width="100" />`,
            };
          });

          setEvents(events);
        } else {
          setEvents([]);
        }

        handleLoading(false);
      })
      .finally(() => handleLoading(false));
  };

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = eventsTableColumns(onEdit, onDelete);

  const navigate = useNavigate();

  return (
    <Page
      title="Events"
      button={
        <div className="w-64">
          <Button
            label="Create New Event"
            onClick={() => navigate("/event/new")}
          />
        </div>
      }
    >
      <Table data={events} columns={columns} />
    </Page>
  );
};

export default Events;

import * as yup from "yup";
import { LocationType } from "../../../components/Location/types";
import { requiredMessage } from "../../../helpers";

export const CampaignSchema = yup.object().shape({
  campaign_name: yup.string().required(requiredMessage("Campaign name")),
  advertisement_display: yup.string().required(requiredMessage("Ad type")),
  campaign_languages: yup.array().required(requiredMessage("Language")),
  campaign_daily_budget: yup.string().required(requiredMessage("Daily budget")),
  campaign_event_budget: yup.string().required(requiredMessage("Event budget")),
  campaign_start_date: yup.string().required(requiredMessage("Start date")),
  campaign_end_date: yup.string().required(requiredMessage("End date")),
  campaign_budget_description: yup
    .string()
    .required(requiredMessage("Budget description")),
  campaign_distribution: yup.string().required(requiredMessage("Distribution")),
  campaign_ages: yup.array().required(requiredMessage("Ages")),
  campaign_parent_status: yup
    .array()
    .required(requiredMessage("Parent status")),
  campaign_interests: yup.array().required(requiredMessage("Interests")),
  campaign_genders: yup.array().required(requiredMessage("Gender")),
  candidate_event: yup.number().required(requiredMessage("Events")),
  campaign_location_city: yup.string().required(requiredMessage("City")),
  campaign_location_province: yup
    .string()
    .required(requiredMessage("Province")),
  campaign_location_country: yup.string().required(requiredMessage("Country")),
  campaign_currency: yup.number().required(requiredMessage("Currency")),
  campaign_locations: yup.array().required(requiredMessage("Locations")),
});

export type CampaignType = {
  campaign_name: string;
  campaign_languages: number[];
  campaign_daily_budget: string;
  campaign_event_budget: string;
  campaign_start_date: string;
  campaign_end_date: string;
  campaign_budget_description: string;
  campaign_distribution: string;
  campaign_ages: string[];
  campaign_parent_status: string[];
  campaign_interests: string[] | number[];
  campaign_genders: string[];
  campaign_currency: number;
  candidate_event: number;
  campaign_location_city: string;
  campaign_location_province: string;
  campaign_location_country: string;
  advertisement_display: string;
  advertisement_media: File;
  advertisement_total_spending: number;
  advertisement_total_sales: number;
  campaign_locations: LocationType[] | undefined;
};

import React, { useEffect, ChangeEvent, useState } from "react";
import Checkbox from "../../../../components/Form/Checkbox";
import Input from "../../../../components/Form/Input";
import firstAds from "../../../../assets/lion1.png";
import secondAds from "../../../../assets/lion2.png";
import thirdAds from "../../../../assets/lion3.png";
import AdPreview from "./AdPreview";
import { AdType, Campaign, CampaignProps } from "./types";
import { toast } from "react-toastify";
import services from "../../../../services/api";
import { useTranslation } from "react-i18next";

const allowedExtensions =
  /(\.jpg|\.jpeg|\.mp4|\.mov|\.wmv|\.flv|\.mkv|\.avi)$/i;

const videoExtensions = /(\.mp4|\.mov|\.wmv|\.flv|\.mkv|\.avi)$/i;

const CampaignType: React.FC<CampaignProps> = ({ setValue }) => {
  const [logo, setLogo] = useState<string>();
  const [lateralBanner, setLateralBanner] = useState<string>();
  const [bottomBanner, setBottomBanner] = useState<string>();
  const [campaignData, setCampaignData] = useState<Campaign>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOnChange = async (
    bannerType: string,
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setType(bannerType);
    setIsVideo(false);
    const { files } = event.currentTarget;
    if (files) {
      const file = URL.createObjectURL(files?.[0]);

      const formData = new FormData();
      formData.append("file", file);

      if (!allowedExtensions.exec(String(files?.[0].name))) {
        toast.error(t("newCamp.only"));
        return;
      }

      if (bannerType === "logo") {
        setLogo(file);
        setValue("advertisement_media", files?.[0]);
        setVideoUrl("");
      }

      if (bannerType === "bottomBanner") {
        if (videoExtensions.exec(String(files?.[0].name))) {
          setIsLoading(true);
          setVideoUrl("");
          setBottomBanner(file);
          setLateralBanner("");
          setIsVideo(true);
          const response = await services.campaigns.uploadVideo(files?.[0]);
          setVideoUrl(response.data.media);
          setIsLoading(false);
          setValue("advertisement_media", files?.[0]);
          return;
        } else {
          setVideoUrl("");
          setBottomBanner(file);
        }

        setCampaignData({
          ...campaignData,
          adType: AdType.banner,
        });
        setVideoUrl("");
        setLateralBanner("");
        setValue("advertisement_media", files?.[0]);
      }

      if (bannerType === "lateralBanner") {
        if (videoExtensions.exec(String(files?.[0].name))) {
          setIsLoading(true);
          setVideoUrl("");
          setLateralBanner("");
          setBottomBanner("");
          setIsVideo(true);
          const response = await services.campaigns.uploadVideo(files?.[0]);
          setVideoUrl(response.data.media);
          setIsLoading(false);
        } else {
          setVideoUrl("");
          setLateralBanner(file);
        }

        setCampaignData({
          ...campaignData,
          adType: AdType.bannerRight,
        });
        setBottomBanner("");
        setLateralBanner(file);
        setValue("advertisement_media", files?.[0]);
      }
    }
  };

  const isImageCampaign =
    campaignData?.adType === AdType.banner ||
    campaignData?.adType === AdType.thumbnail ||
    campaignData?.adType ||
    AdType.bannerRight;

  useEffect(() => {
    if (campaignData?.adType) {
      setValue("advertisement_display", campaignData?.adType);
    }

    if (campaignData?.adType === AdType.banner) {
      setLogo("");
    }

    if (campaignData?.adType === AdType.thumbnail) {
      setBottomBanner("");
      setLateralBanner("");
    }
  }, [setValue, campaignData?.adType]);

  return (
    <div className="flex flex-col w-full md:flex-row md:space-x-8">
      <div className={isImageCampaign ? "md:w-6/12 w-full" : "w-full"}>
        <div className="w-80 md:w-full">
          {" "}
          <Input
            label={String(t("newCamp.name"))}
            type="text"
            placeholder={String(t("newCamp.give"))}
            onChange={(event) => {
              setValue("campaign_name", event.target.value);
            }}
          />
        </div>

        <div className="flex flex-col mt-10">
          <p className="text-white">{t("newCamp.how")}</p>
          <div className="flex flex-col sm:flex-row justify-between bg-blue-300 rounded-2xl md:p-0 p-6 md:pl-10 mt-5">
            <Checkbox
              label={String(t("newCamp.text1"))}
              name="banner"
              isChecked={campaignData?.adType === AdType.banner}
              onChange={(event) =>
                setCampaignData({
                  ...campaignData,
                  adType: event.target.checked ? AdType.banner : undefined,
                })
              }
            />
            <img src={firstAds} alt="Placed" className="ml-5 h-[100px]" />
          </div>
          {campaignData?.adType === AdType.banner && (
            <div className="flex gap-1 mt-5 md:space-x-5">
              <div className="md:w-64 w-60">
                <Input
                  type="file"
                  id="upload-bottom"
                  onChange={(event) => handleOnChange("bottomBanner", event)}
                  hidden
                />
                <label
                  htmlFor="upload-bottom"
                  className="text-center w-full cursor-pointer rounded-2xl flex items-center justify-center border-transparent text-sm md:text-base text-white bg-gradient-to-r from-lime-500 to-lime-600 hover:from-lime-600 hover:to-lime-700 py-3 pr-0 pl-0"
                >
                  {type === "bottomBanner" && isLoading ? (
                    <svg
                      className="ml-2"
                      width="23px"
                      height="23px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="10"
                        r="35"
                        strokeDasharray="164.93361431346415 56.97787143782138"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          values="0 50 50;360 50 50"
                          keyTimes="0;1"
                        ></animateTransform>
                      </circle>
                    </svg>
                  ) : (
                    t("newCamp.bottom")
                  )}
                </label>
              </div>
              <div className="md:w-64 w-60">
                <Input
                  type="file"
                  id="upload-left"
                  onChange={(event) => handleOnChange("lateralBanner", event)}
                  hidden
                />
                <label
                  htmlFor="upload-left"
                  className="w-full cursor-pointer rounded-2xl flex items-center justify-center border-transparent text-sm md:text-base text-white bg-gradient-to-r from-lime-500 to-lime-600 hover:from-lime-600 hover:to-lime-700 py-3 pr-0 pl-0"
                >
                  {type === "lateralBanner" && isLoading ? (
                    <svg
                      className="ml-2"
                      width="23px"
                      height="23px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="10"
                        r="35"
                        strokeDasharray="164.93361431346415 56.97787143782138"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          values="0 50 50;360 50 50"
                          keyTimes="0;1"
                        ></animateTransform>
                      </circle>
                    </svg>
                  ) : (
                    t("newCamp.lateral")
                  )}
                </label>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col mt-10">
          <div className="flex justify-between bg-blue-300 rounded-2xl pl-1 md:pl-10 mt-5">
            <Checkbox
              label={String(t("newCamp.text2"))}
              isChecked={campaignData?.adType === AdType.thumbnail}
              name="thumbnail"
              onChange={(event) =>
                setCampaignData({
                  ...campaignData,
                  adType: event.target.checked ? AdType.thumbnail : undefined,
                })
              }
            />
            <img src={secondAds} alt="Placed" className="h-[100px]" />
          </div>
          {campaignData?.adType === AdType.thumbnail && (
            <div className="flex mt-5 flex-col w-64">
              <Input
                type="file"
                id="upload-thumbnail"
                onChange={(event) => handleOnChange("logo", event)}
                hidden
              />
              <label
                htmlFor="upload-thumbnail"
                className="w-full cursor-pointer rounded-2xl flex items-center justify-center py-3 border-transparent text-base text-white bg-gradient-to-r from-lime-500 to-lime-600 hover:from-lime-600 hover:to-lime-700 pr-0 pl-0"
              >
                {t("newCamp.thumb")}
              </label>
            </div>
          )}
        </div>

        <div className="flex flex-col mt-10">
          <div className="flex justify-between bg-blue-300 rounded-2xl pl-1 md:pl-10 mt-5">
            <Checkbox
              label={String(t("newCamp.text3"))}
              isChecked={campaignData?.adType === AdType.video}
              name="video"
              onChange={(event) =>
                setCampaignData({
                  ...campaignData,
                  adType: event.target.checked ? AdType.video : undefined,
                })
              }
            />

            <img src={thirdAds} alt="Placed" className="h-[100px]" />
          </div>
        </div>
      </div>
      {isImageCampaign && (
        <div className="md:w-6/12 mt-10 md:mt-0 w-full">
          <AdPreview
            isVideo={isVideo}
            videoUrl={videoUrl}
            bottomBanner={bottomBanner}
            lateralBanner={lateralBanner}
            logo={logo}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignType;

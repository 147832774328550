import { useEffect, useState } from "react";
import services from "../../services/api";
import { CurrencyType } from "../../views/CreateCampaign/Steps/Budget";
import { useTranslation } from "react-i18next";

interface Props {
  budget: number;
  payment_status: string;
  onClick: () => void;
  days: number;
  currency: number;
}

export default function ButtonStatus({
  budget,
  onClick,
  payment_status,
  days,
  currency,
}: Props) {
  const [currencies, setCurrencies] = useState<CurrencyType>();
  const getCurrencies = async () => {
    const response = await services.campaigns.getCurrencyById(currency);
    setCurrencies(response.data);
  };
  const { t } = useTranslation();

  useEffect(() => {
    getCurrencies();
  }, []);

  const switchCoin = (abbrev: string) => {
    switch (abbrev) {
      case "BRL":
        return "R$";
      case "USD":
        return "US$";
      case "EUR":
        return "€";
      case "MXN":
        return "$";
      default:
        break;
    }
  };

  return (
    <div className="font-semibold px-2 justify-evenly items-center flex flex-col font-mulish text-sm">
      <p>
        {" "}
        {switchCoin(String(currencies?.abbreviation))} {Number(budget) * days}
      </p>
      <p
        className={
          payment_status === "paid"
            ? "text-[#09B55F] text-center"
            : "text-[#ff0000] text-center"
        }
      >
        {" "}
        {payment_status === "paid"
          ? t("campTable.paid")
          : t("campTable.unpaid")}
      </p>
      {payment_status === "unpaid" && (
        <a
          onClick={onClick}
          className="border-2 cursor-pointer text-center p-1 rounded-lg mt-2 text-xs font-medium w-[70px] border-[#ff0000]"
        >
          {t("campTable.try")}
        </a>
      )}
    </div>
  );
}

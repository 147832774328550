export type IconProps = {
  name: IconEnum;
};

export enum IconEnum {
  dashboard = "dashboard",
  analytics = "analytics",
  people = "people",
  settings = "settings",
  integration = "integration",
  notify = "notify",
  right = "right",
  down = "down",
  peopleOutline = "peopleOutline",
  document = "document",
  like = "like",
  chat = "chat",
  alert = "alert",
  anotherIcon = "anotherIcon",
  ellipsis = "ellipsis",
  arrowLeft = "arrowLeft",
  close = "close",
  loading = "Loading",
  logout = "logout",
}

import { IconEnum } from "../../components/Icon/types";

export const icons = [
  {
    name: IconEnum.dashboard,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 13H11V3H3V13ZM3 21H11V15H3V21ZM13 21H21V11H13V21ZM13 3V9H21V3H13Z"
          fill="#616D7E"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.analytics,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z"
          fill="#56657F"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.people,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z"
          fill="#56657F"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.settings,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4301 12.98C19.4701 12.66 19.5001 12.34 19.5001 12C19.5001 11.66 19.4701 11.34 19.4301 11.02L21.5401 9.37C21.7301 9.22 21.7801 8.95 21.6601 8.73L19.6601 5.27C19.5401 5.05 19.2701 4.97 19.0501 5.05L16.5601 6.05C16.0401 5.65 15.4801 5.32 14.8701 5.07L14.4901 2.42C14.4601 2.18 14.2501 2 14.0001 2H10.0001C9.75008 2 9.54008 2.18 9.51008 2.42L9.13008 5.07C8.52008 5.32 7.96008 5.66 7.44008 6.05L4.95008 5.05C4.72008 4.96 4.46008 5.05 4.34008 5.27L2.34008 8.73C2.21008 8.95 2.27008 9.22 2.46008 9.37L4.57008 11.02C4.53008 11.34 4.50008 11.67 4.50008 12C4.50008 12.33 4.53008 12.66 4.57008 12.98L2.46008 14.63C2.27008 14.78 2.22008 15.05 2.34008 15.27L4.34008 18.73C4.46008 18.95 4.73008 19.03 4.95008 18.95L7.44008 17.95C7.96008 18.35 8.52008 18.68 9.13008 18.93L9.51008 21.58C9.54008 21.82 9.75008 22 10.0001 22H14.0001C14.2501 22 14.4601 21.82 14.4901 21.58L14.8701 18.93C15.4801 18.68 16.0401 18.34 16.5601 17.95L19.0501 18.95C19.2801 19.04 19.5401 18.95 19.6601 18.73L21.6601 15.27C21.7801 15.05 21.7301 14.78 21.5401 14.63L19.4301 12.98ZM12.0001 15.5C10.0701 15.5 8.50008 13.93 8.50008 12C8.50008 10.07 10.0701 8.5 12.0001 8.5C13.9301 8.5 15.5001 10.07 15.5001 12C15.5001 13.93 13.9301 15.5 12.0001 15.5Z"
          fill="#56657F"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.integration,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
          fill="#56657F"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.notify,
    icon: (
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2335 6.40096C16.2335 4.96853 15.6323 3.59478 14.5623 2.5819C13.4923 1.56903 12.041 1 10.5278 1C9.01454 1 7.56327 1.56903 6.49325 2.5819C5.42322 3.59478 4.82209 4.96853 4.82209 6.40096C4.82209 12.7021 1.96924 14.5024 1.96924 14.5024H19.0863C19.0863 14.5024 16.2335 12.7021 16.2335 6.40096Z"
          stroke="#8C8A97"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1731 18.103C12.0059 18.3758 11.7659 18.6023 11.4772 18.7597C11.1885 18.9171 10.8611 19 10.528 19C10.1948 19 9.86742 18.9171 9.57869 18.7597C9.28997 18.6023 9.05 18.3758 8.88281 18.103"
          stroke="#8C8A97"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.down,
    icon: (
      <svg
        width="17"
        height="10"
        viewBox="0 0 17 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.890503 1L8.28547 8L15.6804 1"
          stroke="#ACABB7"
          strokeWidth="2"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.peopleOutline,
    icon: (
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.25 1.72656C5.45435 1.72656 4.69129 2.04263 4.12868 2.60524C3.56607 3.16785 3.25 3.93091 3.25 4.72656C3.25 5.52221 3.56607 6.28527 4.12868 6.84788C4.69129 7.41049 5.45435 7.72656 6.25 7.72656C7.04565 7.72656 7.80871 7.41049 8.37132 6.84788C8.93393 6.28527 9.25 5.52221 9.25 4.72656C9.25 3.93091 8.93393 3.16785 8.37132 2.60524C7.80871 2.04263 7.04565 1.72656 6.25 1.72656ZM1.75 4.72656C1.75 3.53309 2.22411 2.3885 3.06802 1.54458C3.91193 0.700668 5.05653 0.226563 6.25 0.226562C7.44347 0.226563 8.58807 0.700668 9.43198 1.54458C10.2759 2.3885 10.75 3.53309 10.75 4.72656C10.75 5.92004 10.2759 7.06463 9.43198 7.90854C8.58807 8.75246 7.44347 9.22656 6.25 9.22656C5.05653 9.22656 3.91193 8.75246 3.06802 7.90854C2.22411 7.06463 1.75 5.92004 1.75 4.72656Z"
          fill="#849AA9"
        />
        <path
          d="M1.843 12.2263H10.657C10.2467 10.5786 8.512 9.22635 6.25 9.22635C3.988 9.22635 2.25325 10.5786 1.843 12.2263ZM0.25 12.9763C0.25 9.9726 3.049 7.72635 6.25 7.72635C9.451 7.72635 12.25 9.9726 12.25 12.9763C12.25 13.1753 12.171 13.366 12.0303 13.5067C11.8897 13.6473 11.6989 13.7263 11.5 13.7263H1C0.801088 13.7263 0.610322 13.6473 0.46967 13.5067C0.329018 13.366 0.25 13.1753 0.25 12.9763ZM11.5007 7.63185C11.4116 7.79279 11.315 7.94946 11.2113 8.10135C11.6785 8.51385 12.094 8.98335 12.4465 9.49935C13.8497 9.9756 14.857 11.0218 15.157 12.2263H13.6C13.6982 12.7108 13.75 13.2126 13.75 13.7263H16C16.1989 13.7263 16.3897 13.6473 16.5303 13.5067C16.671 13.366 16.75 13.1753 16.75 12.9763C16.75 10.8823 15.3895 9.1566 13.4815 8.3031C14.2638 7.70596 14.8288 6.86882 15.0899 5.91992C15.351 4.97102 15.2938 3.96269 14.9271 3.04939C14.5604 2.13609 13.9044 1.36819 13.0596 0.863318C12.2148 0.358446 11.2278 0.144445 10.2498 0.254098C10.7511 0.703106 11.1737 1.23294 11.5 1.8216C12.1433 1.98868 12.713 2.36459 13.1197 2.89037C13.5264 3.41614 13.747 4.06203 13.747 4.72672C13.747 5.39142 13.5264 6.03731 13.1197 6.56308C12.713 7.08885 12.1433 7.46476 11.5 7.63185H11.5007Z"
          fill="#849AA9"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.document,
    icon: (
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 15.4766C1.90326 15.4766 1.33097 15.2395 0.90901 14.8176C0.487053 14.3956 0.25 13.8233 0.25 13.2266V2.72656C0.25 2.12983 0.487053 1.55753 0.90901 1.13557C1.33097 0.713615 1.90326 0.476563 2.5 0.476562H8.629C9.22543 0.476889 9.79733 0.71401 10.219 1.13581L13.0915 4.00681C13.5132 4.42879 13.7501 5.00098 13.75 5.59756V13.2266C13.75 13.8233 13.5129 14.3956 13.091 14.8176C12.669 15.2395 12.0967 15.4766 11.5 15.4766H2.5ZM1.75 13.2266C1.75 13.4255 1.82902 13.6162 1.96967 13.7569C2.11032 13.8975 2.30109 13.9766 2.5 13.9766H11.5C11.6989 13.9766 11.8897 13.8975 12.0303 13.7569C12.171 13.6162 12.25 13.4255 12.25 13.2266V6.47656H10C9.40326 6.47656 8.83097 6.23951 8.40901 5.81755C7.98705 5.3956 7.75 4.8233 7.75 4.22656V1.97656H2.5C2.30109 1.97656 2.11032 2.05558 1.96967 2.19623C1.82902 2.33688 1.75 2.52765 1.75 2.72656V13.2266ZM10 4.97656H11.9395L9.25 2.28706V4.22656C9.25 4.42547 9.32902 4.61624 9.46967 4.75689C9.61032 4.89754 9.80109 4.97656 10 4.97656ZM4 7.97656C3.80109 7.97656 3.61032 8.05558 3.46967 8.19623C3.32902 8.33688 3.25 8.52765 3.25 8.72656C3.25 8.92548 3.32902 9.11624 3.46967 9.25689C3.61032 9.39754 3.80109 9.47656 4 9.47656H10C10.1989 9.47656 10.3897 9.39754 10.5303 9.25689C10.671 9.11624 10.75 8.92548 10.75 8.72656C10.75 8.52765 10.671 8.33688 10.5303 8.19623C10.3897 8.05558 10.1989 7.97656 10 7.97656H4ZM4 10.9766C3.80109 10.9766 3.61032 11.0556 3.46967 11.1962C3.32902 11.3369 3.25 11.5276 3.25 11.7266C3.25 11.9255 3.32902 12.1162 3.46967 12.2569C3.61032 12.3975 3.80109 12.4766 4 12.4766H7C7.19891 12.4766 7.38968 12.3975 7.53033 12.2569C7.67098 12.1162 7.75 11.9255 7.75 11.7266C7.75 11.5276 7.67098 11.3369 7.53033 11.1962C7.38968 11.0556 7.19891 10.9766 7 10.9766H4Z"
          fill="#849AA9"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.like,
    icon: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1945 3.72166C10.4478 3.46838 10.775 3.30211 11.1289 3.24682C11.4828 3.19154 11.8451 3.25009 12.1636 3.41404C12.4821 3.57799 12.7402 3.83886 12.9008 4.15903C13.0615 4.47919 13.1162 4.8421 13.0573 5.19541L12.635 7.72891C13.6056 7.76387 14.5248 8.17404 15.199 8.87306C15.8733 9.57208 16.25 10.5054 16.25 11.4767V12.9767C16.25 13.9712 15.8549 14.925 15.1517 15.6283C14.4484 16.3316 13.4946 16.7267 12.5 16.7267H5C4.40326 16.7267 3.83097 16.4896 3.40901 16.0676C2.98705 15.6457 2.75 15.0734 2.75 14.4767V9.97666C2.75 9.37992 2.98705 8.80762 3.40901 8.38567C3.83097 7.96371 4.40326 7.72666 5 7.72666H6.1895L10.1945 3.72166ZM5.75 9.22666H5C4.80109 9.22666 4.61032 9.30568 4.46967 9.44633C4.32902 9.58698 4.25 9.77775 4.25 9.97666V14.4767C4.25 14.6756 4.32902 14.8663 4.46967 15.007C4.61032 15.1476 4.80109 15.2267 5 15.2267H5.75V9.22666ZM7.25 15.2267H12.5C13.0967 15.2267 13.669 14.9896 14.091 14.5676C14.5129 14.1457 14.75 13.5734 14.75 12.9767V11.4767C14.75 10.8799 14.5129 10.3076 14.091 9.88567C13.669 9.46371 13.0967 9.22666 12.5 9.22666H10.25C10.0511 9.22666 9.86032 9.14764 9.71967 9.00699C9.57902 8.86634 9.5 8.67557 9.5 8.47666C9.5 8.27775 9.57902 8.08698 9.71967 7.94633C9.86032 7.80568 10.0511 7.72666 10.25 7.72666H11.1147L11.5775 4.94866C11.5842 4.90883 11.5781 4.86789 11.5601 4.83176C11.542 4.79562 11.513 4.76616 11.4771 4.74763C11.4412 4.7291 11.4003 4.72246 11.3604 4.72866C11.3205 4.73487 11.2836 4.7536 11.255 4.78216L7.25 8.78716V15.2267Z"
          fill="#849AA9"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.chat,
    icon: (
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49999 4.72656C4.90199 4.72656 2.99999 6.51006 2.99999 8.47656C2.99999 9.13656 3.20474 9.75981 3.57374 10.3066C3.63679 10.3999 3.67802 10.5061 3.69438 10.6175C3.71074 10.7289 3.7018 10.8426 3.66824 10.9501L3.32699 12.0443L5.04824 11.7683C5.18773 11.7459 5.33072 11.7633 5.46074 11.8186C6.10611 12.0904 6.79971 12.2292 7.49999 12.2266C10.098 12.2266 12 10.4431 12 8.47656C12 6.51006 10.098 4.72656 7.49999 4.72656ZM1.49999 8.47656C1.49999 5.47281 4.29899 3.22656 7.49999 3.22656C10.701 3.22656 13.5 5.47281 13.5 8.47656C13.5 11.4803 10.701 13.7266 7.49999 13.7266C6.67256 13.7287 5.85184 13.5781 5.07899 13.2826L2.36849 13.7176C2.24233 13.7378 2.11309 13.7255 1.99302 13.6818C1.87295 13.6381 1.76603 13.5645 1.68241 13.4679C1.59879 13.3713 1.54124 13.2549 1.51522 13.1298C1.48921 13.0047 1.49558 12.875 1.53374 12.7531L2.13374 10.8308C1.71969 10.1152 1.50113 9.30331 1.49999 8.47656Z"
          fill="#849AA9"
        />
        <path
          d="M12.0555 7.12662C12.0183 7.21787 11.9994 7.31555 12 7.4141C12.0005 7.51265 12.0205 7.61012 12.0587 7.70095C12.0969 7.79178 12.1527 7.87418 12.2228 7.94344C12.2929 8.01271 12.376 8.06747 12.4673 8.10462C14.0198 8.73687 15 10.0479 15 11.4766C15 12.1366 14.7953 12.7599 14.4263 13.3066C14.3632 13.3999 14.322 13.5062 14.3056 13.6176C14.2893 13.729 14.2982 13.8426 14.3318 13.9501L14.673 15.0444L12.9518 14.7684C12.8123 14.7459 12.6693 14.7634 12.5393 14.8186C11.8939 15.0904 11.2003 15.2292 10.5 15.2266C8.47201 15.2266 6.83026 14.1174 6.24226 12.6909C6.16648 12.5069 6.0207 12.3605 5.83701 12.284C5.65332 12.2075 5.44676 12.2071 5.26276 12.2829C5.07877 12.3587 4.93242 12.5044 4.8559 12.6881C4.77939 12.8718 4.77898 13.0784 4.85476 13.2624C5.70901 15.3316 7.95751 16.7266 10.5 16.7266C11.3274 16.7288 12.1482 16.5782 12.921 16.2826L15.6315 16.7176C15.7577 16.7379 15.8869 16.7256 16.007 16.6819C16.1271 16.6382 16.234 16.5645 16.3176 16.4679C16.4012 16.3713 16.4588 16.2549 16.4848 16.1298C16.5108 16.0047 16.5044 15.8751 16.4663 15.7531L15.8663 13.8309C16.2803 13.1153 16.4989 12.3034 16.5 11.4766C16.5 9.31512 15.0278 7.52712 13.0328 6.71487C12.9415 6.67778 12.8438 6.65903 12.7453 6.65968C12.6469 6.66034 12.5494 6.6804 12.4587 6.7187C12.3679 6.75701 12.2856 6.81281 12.2165 6.88293C12.1473 6.95305 12.0926 7.03611 12.0555 7.12737V7.12662Z"
          fill="#849AA9"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.alert,
    icon: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.18374 17.218C2.08649 17.1061 2.02461 16.9678 2.00592 16.8207C1.98723 16.6736 2.01257 16.5242 2.07874 16.3915L8.82874 2.89152C8.89094 2.76672 8.98671 2.66173 9.10528 2.58834C9.22386 2.51495 9.36054 2.47607 9.49999 2.47607C9.63944 2.47607 9.77612 2.51495 9.8947 2.58834C10.0133 2.66173 10.109 2.76672 10.1712 2.89152L16.9212 16.3915C16.9876 16.5242 17.0132 16.6736 16.9946 16.8209C16.9761 16.9681 16.9143 17.1065 16.8171 17.2186C16.7198 17.3307 16.5915 17.4114 16.4484 17.4506C16.3053 17.4897 16.1537 17.4855 16.013 17.4385L9.49999 15.2673L2.98699 17.4385C2.8463 17.4853 2.69491 17.4893 2.55194 17.45C2.40897 17.4108 2.28084 17.3301 2.18374 17.218ZM10.25 13.9368L14.7695 15.4435L9.49999 4.90377L4.23049 15.4428L8.74999 13.936V9.97677C8.74999 9.77786 8.82901 9.5871 8.96966 9.44644C9.11031 9.30579 9.30108 9.22677 9.49999 9.22677C9.6989 9.22677 9.88967 9.30579 10.0303 9.44644C10.171 9.5871 10.25 9.77786 10.25 9.97677V13.9368Z"
          fill="#849AA9"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.ellipsis,
    icon: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8635 8C13.9695 8 14.8744 7.1 14.8744 6C14.8744 4.9 13.9695 4 12.8635 4C11.7575 4 10.8525 4.9 10.8525 6C10.8525 7.1 11.7575 8 12.8635 8ZM12.8635 10C11.7575 10 10.8525 10.9 10.8525 12C10.8525 13.1 11.7575 14 12.8635 14C13.9695 14 14.8744 13.1 14.8744 12C14.8744 10.9 13.9695 10 12.8635 10ZM12.8635 16C11.7575 16 10.8525 16.9 10.8525 18C10.8525 19.1 11.7575 20 12.8635 20C13.9695 20 14.8744 19.1 14.8744 18C14.8744 16.9 13.9695 16 12.8635 16Z"
          fill="#C5C7CD"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.arrowLeft,
    icon: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 12.5H5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 19.5L5 12.5L12 5.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.close,
    icon: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4L4 12"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4L12 12"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    name: IconEnum.right,
    icon: (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1066_1801)">
          <path
            d="M9.70288 17.9175L15.7029 11.9175L9.70288 5.91748"
            stroke="#8898B0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1066_1801">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.369385 0.351074)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    name: IconEnum.loading,
    icon: (
      <svg
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#62b05d"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    ),
  },
  {
    name: IconEnum.logout,
    icon: (
      <svg
        width="24"
        height="24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 320.002 320.002"
        fill="#56657F"
      >
        <g id="XMLID_6_">
          <path
            id="XMLID_7_"
            d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z"
          />
          <path
            id="XMLID_8_"
            d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    ),
  },
];

export default icons;

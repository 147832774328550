import React from "react";
import Icon from "../../Icon";
import { ReportBoxProps } from "./types";

const ReportBox: React.FC<ReportBoxProps> = ({
  label,
  icon,
  amount,
  percentage,
  status,
}) => {
  return (
    <div className="bg-blue-700 w-full p-3 xl:h-full md:h-32 rounded-lg">
      <div className="flex justify-between items-center mb-1">
        <p className="font-medium text-slate-400">{label}</p>
        <Icon name={icon} />
      </div>
      <div className="flex justify-between flex-wrap">
        <strong className="font-bold text-xl text-white">{amount}</strong>
        <p
          className={`text-base font-bold ${
            status === "positive" ? "text-green-400" : "text-red-500"
          }`}
        >
          {status === "positive" ? "+" : "-"}
          {percentage}
        </p>
      </div>
    </div>
  );
};

export default ReportBox;

import { forwardRef, ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useAuthContext } from "../../store/context/AuthContext";
import Header from "../Header";
import { IconEnum } from "../Icon/types";
import Loader from "../Loader";
import { SidebarComponent } from "../Sidebar/sidebar.stories";
import services from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

interface PageProps {
  children: ReactNode;
  title?: string;
  button?: ReactNode;
  width?: "medium" | "large";
  hasTitle?: boolean;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = "", button, width = "large", hasTitle = true }, ref) => {
    const { user, loading } = useAuthContext();
    const [organizationType, setOrganizationType] = useState();

    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
      const response = services.users.organization(user?.organization);

      response.then((organization) =>
        setOrganizationType(organization.data.business)
      );

      if (organizationType === "brd") {
        navigate("/campaigns");
      }

      if (organizationType === "spt") {
        navigate("/campaigns-candidate");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigationItems = [
      {
        name: t("menu.stats"),
        link: "/dashboard",
        icon: IconEnum.dashboard,
      },
    ];

    if (organizationType === "brd") {
      navigationItems.push({
        name: t("menu.campaigns"),
        link: "/campaigns",
        icon: IconEnum.analytics,
      });
    }

    if (organizationType === "spt") {
      navigationItems.unshift({
        name: t("menu.videos"),
        link: "/videos",
        icon: IconEnum.integration,
      });

      navigationItems.unshift({
        name: t("menu.campaigns"),
        link: "/campaigns-candidate",
        icon: IconEnum.analytics,
      });
    }

    return (
      <>
        <Loader visible={loading} />
        <div ref={ref} className="w-full h-full">
          <Helmet>
            <title>Moneyball {title && "- " + title}</title>
          </Helmet>
          <div className="bg-blue-900 w-full h-full flex flex-col">
            <div className="w-full">
              <Header />
            </div>
            <div className="w-full flex mt-10">
              <div className="w-2/12">
                <SidebarComponent navigationItems={navigationItems} />
              </div>
              <div
                className={
                  width === "medium"
                    ? "w-8/12 center"
                    : "w-full 2xl:max-w-7xl xl:max-w-5xl lg:max-w-4xl m-auto pr-10 pl-5"
                }
              >
                <div className="flex w-full items-center justify-between">
                  {hasTitle && <h1 className="text-4xl text-white">{title}</h1>}
                  {button}
                </div>
                <div className="mt-10">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default Page;

import { useEffect } from "react";
import { DeepRequired, FieldError, FieldErrorsImpl } from "react-hook-form";
import { toast } from "react-toastify";

interface GenericMessage {
  <Type>(arg: Type): Type;
}

function useShowToastErrorMessage(
  messages: FieldErrorsImpl<DeepRequired<GenericMessage>>
) {
  useEffect(() => {
    const messagesArray = Array.from(Object.values(messages)) as FieldError[];

    messagesArray.forEach((m) => toast.error(m.message));
  }, [messages]);

  return;
}

export default useShowToastErrorMessage;

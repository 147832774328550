import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import EventCard from "../../../../components/EventCard";
import services from "../../../../services/api";
import { useTranslation } from "react-i18next";

type EventType = {
  id: number;
  title: string;
  thumbnail: string;
};

type DistributionProps = {
  onChange: (events: number) => void;
};

const Distribution: React.FC<DistributionProps> = ({ onChange }) => {
  const [items, setItems] = useState<EventType[]>([]);
  const [event, setEvent] = useState<number>();

  const [next, setNext] = useState<string>("");
  const [prev, setPrev] = useState<string>("");

  const hasPages = Boolean(next || prev);

  const { t } = useTranslation();

  const handleSetEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    setEvent(value);
  };

  useEffect(() => {
    if (event) {
      onChange(event);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const dropdownItems = [
    { name: "A-Z", value: "sort=alphabetical_asc" },
    { name: "Z-A", value: "sort=alphabetical_desc" },
    { name: t("campaigns.most"), value: "sort=created_desc" },
    { name: t("campaigns.least"), value: "sort=created_asc" },
  ];

  const [selected, setSelected] = useState(dropdownItems[2]);

  const loadEvents = (page?: string) => {
    const response = services.events.list(page, selected.value);
    response.then((response) => {
      let events = response.data.results;
      setPrev(response.data.previous);

      setNext(response.data.next);

      if (events?.length) {
        events = events?.map((event: EventType) => {
          return {
            id: event.id,
            title: event.title,
            thumbnail: event.thumbnail,
          };
        });
      }

      setItems(events);
    });
  };

  useEffect(() => {
    loadEvents();
  }, [selected]);

  const handlePrev = async () => {
    await loadEvents(prev);
    return;
  };

  const handleNext = async () => {
    await loadEvents(next);
    return;
  };

  return (
    <div className="flex justify-center flex-col">
      <p className="text-white mb-5 text-1xl">{t("newCamp.event")}</p>
      <Dropdown
        setSelected={setSelected}
        selected={selected}
        options={dropdownItems}
      />
      <>
        <p className="text-white mt-4">{t("newCamp.selectEvent")}</p>
        {items.map(({ id, title, thumbnail }) => {
          return (
            title && (
              <div className="mt-5" key={String(id + title)}>
                <EventCard
                  id={id}
                  label={title}
                  name={title}
                  thumbnail={String(thumbnail)}
                  onChange={handleSetEvent}
                  isChecked={event === id}
                />
              </div>
            )
          );
        })}

        {hasPages && (
          <div className="mb-12 w-full flex space-x-5 justify-center">
            <div className="w-32">
              <Button
                disabled={!Boolean(prev)}
                label={t("newCamp.back")}
                variation="dark"
                onClick={() => handlePrev()}
              />
            </div>
            <div className="w-32">
              <Button
                disabled={!Boolean(next)}
                label={t("newCamp.next")}
                variation="dark"
                onClick={() => handleNext()}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Distribution;

import { UseFormSetValue } from "react-hook-form";
import { CampaignType } from "../../../../settings/yup/schemas/CampaignSchema";

export type Campaign = {
  name?: string;
  adType?: AdType;
};

export enum AdType {
  banner = "bt_bnr",
  bannerRight = "right_sd_bnr",
  thumbnail = "thb",
  video = "pre_roll",
}

export type CampaignProps = {
  setValue: UseFormSetValue<CampaignType>;
};

import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import { campaignsTableColumns } from "./campaignsTableColumns";
import Page from "../../components/Page";
import services from "../../services/api";
import { CampaignType } from "./campaignsTableColumns";
import { formatCurrency } from "../../helpers";
import { useAuthContext } from "../../store/context/AuthContext";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const currencyConfig = {
  country: "pt-PT",
  currency: "EUR",
};

const CampaignsCandidate: React.FC = () => {
  const { t } = useTranslation();

  const dropdownItems = [
    { name: t("candidate.first"), value: "sort=evaluated_asc" },
    { name: t("candidate.last"), value: "sort=evaluated_desc" },
    { name: t("candidate.most"), value: "sort=created_desc" },
    { name: t("candidate.least"), value: "sort=created_asc" },
  ];

  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);

  const [selected, setSelected] = useState(dropdownItems[2]);

  const navigate = useNavigate();

  const { handleLoading } = useAuthContext();

  const [next, setNext] = useState<string>("");
  const [prev, setPrev] = useState<string>("");

  const hasPages = Boolean(next || prev);

  const listCampaigns = (page?: string) => {
    const response = services.campaigns.listCandidates(page, selected.value);
    response
      .then((response) => {
        let campaigns = response.data;

        if (campaigns.count === 0) {
          return navigate("/event/new");
        }

        campaigns = campaigns?.results
          ?.map((campaign: any) => {
            return {
              id: campaign.id,
              playback_id: campaign.mux_playback_id,
              details: `${
                campaign.event_organization_picture ? (
                  <img
                    alt={campaign.campaign_name}
                    src={campaign.event_organization_picture}
                    className="mr-5"
                    width="60"
                  />
                ) : (
                  ""
                )
              } ${campaign.campaign_name} `,
              budget: formatCurrency(
                campaign.campaign_budget,
                currencyConfig.country,
                currencyConfig.currency
              ),
              status: campaign.evaluation || "For Approval",
              payment_status: campaign.campaign_payment_status,
            };
          })
          .filter((campaign: any) => campaign.payment_status === "paid");

        setPrev(response.data.previous);
        setNext(response.data.next);
        setCampaigns(campaigns);

        handleLoading(false);
      })
      .finally(() => handleLoading(false));
  };

  useEffect(() => {
    handleLoading(true);
    listCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onApprove = async (id: number) => {
    await services.campaigns.approveCandidates(id);
    listCampaigns();
  };

  const onDelete = async (id: number) => {
    await services.campaigns.rejectCandidates(id);
    listCampaigns();
  };

  const onView = async (id?: string) => {
    navigate("/campaigns/" + id);
  };

  const handlePrev = async () => {
    listCampaigns(prev);
    return;
  };

  const handleNext = async () => {
    listCampaigns(next);
    return;
  };
  const columns = campaignsTableColumns(onApprove, onDelete, onView, t);

  return (
    <Page title={String(t("candidate.campaigns"))}>
      <Dropdown
        selected={selected}
        setSelected={setSelected}
        options={dropdownItems}
      />
      {Boolean(campaigns.length) ? (
        <>
          <Table data={campaigns} columns={columns as any} />
          {hasPages && (
            <div className="mb-12 w-full flex space-x-5 justify-center">
              <div className="w-32">
                <Button
                  disabled={!Boolean(prev)}
                  label={t("candidate.previous")}
                  isFilled
                  onClick={() => handlePrev()}
                />
              </div>
              <div className="w-32">
                <Button
                  disabled={!Boolean(next)}
                  label={t("candidate.next")}
                  isFilled
                  onClick={() => handleNext()}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="text-2xl mt-[15%] text-white font-medium text-center">
          {t("candidate.noCamp")}
        </p>
      )}
    </Page>
  );
};

export default CampaignsCandidate;
